var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "privacy" }, [
      _c("br"),
      _vm._v(" "),
      _c("h1", [_vm._v("隐私政策")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "privacy-content" }, [
        _c("p", [_vm._v("应用名称：数字暖通机房")]),
        _vm._v(" "),
        _c("p", [_vm._v("开发者名称：深圳毅隆数字技术有限公司")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      本隐私政策适用于深圳毅隆数字技术有限公司的数字暖通机房软件。最近更新日期：2022\n      年 02 月 15 日\n    "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("本政策将帮助您了解以下内容：")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "一、我们如何收集和使用您的用户信息(app不提供用户注册、注销功能)"
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("二、我们如何使用 Cookie 和同类技术")]),
        _vm._v(" "),
        _c("p", [_vm._v("三、我们如何共享、转让、公开披露您的用户信息")]),
        _vm._v(" "),
        _c("p", [_vm._v("四、我们如何保护您的用户信息")]),
        _vm._v(" "),
        _c("p", [_vm._v("五、您的权利")]),
        _vm._v(" "),
        _c("p", [_vm._v("六、我们如何处理儿童的个人信息")]),
        _vm._v(" "),
        _c("p", [_vm._v("七、您的用户信息如何储存及如何在全球范围转移")]),
        _vm._v(" "),
        _c("p", [_vm._v("八、本政策如何更新")]),
        _vm._v(" "),
        _c("p", [_vm._v("九、如何联系我们")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      我们深知用户信息对您的重要性，并会尽全力保护您的用户信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的用户信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的用户信息。\n      请在使用我们的产品（或服务）前，仔细阅读并了解本隐私政策。\n    "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("一、 我们如何收集和使用您的用户信息")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      （一）您使用我司产品或服务过程中我们收集和使用的信息\n      我们仅会出于本政策所述的业务功能，收集和使用您的用户信息，收集用户信息的目的在于向您提供产品或服务，您有权自行选择是否提供该信息，但多数情况下，如果您不提供，我们可能无法向您提供相应的服务，也无法回应您遇到的问题：\n      在您使用我们的服务时，允许我们收集您自行向我们提供的或为向您提供服务所必要的信息包括：网络日志、设备信息、位置信息和其它相关信息等。\n      对于我们收集的用户信息，我们将用于为您提供身份验证、安全防范、反诈骗监测、存档备份、客户的安全服务等用途。\n      您提供的上述信息，将在您使用本服务期间持续授权我们使用。在您停止使用推送服务时，我们将停止使用并删除上述信息。\n      我们保证会依法对收集后的用户信息进行去标识化或匿名化处理，对于无法单独或者与其他信息结合识别自然人个人身份的信息，不属于法律意义上的个人信息。如果我们将非个人信息与其他信息结合识别到您的个人身份时，或者与您的个人信息结合使用时，我们会在结合使用期间，将此类信息作为您的个人信息按照本隐私政策对其进行处理和保护。\n      为了更好运营和改善我们的技术和服务，或出于商业策略的变化，当我们提供的产品或服务所需的用户信息收集、使用、处理超出上述范围或者要将收集到的用户信息用于本隐私政策未载明的其他用途，或当我们要将基于特定目的收集而来的信息用于其他目的时，我们会在获取用户信息后的合理期限内或处理用户信息前通知您，并获得您的授权同意。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（二）征得授权同意的例外")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      请您理解，根据法律法规及相关国家标准，以下情形中，我们收集和使用您的用户信息无需征得您的授权同意：\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("1、与国家安全、国防安全直接相关的；")]),
        _vm._v(" "),
        _c("p", [_vm._v("2、与公共安全、公共卫生、重大公共利益直接相关的；")]),
        _vm._v(" "),
        _c("p", [_vm._v("3、与犯罪侦查、起诉、审判和判决执行等直接相关的；")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("5、所收集的您的用户信息是您自行向社会公众公开的；")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      6、从合法公开披露的信息中收集的您的用户信息，如合法的新闻报道、政府信息公开等渠道；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("7、根据您的要求签订或履行合同所必需的；")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      8、用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "9、 个人信息控制者为新闻单位且其在开展合法的新闻报道所必需的；"
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("11、法律法规规定的其他情形。")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("二、我们如何使用 Cookie 和同类技术")]),
        _vm._v(" "),
        _c("p", [_vm._v("（一）Cookie")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie\n      的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于\n      Cookie，网站能够存储您的访问偏好数据。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      我们不会将 Cookie\n      用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除\n      Cookie。您可以清除计算机上保存的所有\n      Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（二）网站信标和像素标签")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      除 Cookie\n      外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（三）Do Not Track（请勿追踪）")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do Not\n      Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了\n      Do Not Track，那么我们的所有网站都会尊重您的选择。\n    "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("三、我们如何共享、转让、公开披露您的用户信息")]),
        _vm._v(" "),
        _c("p", [_vm._v("（一）共享")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      我们不会与其他的任何公司、组织和个人分享您的用户信息，但以下情况除外：\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      2、我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的用户信息。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      3、与我们的关联公司共享：您的用户信息可能会与我们的关联公司共享。我们只会共享必要的用户信息，且受本隐私政策中所声明目的的约束。关联公司如要改变用户信息的处理目的，将再次征求您的授权同意。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      4、与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些用户信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。为了更好运营和改善技术和服务，您同意我们和授权合作伙伴在符合相关法律法规的前提下可将收集的信息用于其他服务和用途。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      以下列举了具体的授权合作伙伴，并提供了该第三方的隐私政策链接，我们建议您阅读该第三方的隐私政策：\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（1）. 允许应用读取存储卡上的照片、媒体内容和文件")]),
        _vm._v(" "),
        _c("p", [_vm._v("1) 权限名称: android.permission.CAMERA。")]),
        _vm._v(" "),
        _c("p", [_vm._v("2) 使用场景:处理工单、意见反馈需要上传相关图片。")]),
        _vm._v(" "),
        _c("p", [_vm._v("3) 调用路径：应用首页->“工单”tab页->新增。")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("（2）. 允许应用基于GPS、基站、 Wi-Fi 等网络源获取位置信息。")
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("1) 使用场景:设备周围环境状态。")]),
        _vm._v(" "),
        _c("p", [_vm._v("2) 调用路径：应用首页。")]),
        _vm._v(" "),
        _c("p", [_vm._v("（3）. 允许用于获取设备IMEI等信息。")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("1) 使用场景:监测页面获取设备重力方向切换横竖屏模式。")
        ]),
        _vm._v(" "),
        _c("p", [_vm._v('2) 调用路径："监测"tab页。')]),
        _vm._v(" "),
        _c("p", [_vm._v("（4）. 允许用于获取设备ANDROID ID信息。")]),
        _vm._v(" "),
        _c("p", [_vm._v("1) 使用场景:检测是否是用户常用设备。")]),
        _vm._v(" "),
        _c("p", [_vm._v("2) 调用路径：应用首页。")]),
        _vm._v(" "),
        _c("p", [_vm._v("（二）转让")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外："
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的用户信息；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      2、在涉及合并、收购或破产清算时，如涉及到用户信息转让，我们会再要求新的持有您用户信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（三）公开披露")]),
        _vm._v(" "),
        _c("p", [_vm._v("我们仅会在以下情况下，公开披露您的用户信息：")]),
        _vm._v(" "),
        _c("p", [_vm._v("1、获得您明确同意后；")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      2、基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的用户信息。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("（四）共享、转让、公开披露信息时事先征得授权同意的例外")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      请您理解，根据法律法规及相关国家标准，以下情形中，我们共享、转让、公开披露您的用户信息无需征得您的授权同意：\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("1、与国家安全、国防安全直接相关的；")]),
        _vm._v(" "),
        _c("p", [_vm._v("2、与公共安全、公共卫生、重大公共利益直接相关的；")]),
        _vm._v(" "),
        _c("p", [_vm._v("3、与犯罪侦查、起诉、审判和判决执行等直接相关的；")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("5、您自行向社会公众公开的信息；")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      6、从合法公开披露的信息中收集的，如合法的新闻报道、政府信息公开等渠道。\n    "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("四、我们如何保护您的用户信息")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      （一）我们已使用符合业界标准的安全防护措施保护您提供的用户信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的用户信息。例如，在您的浏览器与“服务”之间交换数据时受SSL加密保护；我们同时对网站提供https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      （二）我们会采取一切合理可行的措施，确保未收集无关的用户信息。我们只会在达成本政策所述目的所需的期限内保留您的用户信息，除非需要延长保留期或受到法律的允许。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      （三）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送用户信息。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      （四）我们将定期更新并公开安全风险、用户信息安全影响评估等报告的有关内容。您可通过以下方式获得：补充安全风险公开途径\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      （五）互联网环境并非百分之百安全，我们将尽力确保您发送给我们的任何信息的安全性。即使我们做出了很大努力，采取了一切合理且必要的措施，仍然有可能无法杜绝您的用户信息被非法访问、被非法盗取，被非法篡改或毁坏，导致您的合法权益受损，请您理解信息网络的上述风险并自愿承担。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      （六）在不幸发生用户信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报用户信息安全事件的处置情况。\n    "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("五、您的权利")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的用户信息行使以下权利：\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（一）访问您的用户信息")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("您有权访问您的用户信息，法律法规规定的例外情况除外。")
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（二）更正您的用户信息")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      当您发现我们处理的关于您的用户信息有错误时，您有权要求我们作出更正。如果您无法通过上述链接更正这些用户信息，您可以随时使用我们的\n      Web表单联系我们将在30天内回复您的更正请求。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（三）删除您的用户信息")]),
        _vm._v(" "),
        _c("p", [_vm._v("在以下情形中，您可以向我们提出删除用户信息的请求：")]),
        _vm._v(" "),
        _c("p", [_vm._v("1、如果我们处理用户信息的行为违反法律法规；")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("2、如果我们收集、使用您的用户信息，却未征得您的同意；")
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("3、如果我们处理用户信息的行为违反了与您的约定；")]),
        _vm._v(" "),
        _c("p", [
          _vm._v("4、如果您不再使用我们的产品或服务，或您注销了账号；")
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("5、如果我们不再为您提供产品或服务。")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      我们将会根据您的删除请求进行评估，若满足相应规定，我们将会采取相应步骤进行处理。当您向我们提出删除请求时，我们可能会要求您进行身份验证，以保障账户的安全。当您从我们的服务中删除信息后，因为适用的法律和安全技术，我们可能不会立即从备份系统中删除相应的信息，我们将安全存储您的信息直到备份可以清除或实现匿名化。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（四）改变您授权同意的范围")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      每个业务功能需要一些基本的用户信息才能得以完成（见本政策“第一部分”）。对于用户信息的收集和使用，您可以随时给予或收回您的授权同意。您可以通过以下方式自行操作：补充改变用户信息的访问方式\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      当您收回同意后，我们将不再处理相应的用户信息。同时也请您注意，您撤销授权同意可能会导致某些后果，例如我们可能无法继续为您提供相应的服务或特定的功能，但您收回同意的决定，不会影响此前基于您的授权而开展的用户信息处理。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（五）用户信息主体注销账户")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      您随时可注销此前注册的账户，您可以通过以下方式自行操作：补充注销账户的操作方式\n      在注销账户之后，我们将停止为您提供产品或服务并依据您的要求，删除或匿名化您的信息，法律法规另有规定的除外。这也将可能导致您失去对您账户中数据的访问权，请您谨慎操作。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（六）用户信息主体获取用户信息副本")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      您有权获取您的用户信息副本，您可以通过以下方式自行操作：补充获取用户信息副本的操作方式\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的用户信息副本传输给您指定的第三方。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（七）约束信息系统自动决策")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("（八）响应您的上述请求")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      我们将在三十天内作出答复。如您不满意，还可以通过以下途径投诉：补充其他的申诉途径\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。也请您理解，出于安全保障的考虑、相关法律法规的要求或技术上的限制，对于您的某些请求我们可能无法做出响应，例如以下情形：\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("1、与用户信息控制者履行法律法规规定的义务相关的；")]),
        _vm._v(" "),
        _c("p", [_vm._v("2、与国家安全、国防安全直接相关的；")]),
        _vm._v(" "),
        _c("p", [_vm._v("3、与公共安全、公共卫生、重大公共利益直接相关的；")]),
        _vm._v(" "),
        _c("p", [_vm._v("4、与犯罪侦查、起诉、审判和执行判决等直接相关的；")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      5、用户信息控制者有充分证据表明用户信息主体存在主观恶意或滥用权利的；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      6、出于维护用户信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      7、响应用户信息主体的请求将导致用户信息主体或其他个人、组织的合法权益受到严重损害的；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("8、涉及商业秘密的。")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("六、我们如何处理儿童的个人信息")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      我们非常重视儿童个人信息的保护，我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，儿童不应创建自己的用户账户。尽管当地法律和习俗对儿童的定义不同，但我们将不满\n      14 周岁的任何人均视为儿童。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      对于经父母或监护人同意而收集儿童用户信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下储存、使用或公开披露此信息，否则我们会设法尽快删除相关数据。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      鉴于现有技术和业务模式的限制，我们很难主动识别儿童的个人信息，如果您发现我们在不知情的情况下或在未事先获得可证实的监护人同意的情况下收集了儿童的个人信息，您可以及时联系我们，我们将在发现后设法及时删除，如果我们自己发现前述情形的，我们也会及时删除，法律要求我们保留的除外。\n    "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("七、您的用户信息如何储存以及如何在全球范围转移")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      原则上，我们在中华人民共和国境内收集和产生的用户信息，将存储在中华人民共和国境内。我们只会在本政策所述目的和用途所需的期限内和法律法规规定的最短期限内保留您的用户信息，超出上述保留期间后，我们会根据适用法律法规的要求删除您的用户信息或匿名化处理。法律法规另有规定，或出于公共利益、科学历史研究等的目的，或您的另行授权同意的除外，我们可能需要较长时间保留相关数据。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的用户信息可能会被转移到您使用产品或服务所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的用户信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移用户信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。\n    "
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("八、本政策如何更新")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      我们的隐私政策可能变更。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。\n      对于重大变更，我们还会提供更为显著的通知。本政策所指的重大变更包括但不限于：\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      1、我们的服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("3、用户信息共享、转让或公开披露的主要对象发生变化；")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("4、您参与用户信息处理方面的权利及其行使方式发生重大变化；")
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      5、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化时；\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("6、用户信息安全影响评估报告表明存在高风险时。")]),
        _vm._v(" "),
        _c("p", [_vm._v("我们还会将本政策的旧版本存档，供您查阅。")]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", [_vm._v("九、如何联系我们")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系："
          )
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("电子邮件：609723528@163.com")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      我们设立了用户信息保护专职部门，您可以通过以下方式与相关人员联系：609723528@163.com\n    "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _home = require("@/api/home");

var _dayjs = _interopRequireDefault(require("dayjs"));

var _bar = _interopRequireDefault(require("./bar"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    bar: _bar.default
  },
  props: {
    chartData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isShow: true,
      numberData: {},
      projectInfo: {},
      dayColdPrice: [],
      dayColdPriceDate: [],
      monthColdPrice: [],
      monthColdPriceDate: [],
      yearColdPrice: [],
      zoom: '',
      zoomrAtio: ''
    };
  },
  watch: {
    chartData: function chartData(val) {
      var res = Object.assign.apply(Object, [{}].concat((0, _toConsumableArray2.default)(val)));
      var day = Number(res.dayColdPrice || res.dayCop || 0);
      var month = Number(res.monthColdPrice || res.monthCop || 0);
      var year = Number(res.yearColdPrice || res.yearCop || 0);
      this.numberData = {
        day: day === 0 ? 0 : day.toFixed(2),
        month: month === 0 ? 0 : month.toFixed(2),
        year: year === 0 ? 0 : year.toFixed(2)
      };
    },
    isShow: function isShow(val) {
      if (val) {
        this.$store.dispatch("project/setProjectCold", 2);
        this.getColdPriceData();
      } else {
        this.$store.dispatch("project/setProjectCold", 1);
        this.getCopData();
      }
    }
  },
  created: function created() {
    var projectInfo = JSON.parse(sessionStorage.getItem("projectInfo"));

    if (projectInfo) {
      this.projectInfo = projectInfo;
    }
  },
  mounted: function mounted() {
    this.getColdPriceData();
  },
  methods: {
    // // 获取冷价数据
    // getColdPriceData() {
    //   getColdPrice({ projectId: this.projectInfo.id, type: 2 }).then((res) => {
    //     this.dayColdPrice = res[0].dayColdPrice.map((e) => {
    //       return e.coldPriceValue;
    //     });
    //     this.dayColdPriceDate = res[0].dayColdPrice.map((e) => {
    //       return dayjs(e.dataTime).format("YYYY-MM-DD");
    //     });
    //     this.monthColdPrice = res[1].monthColdPrice.map((e) => {
    //       return e.coldPriceValue;
    //     });
    //     this.monthColdPriceDate = res[1].monthColdPrice.map((e) => {
    //       return dayjs(e.dataTime).format("YYYY-MM");
    //     });
    //     this.yearColdPrice = res[2].yearColdPrice.map((e) => {
    //       return e.coldPriceValue;
    //     });
    //     this.yearColdPrice.push(this.projectInfo.coldPriceTargetValue);
    //     this.getLineEcharts();
    //     this.getBarEcharts();
    //   });
    // },
    // // 获取COP数据
    // getCopData() {
    //   getCop({ projectId: this.projectInfo.id, type: 1 }).then((res) => {
    //     this.dayColdPrice = res[0].dayCop.map((e) => {
    //       return e.copValue;
    //     });
    //     this.dayColdPriceDate = res[0].dayCop.map((e) => {
    //       return dayjs(e.dataTime).format("YYYY-MM-DD");
    //     });
    //     this.monthColdPrice = res[1].monthCop.map((e) => {
    //       return e.copValue;
    //     });
    //     this.monthColdPriceDate = res[1].monthCop.map((e) => {
    //       return dayjs(e.dataTime).format("YYYY-MM");
    //     });
    //     this.yearColdPrice = res[2].yearCop.map((e) => {
    //       return e.copValue;
    //     });
    //     this.yearColdPrice.push(this.projectInfo.copTargetValue);
    //     this.getLineEcharts();
    //     this.getBarEcharts();
    //   });
    // },
    // // 绘制COP图表
    // getLineEcharts() {
    //   const lineEcharts = this.$echarts.init(
    //     document.getElementById("lineEcharts")
    //   );
    //   const option = {
    //     grid: {
    //       left: "0%",
    //       right: "10%",
    //       top: "30%",
    //       bottom: "2%", // 也可设置left和right设置距离来控制图表的大小
    //     },
    //     xAxis: {
    //       type: "category",
    //       axisLine: {
    //         // y轴
    //         show: false,
    //       },
    //       axisTick: {
    //         // y轴刻度线
    //         show: false,
    //       },
    //       splitLine: {
    //         // 网格线
    //         show: false,
    //       },
    //       data: this.dayColdPriceDate,
    //     },
    //     tooltip: {
    //       trigger: "axis",
    //       backgroundColor: "rgba(255,255,255,1)",
    //       extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#000000",
    //       textStyle: {
    //         fontSize: 18,
    //         lineHeight: 30,
    //       },
    //       padding: 15,
    //     },
    //     yAxis: {
    //       type: "value",
    //       axisLine: {
    //         // y轴
    //         show: false,
    //       },
    //       axisTick: {
    //         // y轴刻度线
    //         show: false,
    //       },
    //       splitLine: {
    //         // 网格线
    //         show: false,
    //       },
    //     },
    //     series: [
    //       {
    //         data: this.dayColdPrice,
    //         type: "line",
    //         smooth: true,
    //         lineStyle: {
    //           color: "#6aaaf3",
    //         },
    //         itemStyle: {
    //           label: { show: true },
    //           color: "#6aaaf3",
    //         },
    //       },
    //     ],
    //   };
    //   lineEcharts.setOption(option);
    // },
    // // 绘制冷价图表
    // getBarEcharts() {
    //   const barEcharts = this.$echarts.init(
    //     document.getElementById("barEcharts")
    //   );
    //   const option = {
    //     grid: {
    //       left: "0%",
    //       right: "10%",
    //       top: "20%",
    //       bottom: "0%", // 也可设置left和right设置距离来控制图表的大小
    //     },
    //     xAxis: {
    //       type: "category",
    //       data: this.monthColdPriceDate,
    //     },
    //     tooltip: {
    //       trigger: "axis",
    //       backgroundColor: "rgba(255,255,255,1)",
    //       extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#000000",
    //       textStyle: {
    //         fontSize: 18,
    //         lineHeight: 30,
    //       },
    //       padding: 15,
    //     },
    //     yAxis: {
    //       type: "value",
    //       axisLine: {
    //         // y轴
    //         show: false,
    //       },
    //       axisTick: {
    //         // y轴刻度线
    //         show: false,
    //       },
    //       splitLine: {
    //         // 网格线
    //         show: false,
    //       },
    //     },
    //     series: [
    //       {
    //         name: "",
    //         type: "bar",
    //         barWidth: "20",
    //         data: this.monthColdPrice,
    //         itemStyle: {
    //           label: { show: true, position: "top" },
    //           color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //             { offset: 1, color: "#c4c2fe" },
    //             { offset: 0.5, color: "#7774fc" },
    //             { offset: 0, color: "#342efa" },
    //           ]),
    //         },
    //       },
    //     ],
    //   };
    //   barEcharts.setOption(option);
    // },
    getColdPriceData: function getColdPriceData() {
      var _this = this;

      (0, _home.getColdPrice)({
        projectId: this.projectInfo.id,
        type: 2
      }).then(function (res) {
        _this.dayColdPrice = res[0].dayColdPrice.map(function (e) {
          return e.coldPriceValue;
        });
        _this.dayColdPriceDate = res[0].dayColdPrice.map(function (e) {
          // return dayjs(e.dataTime).format("MM-DD");
          return e.dataTime.substr(5, 5);
        });
        _this.monthColdPrice = res[1].monthColdPrice.map(function (e) {
          return e.coldPriceValue;
        });
        _this.monthColdPriceDate = res[1].monthColdPrice.map(function (e) {
          // return dayjs(e.dataTime).format("MM");
          return e.dataTime.substr(5, 2);
        });
        _this.yearColdPrice = res[2].yearColdPrice.map(function (e) {
          return e.coldPriceValue;
        });

        _this.yearColdPrice.push(_this.projectInfo.coldPriceTargetValue);

        _this.getLineEcharts();

        _this.getBarEcharts(); // this.getAcrossBarEcharts()

      });
    },
    getCopData: function getCopData() {
      var _this2 = this;

      (0, _home.getCop)({
        projectId: this.projectInfo.id,
        type: 1
      }).then(function (res) {
        //console.log("getCopData:", res);
        if (res.length == 0) {
          _this2.getLineEcharts();

          _this2.getBarEcharts();

          return;
        }

        _this2.dayColdPrice = res[0].dayCop.map(function (e) {
          return e.copValue;
        });
        _this2.dayColdPriceDate = res[0].dayCop.map(function (e) {
          // return dayjs(e.dataTime).format("MM-DD");
          return e.dataTime.substr(5, 5);
        });
        _this2.monthColdPrice = res[1].monthCop.map(function (e) {
          return e.copValue;
        });
        _this2.monthColdPriceDate = res[1].monthCop.map(function (e) {
          return (0, _dayjs.default)(e.dataTime).format("MM");
        });
        _this2.yearColdPrice = res[2].yearCop.map(function (e) {
          return e.copValue;
        });

        _this2.yearColdPrice.push(_this2.projectInfo.copTargetValue);

        _this2.getLineEcharts();

        _this2.getBarEcharts(); // this.getAcrossBarEcharts()

      });
    },
    getLineEcharts: function getLineEcharts() {
      var _this3 = this;

      var lineEcharts = this.$echarts.init(document.getElementById("lineEcharts"));
      var option = {
        grid: {
          left: "10%",
          right: "10%",
          top: "30%",
          bottom: "20%" // 也可设置left和right设置距离来控制图表的大小

        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            inside: false,
            textStyle: {
              fontSize: '11'
            }
          },
          axisLine: {
            // y轴
            show: false,
            lineStyle: {
              color: "#bfbfbf" //x轴轴线颜色

            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          },
          splitLine: {
            // 网格线
            show: false
          },
          data: this.dayColdPriceDate
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,1)",
          extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#000000",
          textStyle: {
            fontSize: 12,
            lineHeight: 30
          },
          padding: 15
        },
        yAxis: {
          type: "value",
          show: false,
          axisLine: {
            // y轴
            show: false
          },
          axisTick: {
            // y轴刻度线
            show: false
          },
          splitLine: {
            // 网格线
            show: false
          }
        },
        //
        series: [{
          data: this.dayColdPrice,
          type: "line",
          areaStyle: {},
          lineStyle: {
            color: "#FACF55"
          },
          itemStyle: {
            label: {
              show: true
            },
            color: "rgb(250 207 85 / 40%)"
          }
        }]
      };
      lineEcharts.setOption(option);
      this.$nextTick(function () {
        _this3.zoom = document.body.style.zoom;
        _this3.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    },
    getBarEcharts: function getBarEcharts() {
      var barEcharts = this.$echarts.init(document.getElementById("barEcharts"));
      var option = {
        grid: {
          left: "10%",
          right: "10%",
          top: "20%",
          bottom: "20%" // 也可设置left和right设置距离来控制图表的大小

        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLabel: {
            inside: false,
            textStyle: {
              fontSize: '11'
            }
          },
          axisLine: {
            // y轴
            show: false,
            lineStyle: {
              color: "#bfbfbf",
              //x轴轴线颜色
              fontSize: '10'
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          },
          splitLine: {
            // 网格线
            show: false
          },
          data: this.monthColdPriceDate
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,1)",
          extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#000000",
          textStyle: {
            fontSize: 12,
            lineHeight: 30
          },
          padding: 15
        },
        yAxis: {
          type: "value",
          show: false,
          axisLine: {
            // y轴
            show: false
          },
          axisTick: {
            // y轴刻度线
            show: false
          },
          splitLine: {
            // 网格线
            show: false
          }
        },
        series: [{
          name: "",
          type: "bar",
          barWidth: "14",
          data: this.monthColdPrice,
          itemStyle: {
            // normal: {
            label: {
              show: true,
              position: "top"
            },
            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: "#1665D8"
            }, {
              offset: 0.68,
              color: "#257dff"
            }, {
              offset: 1,
              color: "#257dff"
            }]) // },

          }
        }]
      };
      barEcharts.setOption(option);
    },
    presentStyle: function presentStyle() {
      if (this.yearColdPrice[1] > this.yearColdPrice[0]) {
        return 'left:80%';
      } else {
        var str = this.yearColdPrice[1] / this.yearColdPrice[0] * 80;
        return "left:".concat(str, "%");
      }
    }
  }
};
exports.default = _default2;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "border" }, [
    _vm.borderSize
      ? _c("img", {
          attrs: {
            src: require("@/assets/big_border1.png"),
            alt: "Description"
          }
        })
      : _c("img", {
          attrs: {
            src: require("@/assets/big_border2.png"),
            alt: "Description"
          }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
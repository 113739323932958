"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import headr from '@/components/head'
var _default = {
  components: {// headr
  },
  methods: {
    goBack: function goBack() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-right" }, [
    _c("div", { staticStyle: { height: "100%" } }, [
      _c("div", { staticClass: "header" }, [_c("headr")], 1),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab-box" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            _vm._l(_vm.menusList, function(item, index) {
              return _c(
                "el-tab-pane",
                {
                  attrs: { label: item.name, name: "tag" + item.id },
                  on: { key: index }
                },
                [
                  _vm.activeName == "tag" + item.id && item.name == "设备管理"
                    ? _c("equipment")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName == "tag" + item.id && item.name == "新建工单"
                    ? _c("createOrder")
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeName == "tag" + item.id && item.name == "工单管理"
                    ? _c("orderWork")
                    : _vm._e()
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-right" }, [
    _c("div", { staticClass: "header" }, [_c("headr")], 1),
    _vm._v(" "),
    _c("div", { staticClass: "feedback-box" }, [
      _c("div", { staticClass: "title-box" }, [
        _vm._v("\n      意见反馈\n    ")
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-left": "60px" } },
        [
          _c(
            "el-form",
            {
              ref: "dialogForm",
              attrs: { model: _vm.dialogForm, rules: _vm.dialogRules }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "fullName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "30%" },
                    attrs: {
                      maxlength: "5",
                      "show-word-limit": "",
                      placeholder: "请填写您的姓名 (必填)"
                    },
                    model: {
                      value: _vm.dialogForm.fullName,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogForm, "fullName", $$v)
                      },
                      expression: "dialogForm.fullName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "联系方式", prop: "contactInformation" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "30%" },
                    attrs: {
                      maxlength: "20",
                      "show-word-limit": "",
                      placeholder: "电话、微信或邮箱 (必填一项)"
                    },
                    model: {
                      value: _vm.dialogForm.contactInformation,
                      callback: function($$v) {
                        _vm.$set(_vm.dialogForm, "contactInformation", $$v)
                      },
                      expression: "dialogForm.contactInformation"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "具体描述", prop: "problemDescription" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "30%" },
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          maxlength: "200",
                          "show-word-limit": "",
                          placeholder: "请填写你的具体描述 (必填)"
                        },
                        model: {
                          value: _vm.dialogForm.problemDescription,
                          callback: function($$v) {
                            _vm.$set(_vm.dialogForm, "problemDescription", $$v)
                          },
                          expression: "dialogForm.problemDescription"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-left": "60px", "margin-top": "30px" } },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                action: "https://jsonplaceholder.typicode.com/posts/",
                "list-type": "picture-card",
                "on-preview": _vm.handlePictureCardPreview,
                "on-remove": _vm.handleRemove,
                limit: 10,
                "before-upload": _vm.beforeAvatarUpload,
                "auto-upload": true,
                "http-request": _vm.uploadFile,
                "on-exceed": _vm.handleExceed
              }
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogVisible },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("img", {
                attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "submit-box",
              attrs: { type: "primary" },
              on: { click: _vm.subPicForm }
            },
            [_vm._v("提交反馈")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "margin-bottom": "20px",
          "font-size": "14px",
          color: "#666666",
          "margin-top": "10px"
        }
      },
      [_vm._v("上传图片 "), _c("span", [_vm._v("单张不得大于2M")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
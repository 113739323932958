var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "day-report" }, [
    _c(
      "div",
      { staticClass: "calendar" },
      [
        _c("div", { staticClass: "left" }, [
          _c(
            "div",
            { staticClass: "day-header" },
            [
              _c(
                "div",
                { staticClass: "date", staticStyle: { width: "30%" } },
                [
                  _c("span", [_vm._v("月份选择")]),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    staticStyle: { height: "25px" },
                    attrs: {
                      "value-format": "yyyy-MM",
                      clearable: false,
                      "picker-options": _vm.pickerOptions1,
                      type: "month",
                      placeholder: "选择月"
                    },
                    model: {
                      value: _vm.nowMonth,
                      callback: function($$v) {
                        _vm.nowMonth = $$v
                      },
                      expression: "nowMonth"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "date",
                  staticStyle: { "padding-left": "250px" }
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "year",
                      placeholder: "选择月",
                      clearable: false,
                      "value-format": "yyyy",
                      format: "yyyy",
                      "picker-options": _vm.pickerOptions0
                    },
                    on: { change: _vm.handleTime },
                    model: {
                      value: _vm.monthData,
                      callback: function($$v) {
                        _vm.monthData = $$v
                      },
                      expression: "monthData"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "date dateSel",
                  staticStyle: { "padding-left": "10px" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        "collapse-tags": "",
                        placeholder: "多选"
                      },
                      model: {
                        value: _vm.calendarData,
                        callback: function($$v) {
                          _vm.calendarData = $$v
                        },
                        expression: "calendarData"
                      }
                    },
                    _vm._l(_vm.haveData, function(item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: {
                          label: item.value,
                          value: item.key,
                          hegiht: "28px"
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.$isBtnAuth("report:batchDown")
                ? _c(
                    "el-button",
                    {
                      staticClass: "download-box",
                      attrs: { plain: "", size: "small" },
                      on: { click: _vm.handleBatchDown }
                    },
                    [_vm._v("批量导出")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("section", { staticClass: "left-main" }, [
            _c(
              "div",
              { staticClass: "left-item" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "line-chart" }, [
                  _c("div", {
                    style:
                      "position: absolute;width: 100%;height: 100%;zoom:" +
                      _vm.zoomrAtio +
                      ";transform:scale(" +
                      _vm.zoom +
                      ");transform-origin:0px 0px;",
                    attrs: { id: "first" }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "look",
                    attrs: { type: "primary", plain: "", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.initChart(1)
                      }
                    }
                  },
                  [_vm._v("预览")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "left-item" }, [
              _c("div", { staticClass: "line-title" }, [
                _c("span", [_vm._v("能耗分布")]),
                _vm._v(" "),
                _c("span", [_vm._v("总: " + _vm._s(_vm.nhfbTotal))])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "check_btn" },
                _vm._l(_vm.powerType, function(item, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      class: _vm.activeIndexNhfb == index ? "sel_btn" : "",
                      on: {
                        click: function($event) {
                          return _vm.handelPowerType(index)
                        }
                      }
                    },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "line-chart" }, [
                _c("div", {
                  style:
                    "position: absolute;width: 100%;height: 100%;zoom:" +
                    _vm.zoomrAtio +
                    ";transform:scale(" +
                    _vm.zoom +
                    ");transform-origin:0px 0px;",
                  attrs: { id: "second" }
                })
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "left-item" },
              [
                _vm._m(1),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "check_btn" },
                  _vm._l(_vm.powerType1, function(item, index) {
                    return _c(
                      "span",
                      {
                        key: index,
                        class: _vm.activeIndexGlfb == index ? "sel_btn" : "",
                        on: {
                          click: function($event) {
                            return _vm.handelPowerType1(index)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "line-chart" }, [
                  _c("div", {
                    style:
                      "position: absolute;width: 100%;height: 100%;zoom:" +
                      _vm.zoomrAtio +
                      ";transform:scale(" +
                      _vm.zoom +
                      ");transform-origin:0px 0px;",
                    attrs: { id: "line" }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "look",
                    attrs: { type: "primary", plain: "", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.initChart(3)
                      }
                    }
                  },
                  [_vm._v("预览")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "left-item" },
              [
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "line-chart" }, [
                  _c("div", {
                    style:
                      "position: absolute;width: 100%;height: 100%;zoom:" +
                      _vm.zoomrAtio +
                      ";transform:scale(" +
                      _vm.zoom +
                      ");transform-origin:0px 0px;",
                    attrs: { id: "fourth" }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "look",
                    attrs: { type: "primary", plain: "", size: "mini" },
                    on: {
                      click: function($event) {
                        return _vm.initChart(4)
                      }
                    }
                  },
                  [_vm._v("预览")]
                )
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _c(
            "div",
            {
              staticClass: "day-header day-left",
              staticStyle: { width: "100%", "justify-content": "space-between" }
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "div",
                    { staticClass: "checkAll" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.handlePreview }
                        },
                        [_vm._v("预览")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$isBtnAuth("report:down")
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading"
                            }
                          ],
                          staticClass: "download-box",
                          attrs: { plain: "", size: "small" },
                          on: { click: _vm.handlOneDown }
                        },
                        [_vm._v("导出")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.show,
                  expression: "show"
                }
              ],
              staticClass: "ifrma-box"
            },
            [
              _c("div", { staticClass: "img-box" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "main-box",
                  staticStyle: { position: "relative" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title-box",
                      staticStyle: {
                        position: "absolute",
                        top: "-20px",
                        color: "#333333"
                      }
                    },
                    [
                      _vm._v("\n                《供冷月报》 "),
                      _c("span", {}, [_vm._v(_vm._s(_vm.previewData.timeDate))])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "date-box",
                      staticStyle: { "padding-top": "14px" }
                    },
                    [
                      _vm._v(
                        "\n                平均湿球温度:  \n                "
                      ),
                      _c("span", { staticClass: "number" }, [
                        _vm._v(_vm._s(_vm.previewData.wetBulbTemperature))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                机房平均COP: \n                "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.previewData.monthAverageCop) +
                          "\n                "
                      )
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        "  \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "number"
                      },
                      [_vm._v(_vm._s(_vm.previewData.copMonthDifference) + ",")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                机房折算COP: \n                "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.previewData.conversionCop) +
                          "\n                "
                      )
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        "  \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "number"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.conversionCopMonthDifference
                            ) +
                            ",\n                "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "date-box" },
                    [
                      _vm._v(
                        "\n                机房平均冷价\n                "
                      ),
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom-start",
                            title: "",
                            width: "300",
                            trigger: "click",
                            content: ""
                          }
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n                    使用电度电费计算的供冷单价,仅供参考。\n                  "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { attrs: { slot: "reference" }, slot: "reference" },
                            [
                              _c("i", {
                                staticClass: "el-icon-info",
                                staticStyle: { color: "#39b149" }
                              })
                            ]
                          )
                        ]
                      ),
                      _vm._v("\n                :  \n                "),
                      _c("span", { staticClass: "number" }, [
                        _vm._v(_vm._s(_vm.previewData.monthAverageColdPrice))
                      ]),
                      _vm._v(
                        "\n                元/kWh,  " +
                          _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                          "  \n                "
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isCurrentMonth,
                              expression: "!isCurrentMonth"
                            }
                          ],
                          staticClass: "number"
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.previewData.coldPriceMonthDifference) +
                              "\n                "
                          )
                        ]
                      ),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.isCurrentMonth ? "" : "元/kWh,") +
                          "\n              "
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                平均电价: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.avgElectricityPrices))
                    ]),
                    _vm._v(
                      "\n                元/度电, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.electricityPricesMonthDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "元/度电,") +
                        " \n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                总蓄冷量: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.totalStorageAmount))
                    ]),
                    _vm._v(
                      "\n                kWh, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.totalStorageAmountDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "kWh,") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                总供冷量:  \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.monthCoolingCapacity))
                    ]),
                    _vm._v(
                      "\n                kWh, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.coolingCapacityMonthDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "kWh,") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v(
                      "\n                蓄冷槽放冷量: \n                "
                    ),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.putColdAmount))
                    ]),
                    _vm._v("\n                kWh,  占: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.previewData.putColdAmountProportion) +
                          "%\n                "
                      )
                    ]),
                    _vm._v(
                      "\n                ," +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.previewData.putColdAmountDifference) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "kWh,") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                总耗电量: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.monthPowerConsumption))
                    ]),
                    _vm._v(
                      "\n                kWh,  " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.powerConsumptionMonthDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "kWh,") +
                        " \n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                削峰率: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.peakClippingRate) + "%")
                    ]),
                    _vm._v(",\n                ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                预估电费: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.monthEstimatedTariff))
                    ]),
                    _vm._v(
                      "\n                元, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.estimatedTariffMonthDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "元,") +
                        " \n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v(
                      "\n                预计节省电费: \n                "
                    ),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.saveElectricityBills))
                    ]),
                    _vm._v("\n                元;\n              ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                总供冷时长: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.monthTotalSupplyTime))
                    ]),
                    _vm._v(
                      "\n                小时, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.monthTotalSupplyTimeDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "小时,") +
                        " \n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v(
                      "\n                日均供冷时长: \n                "
                    ),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.dailyAverageSupplyTime))
                    ]),
                    _vm._v(
                      "\n                小时, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.dailyAverageSupplyTimeDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "小时,") +
                        " \n                "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "截至昨日" : "截至本月末") +
                        ",本机房全年综合COP值为: \n                "
                    ),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.yearCop))
                    ]),
                    _vm._v("\n                ,冷价为: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.yearColdPrice))
                    ]),
                    _vm._v("\n                元/kWh, 加油!\n              ")
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "remark" })
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              visible: _vm.dialogVisible,
              width: "30%",
              "modal-append-to-body": false
            },
            on: {
              "update:visible": function($event) {
                _vm.dialogVisible = $event
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "dialog-heard" },
              [
                _vm.$isBtnAuth("report:down")
                  ? _c(
                      "el-button",
                      {
                        staticClass: "download-box",
                        attrs: { plain: "", size: "small" },
                        on: { click: _vm.handlOneDown }
                      },
                      [_vm._v("下载")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      position: "absolute",
                      top: "15px",
                      right: "30px",
                      "font-weight": "bold",
                      color: "red",
                      "font-size": "20px",
                      cursor: "pointer"
                    },
                    on: {
                      click: function($event) {
                        _vm.dialogVisible = false
                      }
                    }
                  },
                  [_vm._v("\n            X\n          ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "ifrma-box active-box" }, [
                _vm.reportLogoVisible == 2
                  ? _c("div", { staticClass: "img-box" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.reportLogoVisible == 1
                  ? _c("div", { staticClass: "logoimg-box" })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "main-box preview_box" }, [
                  _c("div", { staticClass: "title-box" }, [
                    _vm._v("\n                《供冷月报》\n              ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _c("span", {}, [_vm._v(_vm._s(_vm.previewData.timeDate))])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v(
                      "\n                平均湿球温度:  \n                "
                    ),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(_vm._s(_vm.previewData.wetBulbTemperature))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                机房平均COP: \n                "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.previewData.monthAverageCop) +
                          "\n                "
                      )
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        "  \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "number"
                      },
                      [_vm._v(_vm._s(_vm.previewData.copMonthDifference) + ",")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                机房折算COP: \n                "),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.previewData.conversionCop) +
                          "\n                "
                      )
                    ]),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        "  \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "number"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.conversionCopMonthDifference
                            ) +
                            ",\n                "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v(
                      "\n                机房平均冷价:  \n                "
                    ),
                    _c("span", { staticClass: "number" }, [
                      _vm._v(_vm._s(_vm.previewData.monthAverageColdPrice))
                    ]),
                    _vm._v(
                      "\n                元/kWh,  " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        "  \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "number"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.previewData.coldPriceMonthDifference) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "元/kWh,") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                平均电价: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.avgElectricityPrices))
                    ]),
                    _vm._v(
                      "\n                元/度电, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.electricityPricesMonthDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "元/度电,") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.electricityPricesPercentage
                            ) +
                            "%\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : ";") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                总蓄冷量: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.totalStorageAmount))
                    ]),
                    _vm._v(
                      "\n                kWh, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.totalStorageAmountDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "kWh,") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                总供冷量:  \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.monthCoolingCapacity))
                    ]),
                    _vm._v(
                      "\n                kWh, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.coolingCapacityMonthDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "kWh,") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box fpg" }, [
                    _vm._v("\n                峰:  "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        _vm._s(
                          _vm.previewData.coolingCapacityDataList
                            ? _vm.previewData.coolingCapacityDataList[0]
                                .dataValue
                            : 0
                        ) + "kWh"
                      )
                    ]),
                    _vm._v("   平:  "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        _vm._s(
                          _vm.previewData.coolingCapacityDataList
                            ? _vm.previewData.coolingCapacityDataList[1]
                                .dataValue
                            : 0
                        ) + "kWh"
                      )
                    ]),
                    _vm._v("   谷:  "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        _vm._s(
                          _vm.previewData.coolingCapacityDataList
                            ? _vm.previewData.coolingCapacityDataList[2]
                                .dataValue
                            : 0
                        ) + "kWh"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v(
                      "\n                蓄冷槽放冷量: \n                "
                    ),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.putColdAmount))
                    ]),
                    _vm._v("\n                kWh,  占: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.previewData.putColdAmountProportion) +
                          "%\n                "
                      )
                    ]),
                    _vm._v(
                      "\n                ," +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.previewData.putColdAmountDifference) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "kWh;") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box fpg" }, [
                    _vm._v("\n                峰:  "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        _vm._s(
                          _vm.previewData.putColdAmountDataList
                            ? _vm.previewData.putColdAmountDataList[0].dataValue
                            : 0
                        ) + "kWh"
                      )
                    ]),
                    _vm._v("   平:  "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        _vm._s(
                          _vm.previewData.putColdAmountDataList
                            ? _vm.previewData.putColdAmountDataList[1].dataValue
                            : 0
                        ) + "kWh"
                      )
                    ]),
                    _vm._v("   谷:  "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        _vm._s(
                          _vm.previewData.putColdAmountDataList
                            ? _vm.previewData.putColdAmountDataList[2].dataValue
                            : 0
                        ) + "kWh"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                总耗电量: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.monthPowerConsumption))
                    ]),
                    _vm._v(
                      "\n                kWh,  " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.powerConsumptionMonthDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "kWh,") +
                        "\n                 \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.previewData.powerConsumptionPercentage) +
                            "%\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : ";") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box fpg" }, [
                    _vm._v("\n                峰:  "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        _vm._s(
                          _vm.previewData.powerConsumptionDataList
                            ? _vm.previewData.powerConsumptionDataList[0]
                                .dataValue
                            : 0
                        ) + "kWh"
                      )
                    ]),
                    _vm._v("   平:  "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        _vm._s(
                          _vm.previewData.powerConsumptionDataList
                            ? _vm.previewData.powerConsumptionDataList[1]
                                .dataValue
                            : 0
                        ) + "kWh"
                      )
                    ]),
                    _vm._v("   谷:  "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(
                        _vm._s(
                          _vm.previewData.powerConsumptionDataList
                            ? _vm.previewData.powerConsumptionDataList[2]
                                .dataValue
                            : 0
                        ) + "kWh"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                削峰率: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.peakClippingRate) + "%")
                    ]),
                    _vm._v(
                      "\n                , " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.previewData.peakClippingRatePercentage) +
                            "%\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : ";") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                预估电费: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.monthEstimatedTariff))
                    ]),
                    _vm._v(
                      "\n                元, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.estimatedTariffMonthDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "元,") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.previewData.estimatedTariffPercentage) +
                            "%\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : ";") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v(
                      "\n                预计节省电费: \n                "
                    ),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.saveElectricityBills))
                    ]),
                    _vm._v("\n                元;\n              ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v("\n                总供冷时长: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.monthTotalSupplyTime))
                    ]),
                    _vm._v(
                      "\n                小时, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.monthTotalSupplyTimeDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "小时,") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.previewData.totalSupplyTimePercentage) +
                            "%\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : ";") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v(
                      "\n                日均供冷时长: \n                "
                    ),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.dailyAverageSupplyTime))
                    ]),
                    _vm._v(
                      "\n                小时, " +
                        _vm._s(_vm.isCurrentMonth ? "" : "环比:") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.dailyAverageSupplyTimeDifference
                            ) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : "小时,") +
                        " \n                "
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCurrentMonth,
                            expression: "!isCurrentMonth"
                          }
                        ],
                        staticClass: "numbox"
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.previewData.dailyAverageSupplyTimePercentage
                            ) +
                            "%\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "" : ";") +
                        "\n              "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date-box" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.isCurrentMonth ? "截至昨日" : "截至本月末") +
                        ",本机房全年综合COP值为: \n                "
                    ),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.yearCop))
                    ]),
                    _vm._v("\n                ,冷价为: \n                "),
                    _c("span", { staticClass: "numbox" }, [
                      _vm._v(_vm._s(_vm.previewData.yearColdPrice))
                    ]),
                    _vm._v("\n                元/kWh, 加油!\n              ")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "remark" })
              ])
            ])
          ]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticStyle: {
          position: "absolute",
          left: "0px",
          top: "0px",
          background: "rgba(0, 0, 0, 0.4)",
          width: "100%",
          height: "100%",
          filter: "alpha(opacity=60)",
          display: "none",
          "z-index": "1"
        },
        attrs: { id: "magnifyChart1" }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              position: "relative",
              width: "66%",
              height: "66%",
              margin: "8% auto",
              "z-index": "2",
              "background-color": "rgb(255, 255, 255)",
              "-webkit-tap-highlight-color": "transparent",
              "user-select": "none"
            }
          },
          [
            _c(
              "span",
              { staticClass: "close", on: { click: _vm.handleClose } },
              [_vm._v("X")]
            ),
            _vm._v(" "),
            _c("div", {
              style:
                "width: 100%;height: 100%;zoom:" +
                _vm.yl_zoomrAtio +
                ";transform:scale(" +
                _vm.yl_zoom +
                ");transform-origin:0px 0px;",
              attrs: { id: "magnifyChartData" }
            })
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-title" }, [
      _c("span", [_vm._v("能耗概览")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-title" }, [
      _c("span", [_vm._v("供冷分布")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "line-title" }, [
      _c("span", [_vm._v("能效概览")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
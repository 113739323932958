"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _indexCool = _interopRequireDefault(require("@/components/Header/index-cool"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "coolLayout",
  components: {
    Header: _indexCool.default
  },
  computed: {
    key: function key() {
      return this.$route.fullPath;
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {},
  props: {
    heatData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      temperature: []
    };
  },
  watch: {
    heatData: function heatData(val) {
      var _this = this;

      this.temperature = [];
      val.map(function (e, index) {
        for (var key in val[index]) {
          _this.temperature.push(val[index][key][0]);
        }
      });
    }
  }
};
exports.default = _default2;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _home = require("@/api/home");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    themeData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      number: '',
      status: 0,
      headerData: {
        dayCool: "",
        dayCop: "",
        dayElect: "",
        temperatureDifference: ""
      },
      wsIp: process.env.VUE_APP_BASE_API
    };
  },
  watch: {
    themeData: function themeData(val) {
      var _this = this;

      val.map(function (e, index) {
        for (var key in val[0]) {
          _this.number = val[0][key][0];
        }
      });
      this.status = val[1]["status"];
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.ws) {
      this.ws.close();
    }
  },
  //方法集合
  methods: {
    initWebSocket: function initWebSocket() {
      var _this2 = this;

      if (this.ws) {
        this.ws.close();
      }

      if ("WebSocket" in window) {
        var i = this.wsIp.indexOf("//");
        var url = this.wsIp.slice(i);
        this.ws = new WebSocket("".concat(process.env.VUE_APP_WSS_STATUS == "true" ? "wss" : "ws", ":").concat(url, "/websocketServer/projectHeader/").concat(this.$store.state.project.projectInfo.id));

        this.ws.onopen = function () {};

        this.ws.onmessage = function (message) {
          var headerData = JSON.parse(message.data);

          for (var key in headerData) {
            switch (key) {
              case "dayCool":
                _this2.headerData["dayCool"] = headerData[key][0];
                break;

              case "dayCop":
                _this2.headerData["dayCop"] = headerData[key][0];
                break;

              case "dayElect":
                _this2.headerData["dayElect"] = headerData[key][0];
                break;

              case "temperatureDifference":
                _this2.headerData["temperatureDifference"] = headerData[key][0];
                break;
            }
          }
        };
      }
    },
    getProjectHeaderData: function getProjectHeaderData() {
      var _this3 = this;

      (0, _home.getProjectHeader)({
        projectId: this.$store.state.project.projectInfo.id
      }).then(function (res) {
        for (var key in res) {
          switch (key) {
            case "dayCool":
              _this3.headerData["dayCool"] = res[key][0];
              break;

            case "dayCop":
              _this3.headerData["dayCop"] = res[key][0];
              break;

            case "dayElect":
              _this3.headerData["dayElect"] = res[key][0];
              break;

            case "temperatureDifference":
              _this3.headerData["temperatureDifference"] = res[key][0];
              break;
          }
        }
      });
    }
  },
  created: function created() {
    this.getProjectHeaderData();
    this.initWebSocket();
  },
  mounted: function mounted() {}
};
exports.default = _default2;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-right" }, [
    _c("div", { staticClass: "header" }, [_c("headr")], 1),
    _vm._v(" "),
    _c("div", { staticClass: "right-box" }, [
      _c("iframe", {
        ref: "iframe",
        attrs: {
          id: "bdIframe",
          src: _vm.srcLink,
          frameborder: "0",
          scrolling: "yes"
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
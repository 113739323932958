"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _indexCool = _interopRequireDefault(require("@/components/head/index-cool"));

var _dayReport = _interopRequireDefault(require("./components-cool/dayReport"));

var _monthReport = _interopRequireDefault(require("./components-cool/monthReport"));

var _yearReport = _interopRequireDefault(require("./components-cool/yearReport"));

var _runType = _interopRequireDefault(require("./components-cool/runType"));

var _alarm = _interopRequireDefault(require("./components-cool/alarm"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    headr: _indexCool.default,
    day: _dayReport.default,
    month: _monthReport.default,
    year: _yearReport.default,
    runType: _runType.default,
    alarm: _alarm.default
  },
  data: function data() {
    return {
      activeName: "",
      yuming: "http://toncentsoft.eatuo.com:8091/energyExcel/益田假日广场历史数据报表1608863633345.xlsx",
      // 域名  管理方便一点  /m 域名下的文件夹
      viewVisible: false,
      // 弹框隐藏
      fileType: "pdf",
      // 文件类型
      pdfsrc: "",
      // 文件地址
      menusList: []
    };
  },
  computed: {},
  destroyed: function destroyed() {
    sessionStorage.removeItem("firstInit");
  },
  created: function created() {
    // 初始化重置样式
    if (!sessionStorage.getItem("firstInit")) {
      //this.$router.go(0);
      sessionStorage.setItem("firstInit", true);
    } // 获取动态菜单


    var menus = JSON.parse(sessionStorage.getItem('umenuauthsice'));
    var list = [];

    if (menus.length > 0) {
      menus.forEach(function (item) {
        if (item.path == 'report-download-cool') {
          item.children.forEach(function (ite) {
            if (ite.type === 0) {
              list.push(ite);
            }
          });
        }
      });
      this.menusList = list.sort(this.sortBy('priority'));
      this.activeName = 'tag' + this.menusList[0].id;
    }
  },
  mounted: function mounted() {
    this.tabsCode(); //tab
  },
  methods: {
    // 排序
    sortBy: function sortBy(field) {
      //根据传过来的字段进行排序
      return function (x, y) {
        return x[field] - y[field];
      };
    },
    handleClick: function handleClick() {},
    handleClickOne: function handleClickOne() {
      var url = this.yuming;
      this.viewVisible = true;
      this.pdfsrc = url;
    },
    //获取当前点击下标
    tabsCode: function tabsCode() {
      // 比较结果activeName的值
      if (this.$route.query.activeName != null) {
        this.activeName = this.$route.query.activeName;
      }
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRunType = getRunType;

var _request = _interopRequireDefault(require("@/utils/request"));

function getRunType(projectId, time) {
  return (0, _request.default)({
    url: "/projectFieldInfo/getRunType",
    method: "get",
    params: {
      projectId: projectId,
      time: time
    }
  });
}
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLogin = getLogin;
exports.getImg = getImg;
exports.getCurrMenus = getCurrMenus;
exports.getRefreshToken = getRefreshToken;
exports.getProjectQuestionInfo = getProjectQuestionInfo;
exports.postUploadFileByQuestionInfo = postUploadFileByQuestionInfo;
exports.getProjectQuestionCount = getProjectQuestionCount;
exports.relevanceProjectOrder = relevanceProjectOrder;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * @description: 登录
 * @param {type} encrypt_type 0 无加密 | 1 加密
 * @param {type} Object username 用户名
 * @param {type} Object password 密码
 * @return {type} Object token
 * @return {type} Object username 用户名
 * @return {type} Object telephone 手机号
 * @return {type} Object name 姓名
 * @return {type} Object needChangePaw 是否需要修改密码
 * @return {type} Object roleName 角色名称
 * @return {type} Object roleIdentity 角色标识
 */
function getLogin(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/login",
    method: "post",
    params: {
      encryptType: encryptType
    },
    data: params
  });
} // 获取登录图片


function getImg() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/landingImage/energyLandingImageInfo",
    method: "get",
    params: {
      encryptType: encryptType
    }
  });
} // 获取系统的菜单列表 projectType 0常规 1蓄冷


function getCurrMenus(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/users/getFrontMenu",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 刷新Token


function getRefreshToken(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/token/refresh",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 反馈


function getProjectQuestionInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectQuestionInfo/insertProjectQuestionInfo",
    method: "post",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 反馈上传图片


function postUploadFileByQuestionInfo(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectQuestionInfo/uploadFileByQuestionInfo",
    method: "post",
    params: {
      encryptType: encryptType
    },
    data: data
  });
}

function getProjectQuestionCount() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectQuestionInfo/questionCount",
    method: "get",
    params: {
      encryptType: encryptType
    }
  });
} // 用户关联的项目信息,工单标识信息


function relevanceProjectOrder(userId) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectIdOrWorkOrderIdentListByUserId/".concat(userId),
    method: "get",
    params: {
      encryptType: encryptType
    }
  });
}
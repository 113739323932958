"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDayHistoryAnalysis = getDayHistoryAnalysis;
exports.getAnalysisDataByDay = getAnalysisDataByDay;
exports.historyAnalysisType = historyAnalysisType;
exports.getMonthHistoryAnalysis = getMonthHistoryAnalysis;
exports.getAnalysisDataByMonth = getAnalysisDataByMonth;
exports.getEnergyConsumptionCalendar = getEnergyConsumptionCalendar;
exports.getColdConsumptionCalendar = getColdConsumptionCalendar;
exports.getConsumptionBatch = getConsumptionBatch;
exports.getColdPrice = getColdPrice;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// 日折线图——常规、蓄冷
function getDayHistoryAnalysis(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDataAnalysis/dayHistoryAnalysis",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getAnalysisDataByDay(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/analysisDataCoolStorage/analysisDataByDay",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 获取数据分析tab栏表头


function historyAnalysisType(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDataAnalysis/historyAnalysisType",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 月折线图——常规、蓄冷


function getMonthHistoryAnalysis(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDataAnalysis/monthHistoryAnalysis",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getAnalysisDataByMonth(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/analysisDataCoolStorage/monthProjectReportData",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 能效日历——常规、蓄冷


function getEnergyConsumptionCalendar(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDataAnalysis/energyConsumptionCalendar",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getColdConsumptionCalendar(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDataAnalysis/energyColdConsumptionCalendar",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 能效日历头——常规、蓄冷


function getConsumptionBatch(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectConsumption/projectConsumptionList",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getColdPrice(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectColdPrice/projectColdPriceInfo",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}
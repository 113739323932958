"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _message = _interopRequireDefault(require("element-ui/lib/message"));

var _messageBox = _interopRequireDefault(require("element-ui/lib/message-box"));

var _vue = _interopRequireDefault(require("vue"));

_vue.default.prototype.$confirm = _messageBox.default.confirm;
_vue.default.prototype.$message = _message.default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _home = require("@/api/home");

var _projectSelect = _interopRequireDefault(require("@/components/projectSelect"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Select: _projectSelect.default
  },
  data: function data() {
    return {
      projectName: this.$store.state.project.projectInfo.entryName,
      imgUrl: "",
      projectList: [],
      headerData: {
        dayCool: "",
        dayCop: "",
        dayElect: "",
        temperatureDifference: ""
      },
      wsIp: process.env.VUE_APP_BASE_API
    };
  },
  computed: {},
  created: function created() {
    this.getProjectHeaderData();
    this.getLogoImgData();
    this.initWebSocket();

    if (JSON.parse(sessionStorage.getItem("projectInfo"))) {
      this.projectInfo = JSON.parse(sessionStorage.getItem("projectInfo"));
    }

    if (JSON.parse(sessionStorage.getItem("projectList"))) {
      this.projectList = JSON.parse(sessionStorage.getItem("projectList"));
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.ws) {
      this.ws.close();
    }
  },
  methods: {
    getLogoImgData: function getLogoImgData() {
      var _this = this;

      var params = {
        projectId: this.$store.state.project.projectInfo.id,
        type: 0
      };
      (0, _home.getLogoImg)(params).then(function (res) {
        if (res) {
          _this.imgUrl = res.projectLogoPicture;
        }
      });
    },
    initWebSocket: function initWebSocket() {
      var _this2 = this;

      if (this.ws) {
        this.ws.close();
      }

      if ("WebSocket" in window) {
        var i = this.wsIp.indexOf("//");
        var url = this.wsIp.slice(i);
        this.ws = new WebSocket("".concat(process.env.VUE_APP_WSS_STATUS == "true" ? "wss" : "ws", ":").concat(url, "/websocketServer/projectHeader/").concat(this.$store.state.project.projectInfo.id));

        this.ws.onopen = function () {};

        this.ws.onmessage = function (message) {
          var headerData = JSON.parse(message.data);

          for (var key in headerData) {
            switch (key) {
              case "dayCool":
                _this2.headerData["dayCool"] = headerData[key][0];
                break;

              case "dayCop":
                _this2.headerData["dayCop"] = headerData[key][0];
                break;

              case "dayElect":
                _this2.headerData["dayElect"] = headerData[key][0];
                break;

              case "temperatureDifference":
                _this2.headerData["temperatureDifference"] = headerData[key][0];
                break;
            }
          }
        };
      }
    },
    getProjectHeaderData: function getProjectHeaderData() {
      var _this3 = this;

      (0, _home.getProjectHeader)({
        projectId: this.$store.state.project.projectInfo.id
      }).then(function (res) {
        for (var key in res) {
          switch (key) {
            case "dayCool":
              _this3.headerData["dayCool"] = res[key][0];
              break;

            case "dayCop":
              _this3.headerData["dayCop"] = res[key][0];
              break;

            case "dayElect":
              _this3.headerData["dayElect"] = res[key][0];
              break;

            case "temperatureDifference":
              _this3.headerData["temperatureDifference"] = res[key][0];
              break;
          }
        }
      });
    }
  }
};
exports.default = _default;
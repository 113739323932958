"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _equipment = require("@/api/equipment");

var _dataQuery = require("@/api/dataQuery");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      form: {
        page: 1,
        rows: 10,
        deviceName: '',
        projectId: this.$store.state.project.projectInfo.id
      },
      loading: false,
      total: 0,
      tableData: [],
      dialogVisible: false,
      dialogTitle: '新增',
      importLoading: false,
      selection: [],
      dialogForm: {
        deviceName: '',
        deviceDesc: '',
        fieldId: null,
        fieldName: '',
        maintenanceTime: '',
        maintenanceCycle: '',
        id: null,
        projectId: this.$store.state.project.projectInfo.id
      },
      options: []
    };
  },
  mounted: function mounted() {
    this.getDeviceManager();
    this.getProjectSystemAllList();
  },
  methods: {
    // 格式化传过来的时间
    formatterMaintenanceTime: function formatterMaintenanceTime(row, column) {
      return this.dayjs(row.maintenanceTime).format('YYYY-MM-DD');
    },
    today: function today() {
      this.dialogForm.maintenanceTime = this.dayjs().format('YYYY-MM-DD 00:00:00');
    },
    getDeviceManager: function getDeviceManager() {
      var _this = this;

      this.loading = true;
      (0, _equipment.getDeviceManager)(this.form).then(function (res) {
        _this.loading = false;
        _this.tableData = res.rows;
        _this.total = res.total;
      }).catch(function (err) {});
    },
    reset: function reset() {
      this.form = {
        page: 1,
        rows: 10,
        deviceName: '',
        projectId: this.$store.state.project.projectInfo.id
      };
      this.getDeviceManager();
    },
    empty: function empty() {
      this.dialogForm = {
        deviceName: '',
        deviceDesc: '',
        fieldId: null,
        fieldName: '',
        maintenanceTime: '',
        maintenanceCycle: '',
        id: null,
        projectId: this.$store.state.project.projectInfo.id
      };
    },
    add: function add() {
      this.empty();
      this.dialogTitle = '新增';
      this.dialogVisible = true;
    },
    batchImport: function batchImport(file) {
      var _this2 = this;

      var excel = file.target.files[0];

      if (excel) {
        var formData = new FormData();
        formData.append("file", excel);
        formData.append("projectId", this.$store.state.project.projectInfo.id);
        (0, _equipment.importData)(formData).then(function () {
          _this2.$refs.file.value = "";

          _this2.getDeviceManager();

          _this2.$message.success("导入成功");
        }).catch(function () {
          _this2.$refs.file.value = "";
        });
      }
    },
    importTemplate: function importTemplate() {
      var _this3 = this;

      this.importLoading = true;
      (0, _equipment.downloadTemplate)().then(function (res) {
        var link = document.createElement("a");
        var blob = new Blob([res], {
          type: "application/vnd.ms-excel"
        }); //文件流处理

        link.style.display = "none";
        link.href = URL.createObjectURL(blob);

        var date = _this3.dayjs().format('YYYY-MM-DD HH时ss分mm秒');

        link.download = "".concat('设备管理模板' + date + '.xlsx');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        _this3.importLoading = false;
      }).catch(function (err) {
        _this3.importLoading = false;
        console.log('err', err);
      });
    },
    selectionChange: function selectionChange(val) {
      this.selection = val;
    },
    batchDel: function batchDel() {
      var _this4 = this;

      if (this.selection.length > 0) {
        this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          var ids = [];

          _this4.selection.forEach(function (item) {
            ids.push(item.id);
          });

          var params = {
            ids: ids
          };
          (0, _equipment.delDateDeviceManager)(params).then(function (res) {
            _this4.$message.success("批量删除成功");

            _this4.getDeviceManager();
          });
        });
      } else {
        this.$message('请勾选要批量删除的数据');
      }
    },
    handleSizeChange: function handleSizeChange(val) {
      this.form.page = 1;
      this.form.rows = val;
      this.getDeviceManager();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.form.page = val;
      this.getDeviceManager();
    },
    edit: function edit(row) {
      this.empty();
      this.dialogTitle = '编辑';
      this.dialogVisible = true;
      this.dialogForm = row;
    },
    del: function del(id) {
      var _this5 = this;

      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var params = {
          ids: [id]
        };
        (0, _equipment.delDateDeviceManager)(params).then(function (res) {
          _this5.$message.success("删除成功");

          _this5.getDeviceManager();
        });
      });
    },
    // 格式化级联数据添加label value属性
    formaterAllList: function formaterAllList(allList) {
      var _this6 = this;

      allList.map(function (m) {
        m.label = m.name;
        m.value = m.id;

        if (m.children) {
          _this6.formaterAllList(m.children);
        }
      });
    },
    getProjectSystemAllList: function getProjectSystemAllList() {
      var _this7 = this;

      var params = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataQuery.projectSystemAllList)(params).then(function (res) {
        var allList = res;

        _this7.formaterAllList(allList);

        _this7.options = allList;
      }).catch(function (err) {
        console.log('err', err);
      });
    },
    // 递归级联数据查找与最后一个value相匹配的label
    recursionAllList: function recursionAllList(allList, value) {
      var _this8 = this;

      allList.forEach(function (item) {
        if (item.children && item.children.length > 0) {
          _this8.recursionAllList(item.children, value);
        } else {
          if (item.value == value) {
            _this8.dialogForm.fieldName = item.label;
          }
        }
      });
    },
    handleChange: function handleChange(value) {
      var len = value.length - 1;
      this.dialogForm.fieldId = value[len];
      this.recursionAllList(this.options, value[len]);
    },
    createData: function createData(formName) {
      var _this9 = this;

      this.$refs[formName].validate(function (valid) {
        // 验证通过
        if (valid) {
          (0, _equipment.insertOrUpdateDeviceManager)(_this9.dialogForm).then(function () {
            _this9.dialogVisible = false;

            _this9.getDeviceManager();

            _this9.$message.success("新增成功");
          });
        }
      });
    },
    updateData: function updateData(formName) {
      var _this10 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _equipment.insertOrUpdateDeviceManager)(_this10.dialogForm).then(function () {
            _this10.dialogVisible = false;

            _this10.getDeviceManager();

            _this10.$message.success("更新成功");
          });
        }
      });
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dataQuery = require("@/api/dataQuery");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      form: {
        timeType: 2,
        startTime: "",
        endTime: "",
        projectType: this.$store.state.project.projectInfo.projectType,
        projectId: this.$store.state.project.projectInfo.id
      },
      isShow: 2,
      loading: false,
      userInfo: {},
      value1: [],
      dayValue: [],
      historicalData: [],
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      colors: ["#3e89e7", "#fb8c3b", "#63be65", "#d9c32b", "#e11aed", "#26c9e7", "#25c1a9", "#1665D8", "#dd3710", "#48a221", "#ffac30", "#75deff", "#8656ff", "#98FF25", "#FFF100", "#ee2dff", "#2deeff", "#ffac2a", "#91c7ae", "#749f83", "#ca8622", "#bda29a", "#6e7074", "#546570", "#c4ccd3"],
      zoom: '',
      zoomrAtio: ''
    };
  },
  //
  watch: {
    isShow: function isShow(val) {
      this.form.timeType = val;
      this.getDataQueryData();
    }
  },
  mounted: function mounted() {
    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }

    this.getDataQueryData();
  },
  methods: {
    handleQuery: function handleQuery() {
      this.getDataQueryData();
    },
    handleDown: function handleDown() {
      this.getDataQueryExportData();
    },
    handleTime: function handleTime(val) {
      this.form.timeType = 0;
      this.isShow = 0;
      this.form.startTime = val[0];
      this.form.endTime = val[1];
    },
    getDataQueryExportData: function getDataQueryExportData() {
      var _this2 = this;

      this.loading = true;
      setTimeout(function () {
        _this2.loading = false;
      }, 2000);
      (0, _dataQuery.getDataQueryExport)(this.form).then(function (res) {
        var link = document.createElement("a");
        link.href = res;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getDataQueryData: function getDataQueryData() {
      var _this3 = this;

      (0, _dataQuery.getDataQuery)(this.form).then(function (res) {
        _this3.historicalData = res;

        _this3.getLineCharts();
      });
    },
    getLineCharts: function getLineCharts() {
      var _this4 = this;

      var lineCharts = this.$echarts.init(document.getElementById("line"));
      lineCharts.clear();

      var _this = this;

      var historicalData = [];

      _this.historicalData.map(function (e, i) {
        historicalData.push({
          type: "line",
          name: e.fieldMeaning,
          dimensions: [e.company],
          symbol: "circle",
          showSymbol: false,
          data: e.data
        });
      });

      var option = {
        tooltip: {
          confine: true,
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,1)",
          extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#333333",
          textStyle: {
            fontSize: 14,
            lineHeight: 34
          },
          padding: 15,
          formatter: function formatter(params) {
            var str = "";
            str = params[0].name;
            params.map(function (el) {
              str = str + "<br/><span class=\"color-circle\" style=\"background:".concat(el.color, ";\"></span> ").concat(el.seriesName, " : ").concat(el.value[1], "  ").concat(el.dimensionNames[0] === "x" ? "" : el.dimensionNames[0]);
            });
            return str;
          }
        },
        color: _this.colors,
        legend: {
          icon: "circle",
          top: "1%",
          bottom: "5%",
          type: "scroll"
        },
        grid: {
          left: "3%",
          right: "3%",
          top: "10%",
          bottom: "10%",
          containLabel: true
        },
        dataZoom: [{
          type: "slider",
          height: 20,
          handleStyle: {
            borderColor: "#D2D0FF",
            shadowBlur: 4,
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowColor: "#e5e5e5"
          },
          show: true,
          // flase直接隐藏图形
          // xAxisIndex: [0],
          left: "4%",
          // 滚动条靠左侧的百分比
          bottom: 15,
          handleIcon: "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
          handleSize: "100%",
          start: 0,
          // 滚动条的起始位置
          end: 100 // 滚动条的截止位置（按比例分割你的柱状图x轴长度）

        }],
        xAxis: {
          type: "category",
          boundaryGap: false,
          splitLine: {
            show: false
          },
          // 去除网格线
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            interval: 0,
            formatter: function formatter(params) {
              if (params) {
                var newParamsName = "";
                var paramsNameNumber = params.length;
                var provideNumber = 10; // 一行显示几个字

                var rowNumber = Math.ceil(paramsNameNumber / provideNumber);

                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    var start = p * provideNumber;
                    var end = start + provideNumber;

                    if (p === rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      tempStr = params.substring(start, end) + "\n";
                    }

                    newParamsName += tempStr;
                  }
                } else {
                  newParamsName = params;
                }

                return newParamsName;
              }
            },
            textStyle: {
              color: "#bbbbbb" // 文字颜色

            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#cfcfcf",
              width: 1,
              type: "solid"
            }
          }
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#cfcfcf",
              width: 1,
              type: "solid"
            }
          }
        },
        series: historicalData
      };
      lineCharts.setOption(option);
      this.$nextTick(function () {
        _this4.zoom = document.body.style.zoom;
        _this4.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _dayjs = _interopRequireDefault(require("dayjs"));

var _report = require("@/api/report");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import axios from 'axios'
var _default = {
  filters: {
    numFilter: function numFilter(value) {
      var realVal = Number(value).toFixed(0);
      return Number(realVal);
    }
  },
  data: function data() {
    return {
      powerType: [{
        name: "总供冷量"
      }, {
        name: "蓄槽总供冷量"
      }],
      activeIndexNhfb: 0,
      resetReportLoading: false,
      dateArr: [],
      // 选择多个时间,用于批量下载
      barType: 1,
      value: (0, _dayjs.default)().format("YYYY-MM"),
      isShow: true,
      checked: true,
      calendarData: [],
      haveData: [],
      reportData: [],
      previewSrc: "",
      previewSrcOne: "",
      previewData: {},
      dialogVisible: false,
      reportLogoVisible: 1,
      dayActive: (0, _dayjs.default)().format("YYYY-MM-DD"),
      currentDay: (0, _dayjs.default)().format("YYYY-MM-DD"),
      monthData: (0, _dayjs.default)().format("YYYY-MM"),
      userInfo: {},
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      zoom: '',
      zoomrAtio: ''
    };
  },
  watch: {
    value: function value(val) {
      this.monthData = (0, _dayjs.default)(val).format("YYYY-MM");
    },
    monthData: function monthData(val) {
      this.dayActive = val;
      this.calendarData = [];
      this.getDayReportListData();
    },
    previewData: function previewData(val) {
      this.getHdlChart();
      this.getGlfbChart();
      this.getZgllChart();
    }
  },
  created: function created() {
    // 每月1号显示上个月
    if (this.dayjs().date() == 1) {
      this.monthData = this.dayjs().subtract(1, "month").format("YYYY-MM");
      this.value = this.monthData;
      var dayNumber = this.dayjs(this.monthData).daysInMonth() - 1;
      this.currentDay = this.dayjs(this.monthData).add(dayNumber, "day").format("YYYY-MM-DD");
      this.dayTime = this.currentDay;
    }

    this.reportLogoVisible = this.$store.state.project.projectInfo.reportType;

    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } // 判断路由的数据


    if (this.$route.query.day) {
      this.value = this.$route.query.day;

      if ((0, _dayjs.default)(this.$route.query.day).format("YYYY-MM") === (0, _dayjs.default)().format("YYYY-MM")) {
        this.calendarData = [];
        this.getDayReportListData();
      }
    } else {
      this.getDayReportListData();
    }
  },
  methods: {
    handelPowerType: function handelPowerType(value) {
      this.barType = value;
      this.getZgllChart();
    },
    // 重新生成日报
    handleResetReport: function handleResetReport() {
      var _this = this;

      this.resetReportLoading = true;
      (0, _report.getDayProjectCoolStorageReportRegenerate)({
        dayTime: (0, _dayjs.default)(this.dayActive).format("YYYY-MM-DD HH:mm:ss"),
        projectId: this.$store.state.project.projectInfo.id
      }).then(function (res) {
        _this.resetReportLoading = false;

        _this.$message.success("重新生成日报成功");
      }).catch(function (err) {
        _this.resetReportLoading = false;
      });
    },
    // 绘制耗电量
    getHdlChart: function getHdlChart() {
      var _this2 = this;

      var lineCharts = this.$echarts.init(document.getElementById("hdl"));
      var seriesData = [{
        value: this.previewData.powerConsumptionLowEbb,
        name: "低谷时段"
      }, {
        value: this.previewData.powerConsumptionSummit,
        name: "高峰时段"
      }, {
        value: this.previewData.powerConsumptionLevel,
        name: "平时段"
      }];
      lineCharts.clear();
      var option = {
        color: ["#91cc75", "#ee6666", "#fac858"],
        title: {
          // text: `总：${this.previewData.powerConsumption}`,
          right: 10,
          top: 0,
          bottom: 20,
          textStyle: {
            fontSize: 16,
            color: "#333",
            fontWeight: 400
          }
        },
        tooltip: {
          confine: true,
          trigger: "item",
          borderWidth: 0,
          formatter: function formatter(params) {
            return "".concat(params.name, "<br />").concat(params.marker).concat(params.value, ", \u5360\u6BD4:").concat(params.percent, "%");
          },
          textStyle: {
            fontSize: 18,
            lineHeight: 30
          },
          padding: 15
        },
        series: [{
          name: "耗电量",
          type: "pie",
          radius: "60%",
          data: seriesData
        }]
      };
      lineCharts.setOption(option);
      this.$nextTick(function () {
        _this2.zoom = document.body.style.zoom;
        _this2.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    },
    // 绘制供冷分布
    getGlfbChart: function getGlfbChart() {
      var lineCharts = this.$echarts.init(document.getElementById("glfb"));
      lineCharts.clear();
      var seriesData = [{
        barWidth: 40,
        data: [this.previewData.coolingCapacitySummit, this.previewData.coolingCapacityLevel, this.previewData.coolingCapacityLowEbb],
        name: "总供冷量",
        type: "bar"
      }, {
        barGap: "-100%",
        barWidth: 40,
        data: [this.previewData.capacityColdStorageSummit, this.previewData.capacityColdStorageLevel, this.previewData.capacityColdStorageLowEbb],
        name: "冰槽供冷量",
        type: "bar"
      }];
      var option = {
        color: ["#5670c6", "#5dba2f"],
        grid: {
          bottom: 30,
          left: 80
        },
        textStyle: {
          fontSize: 14
        },
        title: {
          text: "供冷分布（kWh）",
          top: 12,
          left: 10,
          textStyle: {
            fontSize: 16,
            color: "#000",
            fontWeight: 500
          }
        },
        legend: {
          data: ["总供冷量", "冰槽供冷量"],
          right: 10,
          top: 12
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          borderWidth: 0,
          formatter: function formatter(params) {
            if (params.length > 1) {
              var zb1 = ((Number(params[0].value) + Number(params[1].value)) / (Number(params[0].value) + Number(params[1].value)) * 100).toFixed(2);
              var zb2 = (Number(params[1].value) / Number(params[0].value) * 100).toFixed(2);
              return "\u4F9B\u51B7\u5206\u5E03<br />\n                ".concat(params[0].marker).concat(params[0].seriesName, ":").concat(params[0].value, ",\u5360\u6BD4: ").concat(isNaN(zb1) ? 0 : zb1, "%<br />\n                ").concat(params[1].marker).concat(params[1].seriesName, ":").concat(params[1].value, ",\u5360\u6BD4: ").concat(isNaN(zb2) ? 0 : zb2, "%\n              ");
            } else {
              return "\u4F9B\u51B7\u5206\u5E03<br />\n                ".concat(params[0].marker).concat(params[0].seriesName, ":").concat(params[0].value, "<br />");
            }
          },
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          padding: 15
        },
        xAxis: {
          data: ["峰", "平", "谷"],
          axisLabel: {
            inside: false,
            textStyle: {
              color: "#333",
              fontSize: 14
            }
          }
        },
        yAxis: {
          splitLine: {
            show: false
          },
          minorSplitLine: {
            show: false
          }
        },
        series: seriesData
      };
      lineCharts.setOption(option);
    },
    // 绘制总供冷量
    getZgllChart: function getZgllChart() {
      var lineCharts = this.$echarts.init(document.getElementById("zgll"));
      var seriesData = [{
        value: this.barType === 1 ? this.previewData.coolingCapacitySummit : this.previewData.capacityColdStorageSummit,
        name: "峰"
      }, {
        value: this.barType === 1 ? this.previewData.coolingCapacityLevel : this.previewData.capacityColdStorageLevel,
        name: "平"
      }, {
        value: this.barType === 1 ? this.previewData.coolingCapacityLowEbb : this.previewData.capacityColdStorageLowEbb,
        name: "谷"
      }];
      lineCharts.clear();
      var option = {
        color: ["#ee6666", "#fac858", "#91cc75"],
        title: {
          text: this.barType === 1 ? this.previewData.coolingCapacity : this.previewData.capacityColdStorage,
          top: "46%",
          left: "center",
          textStyle: {
            fontSize: 14,
            fontWeight: 400
          }
        },
        textStyle: {
          fontSize: 14
        },
        tooltip: {
          confine: true,
          trigger: "item",
          borderWidth: 0,
          formatter: function formatter(params) {
            return "".concat(params.name, "<br />").concat(params.marker).concat(params.value, ", \u5360\u6BD4:").concat(params.percent, "%");
          },
          textStyle: {
            fontSize: 16,
            lineHeight: 30
          },
          padding: 15
        },
        legend: {
          bottom: "5%",
          left: "center",
          itemGap: 30
        },
        series: [{
          name: "总供冷量",
          type: "pie",
          radius: ["40%", "70%"],
          label: {
            show: false
          },
          data: seriesData
        }]
      };
      lineCharts.setOption(option);
    },
    handlOneDown: function handlOneDown() {
      if (this.previewSrc) {
        var link = document.createElement("a");
        link.href = this.previewSrc;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    handlePreview: function handlePreview() {
      this.dialogVisible = true;
    },
    getCoolDayWordPreviewData: function getCoolDayWordPreviewData(date) {
      var _this3 = this;

      var params = {
        dayTime: date,
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _report.getCoolDayWordPreview)(params).then(function (res) {
        _this3.previewData = res;
      });
    },
    handlDay: function handlDay(date, data, isShow) {
      var _this4 = this;

      // //console.log(11, date, data, isShow)
      if (isShow) {
        this.dayActive = data.day;
        this.reportData.map(function (e) {
          if ((0, _dayjs.default)(e.reportTime).format("YYYY-MM-DD") === data.day) {
            _this4.previewSrc = e.reportFilePath;

            _this4.getCoolDayWordPreviewData(data.day);
          }
        });
      } else {
        this.$message.warning("此日期暂无数据");
        this.$router.push({
          query: {}
        });
      }
    },
    handleBatchDown: function handleBatchDown() {
      if (this.calendarData.length !== 0) {
        this.getDayReportData();
      } else {
        this.$message.error("未选择下载日期");
      }
    },
    getDayReportListData: function getDayReportListData() {
      var _this5 = this;

      if (this.dayjs().month() == 0 && this.dayjs().date() == 1) {
        this.monthData = this.dayjs().subtract(1, "month").format("YYYY-MM");
      }

      var params = {
        projectId: this.$store.state.project.projectInfo.id,
        dateTime: this.monthData
      };
      (0, _report.getDayReportList)(params).then(function (res) {
        _this5.reportData = res;
        _this5.haveData = res.map(function (e) {
          // //console.log(dayjs(e.reportTime).format("YYYY-MM-DD"));
          return (0, _dayjs.default)(e.reportTime).format("YYYY-MM-DD");
        });
        _this5.haveData = (0, _toConsumableArray2.default)(new Set(_this5.haveData));

        if (res.length !== 0 && !_this5.$route.query.day) {
          var arr = res[res.length - 1];
          _this5.dayActive = (0, _dayjs.default)(arr.reportTime).format("YYYY-MM-DD");

          _this5.getCoolDayWordPreviewData(_this5.dayActive);

          _this5.previewSrc = arr.reportFilePath;
        } // 通过url获取


        var currentDay = _this5.$route.query.day ? _this5.$route.query.day : null;

        if (currentDay) {
          // this.value = currentDay
          // this.handleTime(dayjs(currentDay).format('YYYY-MM'))
          var day = (0, _dayjs.default)(currentDay).format("YYYY-MM-DD"); // //console.log(5555, this.haveData);

          _this5.handlDay(new Date(day), {
            day: day
          }, _this5.haveData.indexOf(day) !== -1);
        }
      });
    },
    getDayReportData: function getDayReportData() {
      var params = {
        projectId: this.$store.state.project.projectInfo.id,
        dayTime: this.calendarData
      };
      (0, _report.getDayReport)(params).then(function (res) {
        var link = document.createElement("a");
        link.href = res;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    handleTime: function handleTime(val) {
      // //console.log("选择日期", val);
      this.value = val;
    },
    formatDate: function formatDate(year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
    initData: function initData(cur) {
      var that = this;
      var days = []; //   const leftcount = 0 // 存放剩余数量

      var date;

      if (cur) {
        date = new Date(cur);
      } else {
        var now = new Date();
        var d = new Date(that.formatDate(now.getFullYear(), now.getMonth(), 1));
        d.setDate(35);
        date = new Date(that.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      }

      that.currentDay = date.getDate();
      that.currentYear = date.getFullYear();
      that.currentMonth = date.getMonth() + 1;
      that.currentWeek = date.getDay(); // 1...6,0

      if (that.currentWeek === 0) {
        that.currentWeek = 7;
      }

      var str = that.formatDate(that.currentYear, that.currentMonth, that.currentDay);
      days.length = 0; // 今天周日，放在第一行第7个位置，前面6个
      // 初始化本周

      for (var i = that.currentWeek - 1; i >= 0; i--) {
        var _d = new Date(str);

        _d.setDate(_d.getDate() - i);

        var dayobject = {}; // 用一个对象包装Date对象  以便以后预定功能添加属性

        dayobject.day = _d;
        days.push(dayobject); // 将日期放入data 中的days数组 供页面渲染使用
      } // 其他周


      for (var _i = 1; _i <= 42 - that.currentWeek; _i++) {
        var _d2 = new Date(str);

        _d2.setDate(_d2.getDate() + _i);

        var _dayobject = {};
        _dayobject.day = _d2;
        days.push(_dayobject);
      }

      return days;
    },
    handlCalendar: function handlCalendar(date, data) {
      if (this.calendarData.indexOf(data.day) !== -1) {
        this.calendarData = this.calendarData.filter(function (e) {
          return e !== data.day;
        });
      } else {
        this.calendarData.push(data.day);
      }
    },
    handleCheckAll: function handleCheckAll() {
      this.isShow = !this.isShow;

      if (!this.isShow) {
        // const arr = this.initData(this.monthData)
        this.calendarData = this.haveData;
      } else {
        this.calendarData = [];
      }
    },
    changeDay: function changeDay(val) {
      var _this6 = this;

      this.calendarData = val.filter(function (item) {
        return _this6.haveData.indexOf(item) !== -1;
      });
    }
  }
};
exports.default = _default;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "item" }, [
      _c("img", { attrs: { src: require("@/assets/big_wd.png"), alt: "" } }),
      _vm._v(" "),
      _c("div", [_vm._v("室外温度 " + _vm._s(_vm.temperature[0]) + " ℃")])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item" }, [
      _c("img", { attrs: { src: require("@/assets/big_sd.png"), alt: "" } }),
      _vm._v(" "),
      _c("div", [_vm._v("相对湿度 " + _vm._s(_vm.temperature[1]) + " %")])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "item" }, [
      _c("img", { attrs: { src: require("@/assets/big_sqwd.png"), alt: "" } }),
      _vm._v(" "),
      _c("div", [_vm._v("湿球温度 " + _vm._s(_vm.temperature[2]) + " ℃")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
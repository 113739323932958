var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "theme-box" }, [
    _c("div", { staticClass: "title" }, [_vm._v("能耗数据")]),
    _vm._v(" "),
    _c("div", { staticClass: "table" }, [
      _c("div", { staticClass: "box b1" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "num nx" }, [_vm._v(_vm._s(_vm.number))]),
          _vm._v(" "),
          _c("div", [_vm._v("实时能效")])
        ]),
        _vm._v(" "),
        _vm._m(1)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box b2" }, [
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "num" }, [
            _vm._v(_vm._s(_vm.headerData.dayElect) + " "),
            _c("span", { staticClass: "unit" }, [_vm._v("kWh")])
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("日耗电量")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "num" }, [
            _vm._v(_vm._s(_vm.headerData.dayCop))
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("日综合能效")])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "box b3" }, [
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "num" }, [
            _vm._v(_vm._s(_vm.headerData.dayCool) + " "),
            _c("span", { staticClass: "unit" }, [_vm._v("kWh")])
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("日供冷量")])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "num" }, [
            _vm._v(_vm._s(_vm.headerData.temperatureDifference) + " "),
            _c("span", { staticClass: "unit" }, [_vm._v("°C")])
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("冷冻水温度")])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("img", { attrs: { src: require("@/assets/nh_nxfx.png"), alt: "" } }),
      _vm._v(" "),
      _c("div", [_vm._v("能效")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "num" }),
      _vm._v(" "),
      _c("div")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("img", { attrs: { src: require("@/assets/nh_dl.png"), alt: "" } }),
      _vm._v(" "),
      _c("div", [_vm._v("电量")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item" }, [
      _c("img", { attrs: { src: require("@/assets/nh_ll.png"), alt: "" } }),
      _vm._v(" "),
      _c("div", [_vm._v("冷量")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
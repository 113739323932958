"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDeviceManager = getDeviceManager;
exports.downloadTemplate = downloadTemplate;
exports.insertOrUpdateDeviceManager = insertOrUpdateDeviceManager;
exports.importData = importData;
exports.delDateDeviceManager = delDateDeviceManager;
exports.updateStatus = updateStatus;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// 设备管理模板查询
function getDeviceManager(params) {
  return (0, _request.default)({
    url: "/deviceManager/getDeviceManager",
    method: 'get',
    params: _objectSpread({}, params)
  });
} // 设备管理模板下载


function downloadTemplate(params) {
  return (0, _request.default)({
    url: "/deviceManager/downloadTemplate",
    method: 'get',
    responseType: 'blob',
    params: _objectSpread({}, params)
  });
} // 设备管理新增修改


function insertOrUpdateDeviceManager(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/deviceManager/insertOrUpdateDeviceManager",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: params
  });
} // 设备管理模板查询批量导入


function importData(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/deviceManager/importData",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: params
  });
} //设备管理批量删除


function delDateDeviceManager(params) {
  return (0, _request.default)({
    url: "/deviceManager/delDateDeviceManager",
    method: 'delete',
    params: _objectSpread({}, params)
  });
} // 设备管理修改状态


function updateStatus(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/deviceManager/updateStatus",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: params
  });
}
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-box" },
    [
      _c(
        "el-dropdown",
        {
          staticStyle: { display: "flex", "align-items": "center" },
          attrs: { trigger: "click" },
          on: { command: _vm.handleClick }
        },
        [
          _c("img", {
            staticClass: "qh hvr-grow",
            attrs: { src: require("../../assets/svg/syicon_0.svg"), alt: "" }
          }),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            _vm._l(_vm.projectData, function(item, index) {
              return _c(
                "el-dropdown-item",
                {
                  key: index,
                  attrs: { icon: "el-icon-star-on", command: item },
                  nativeOn: {
                    mouseover: function($event) {
                      return _vm.handelMouseover(item.projectType, index)
                    },
                    mouseleave: function($event) {
                      return _vm.handelLeave(item.projectType)
                    }
                  }
                },
                [
                  _vm._v("\n        " + _vm._s(item.entryName) + " \n        "),
                  item.projectType
                    ? _c("img", {
                        attrs: {
                          src:
                            _vm.itemImgIndex == index
                              ? _vm.snowflakeiconcount
                              : _vm.snowflakeicon,
                          alt: ""
                        }
                      })
                    : _vm._e()
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-layout active" }, [
    _c(
      "div",
      { staticClass: "header-router", staticStyle: { height: "100%" } },
      [
        _c("div", { staticClass: "logo" }, [
          _vm.logoImg
            ? _c("img", {
                staticStyle: { width: "42px", height: "42px" },
                attrs: { src: _vm.logoImg, alt: "" }
              })
            : _c("img", {
                staticStyle: { width: "42px", height: "42px" },
                attrs: { src: require("../../assets/svg/logo.svg"), alt: "" }
              })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bottom", staticStyle: { height: "100%" } }, [
          _c("div", { staticStyle: { flex: "1" } }, [
            _vm.pathData.indexOf("home-page-cool") !== -1
              ? _c(
                  "div",
                  {
                    staticClass: "router-item",
                    on: {
                      click: function($event) {
                        return _vm.handleRoute("/home-page-cool", 0)
                      }
                    }
                  },
                  [
                    _vm.routerIndex == 0
                      ? _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon01.svg"),
                            alt: ""
                          }
                        })
                      : _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon1.svg"),
                            alt: ""
                          }
                        }),
                    _vm._v(" "),
                    _c("div", { class: _vm.routerIndex == 0 ? "active" : "" }, [
                      _vm._v("主页")
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pathData.indexOf("system-details-cool") !== -1
              ? _c(
                  "div",
                  {
                    staticClass: "router-item",
                    on: {
                      click: function($event) {
                        return _vm.handleRoute("/system-details-cool", 1)
                      }
                    }
                  },
                  [
                    _vm.routerIndex == 1
                      ? _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon02.svg"),
                            alt: ""
                          }
                        })
                      : _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon2.svg"),
                            alt: ""
                          }
                        }),
                    _vm._v(" "),
                    _c("div", { class: _vm.routerIndex == 1 ? "active" : "" }, [
                      _vm._v("详情")
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pathData.indexOf("data-query-cool") !== -1
              ? _c(
                  "div",
                  {
                    staticClass: "router-item",
                    on: {
                      click: function($event) {
                        return _vm.handleRoute("/data-query-cool", 3)
                      }
                    }
                  },
                  [
                    _vm.routerIndex == 3
                      ? _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon03.svg"),
                            alt: ""
                          }
                        })
                      : _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon3.svg"),
                            alt: ""
                          }
                        }),
                    _vm._v(" "),
                    _c("div", { class: _vm.routerIndex == 3 ? "active" : "" }, [
                      _vm._v("数据")
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pathData.indexOf("report-download-cool") !== -1
              ? _c(
                  "div",
                  {
                    staticClass: "router-item",
                    on: {
                      click: function($event) {
                        return _vm.handleRoute("/report-download-cool", 4)
                      }
                    }
                  },
                  [
                    _vm.routerIndex == 4
                      ? _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon05.svg"),
                            alt: ""
                          }
                        })
                      : _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon5.svg"),
                            alt: ""
                          }
                        }),
                    _vm._v(" "),
                    _c("div", { class: _vm.routerIndex == 4 ? "active" : "" }, [
                      _vm._v("报表")
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "router-item",
                on: {
                  click: function($event) {
                    return _vm.handleRoute("/operation-cool", 6)
                  }
                }
              },
              [
                _vm.routerIndex == 6
                  ? _c("img", {
                      staticClass: "img-box",
                      attrs: {
                        src: require("../../assets/svg/icon_yw_active.svg"),
                        alt: ""
                      }
                    })
                  : _c("img", {
                      staticClass: "img-box",
                      attrs: {
                        src: require("../../assets/svg/icon_yw.svg"),
                        alt: ""
                      }
                    }),
                _vm._v(" "),
                _c("div", { class: { active: _vm.routerIndex == 6 } }, [
                  _vm._v("运维管理")
                ])
              ]
            ),
            _vm._v(" "),
            _vm.pathData.indexOf("maintain-cool") !== -1 &&
            _vm.userInfo.backStage === 0
              ? _c(
                  "div",
                  {
                    staticClass: "router-item",
                    on: { click: _vm.goToMananger }
                  },
                  [
                    _c("img", {
                      staticClass: "img-box",
                      attrs: {
                        src: require("../../assets/svg/icon6.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v("维护")])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pathData.indexOf("feedback-cool") !== -1
              ? _c(
                  "div",
                  {
                    staticClass: "router-item",
                    on: {
                      click: function($event) {
                        return _vm.handleRoute("/home-page-cool", 5)
                      }
                    }
                  },
                  [
                    _vm.routerIndex == 5
                      ? _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon08.svg"),
                            alt: ""
                          }
                        })
                      : _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon8.svg"),
                            alt: ""
                          }
                        }),
                    _vm._v(" "),
                    _c("div", { class: _vm.routerIndex == 5 ? "active" : "" }, [
                      _vm._v("反馈")
                    ])
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "router-item",
                on: { click: _vm.handleFullScreen }
              },
              [
                _c("img", {
                  staticClass: "img-box",
                  attrs: { src: require("../../assets/svg/icon9.svg"), alt: "" }
                }),
                _vm._v(" "),
                _c("div", [_vm._v("全屏")])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "router-item under",
                on: {
                  click: function($event) {
                    return _vm.handleRoute("/home", 0)
                  }
                }
              },
              [
                _c("img", {
                  staticClass: "img-box",
                  attrs: {
                    src: require("../../assets/svg/icon10.svg"),
                    alt: ""
                  }
                }),
                _vm._v(" "),
                _c("div", [_vm._v("返回")])
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
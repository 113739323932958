var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-layout" },
    [
      _c(
        "div",
        { staticClass: "header-router", staticStyle: { height: "100%" } },
        [
          _c(
            "div",
            {
              staticClass: "logo",
              on: {
                click: function($event) {
                  return _vm.enterScreen()
                }
              }
            },
            [
              _vm.logoImg
                ? _c("img", {
                    staticStyle: { width: "42px", height: "42px" },
                    attrs: { src: _vm.logoImg, alt: "" }
                  })
                : _c("img", {
                    staticStyle: { width: "42px", height: "42px" },
                    attrs: {
                      src: require("../../assets/svg/logo.svg"),
                      alt: ""
                    }
                  })
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "bottom", staticStyle: { height: "100%" } },
            [
              _c("div", { staticStyle: { flex: "1", width: "100%" } }, [
                _vm.pathData.indexOf("home-page") !== -1
                  ? _c(
                      "div",
                      {
                        staticClass: "router-item",
                        class: _vm.routerIndex == 0 ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.handleRoute("/home-page", 0)
                          }
                        }
                      },
                      [
                        _vm.routerIndex == 0
                          ? _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon01.svg"),
                                alt: ""
                              }
                            })
                          : _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon1.svg"),
                                alt: ""
                              }
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.routerIndex == 0 ? "active" : "" },
                          [_vm._v("主页")]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pathData.indexOf("system-details") !== -1
                  ? _c(
                      "div",
                      {
                        staticClass: "router-item",
                        class: _vm.routerIndex == 1 ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.handleRoute("/system-details", 1)
                          }
                        }
                      },
                      [
                        _vm.routerIndex == 1
                          ? _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon02.svg"),
                                alt: ""
                              }
                            })
                          : _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon2.svg"),
                                alt: ""
                              }
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.routerIndex == 1 ? "active" : "" },
                          [_vm._v("详情")]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pathData.indexOf("data-query") !== -1
                  ? _c(
                      "div",
                      {
                        staticClass: "router-item",
                        class: _vm.routerIndex == 3 ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.handleRoute("/data-query", 3)
                          }
                        }
                      },
                      [
                        _vm.routerIndex == 3
                          ? _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon03.svg"),
                                alt: ""
                              }
                            })
                          : _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon3.svg"),
                                alt: ""
                              }
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.routerIndex == 3 ? "active" : "" },
                          [_vm._v("数据")]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pathData.indexOf("report-download") !== -1
                  ? _c(
                      "div",
                      {
                        staticClass: "router-item",
                        class: _vm.routerIndex == 4 ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.handleRoute("/report-download", 4)
                          }
                        }
                      },
                      [
                        _vm.routerIndex == 4
                          ? _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon05.svg"),
                                alt: ""
                              }
                            })
                          : _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon5.svg"),
                                alt: ""
                              }
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.routerIndex == 4 ? "active" : "" },
                          [_vm._v("报表")]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "router-item",
                    class: _vm.routerIndex == 6 ? "active" : "",
                    on: {
                      click: function($event) {
                        return _vm.handleRoute("/operation", 6)
                      }
                    }
                  },
                  [
                    _vm.routerIndex == 6
                      ? _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon_yw_active.svg"),
                            alt: ""
                          }
                        })
                      : _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon_yw.svg"),
                            alt: ""
                          }
                        }),
                    _vm._v(" "),
                    _c("div", { class: { active: _vm.routerIndex == 6 } }, [
                      _vm._v("运维管理")
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm.pathData.indexOf("maintain") !== -1 &&
                _vm.userInfo.backStage === 0
                  ? _c(
                      "div",
                      {
                        staticClass: "router-item",
                        on: { click: _vm.goToMananger }
                      },
                      [
                        _c("img", {
                          staticClass: "img-box",
                          attrs: {
                            src: require("../../assets/svg/icon6.svg"),
                            alt: ""
                          }
                        }),
                        _vm._v(" "),
                        _c("div", [_vm._v("维护")])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pathData.indexOf("home-skin") !== -1
                  ? _c(
                      "div",
                      {
                        staticClass: "router-item",
                        class: _vm.routerIndex == 7 ? "active" : "",
                        attrs: { type: "primary" },
                        on: { click: _vm.setSkinPeeler }
                      },
                      [
                        _vm.routerIndex == 7
                          ? _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon07.svg"),
                                alt: ""
                              }
                            })
                          : _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon7.svg"),
                                alt: ""
                              }
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.routerIndex == 7 ? "active" : "" },
                          [_vm._v("皮肤")]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.pathData.indexOf("feed-back") !== -1
                  ? _c(
                      "div",
                      {
                        staticClass: "router-item",
                        class: _vm.routerIndex == 5 ? "active" : "",
                        on: {
                          click: function($event) {
                            return _vm.handleRoute("/home-page", 5)
                          }
                        }
                      },
                      [
                        _vm.routerIndex == 5
                          ? _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon08.svg"),
                                alt: ""
                              }
                            })
                          : _c("img", {
                              staticClass: "img-box",
                              attrs: {
                                src: require("../../assets/svg/icon8.svg"),
                                alt: ""
                              }
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { class: _vm.routerIndex == 5 ? "active" : "" },
                          [_vm._v("反馈")]
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "router-item",
                    on: { click: _vm.handleFullScreen }
                  },
                  [
                    _c("img", {
                      staticClass: "img-box",
                      attrs: {
                        src: require("../../assets/svg/icon9.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v("全屏")])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "router-item",
                    on: {
                      click: function($event) {
                        return _vm.handleRoute("/home", 0)
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img-box",
                      attrs: {
                        src: require("../../assets/svg/icon10.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v("返回")])
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "换肤",
            visible: _vm.drawer,
            direction: _vm.direction,
            modal: false
          },
          on: {
            "update:visible": function($event) {
              _vm.drawer = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "right-box",
              staticStyle: { "margin-top": "10px", width: "550px" }
            },
            [
              _c(
                "el-row",
                { staticClass: "el-row", attrs: { gutter: 12, type: "flex" } },
                _vm._l(this.skinList, function(o, index) {
                  return _c(
                    "el-col",
                    { key: index, staticClass: "el-col", attrs: { span: 8 } },
                    [
                      _c("el-card", { key: index, staticClass: "el-card" }, [
                        _c(
                          "div",
                          {
                            staticClass: "clearfix",
                            attrs: { slot: "header" },
                            slot: "header"
                          },
                          [_c("span", [_vm._v(_vm._s(o.name))])]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "skin",
                          staticStyle: { cursor: "pointer" },
                          style: { background: o.color },
                          on: {
                            click: function($event) {
                              return _vm.updateSkinData(o.skin)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "bottom clearfix",
                              staticStyle: {
                                "text-align": "center",
                                "margin-top": "20px"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "button hvr-grow",
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function($event) {
                                      return _vm.updateSkinData(o.skin)
                                    }
                                  }
                                },
                                [_vm._v("应用")]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
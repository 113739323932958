import { render, staticRenderFns } from "./tabs.vue?vue&type=template&id=37f3c4ac&scoped=true&"
import script from "./tabs.vue?vue&type=script&lang=js&"
export * from "./tabs.vue?vue&type=script&lang=js&"
import style0 from "./tabs.vue?vue&type=style&index=0&id=37f3c4ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f3c4ac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\yl-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37f3c4ac')) {
      api.createRecord('37f3c4ac', component.options)
    } else {
      api.reload('37f3c4ac', component.options)
    }
    module.hot.accept("./tabs.vue?vue&type=template&id=37f3c4ac&scoped=true&", function () {
      api.rerender('37f3c4ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/bigScreen/components/tabs.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { width: "100%", "background-color": "#FBFBFD" },
      attrs: { id: "app" }
    },
    [_c(_vm.layout, { tag: "component" }, [_c("router-view")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
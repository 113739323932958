"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _lineChart = _interopRequireDefault(require("./components-cool/lineChart"));

var _calendar = _interopRequireDefault(require("./components-cool/calendar"));

var _dataAnalysis = require("@/api/dataAnalysis");

var _dayjs = _interopRequireDefault(require("dayjs"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import headr from "@/components/head";
var _default = {
  components: {
    // headr,
    lineChart: _lineChart.default,
    calendar: _calendar.default
  },
  data: function data() {
    return {
      activeName: 'first',
      isShow: true,
      monthData: "",
      dayData: (0, _dayjs.default)().format("YYYY-MM-DD"),
      calendar: (0, _dayjs.default)().format("YYYY-MM"),
      chartData: [],
      consumptionCalendarData: [],
      pathData: [],
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      backupData: [{
        name: "关键参数",
        chartData: []
      }, {
        name: "冷冻水输送系数",
        chartData: []
      }, {
        name: "冷却水输送系数",
        chartData: []
      }, {
        name: "冷却塔系统能效",
        chartData: []
      }],
      nameTips: [{
        name: "关键参数",
        showTip: true,
        tip1: "影响系统运行的主要关键参数",
        //瞬时的提示
        tip2: "影响系统运行的主要关键参数" //日数据的提示

      }, {
        name: "冷冻水输送系数",
        showTip: true,
        tip1: "机房总供冷负荷/机房冷冻水泵总功率，冷冻水供回水温差越大，冷冻水输送系数越高，冷冻水系统效率越高。",
        tip2: "当日机房总供冷量/机房冷冻水泵总耗电量，冷冻水供回水平均温差越大，冷冻水输送系数越高，冷冻水系统效率越高。"
      }, {
        name: "冷却水输送系数",
        showTip: true,
        tip1: "机房总散热负荷/机房冷却水泵总功率，冷却水系统供回水温差越大，冷却水输送系数越高，冷却水系统效率越高。",
        tip2: "当日机房总散热量/机房冷却水泵总耗电量，冷却水系统供回水平均温差越大，冷却水输送系数越高，冷却水系统效率越高。"
      }, {
        name: "冷却塔系统能效",
        showTip: true,
        tip1: "机房总散热负荷/机房冷却塔总功率，冷却塔系统能效越高，冷却塔系统效率越高。",
        tip2: "当日机房总散热量/机房冷却塔总耗电量，冷却塔系统能效越高，冷却塔系统效率越高。"
      }],
      k: 0
    };
  },
  created: function created() {
    var arr = JSON.parse(window.sessionStorage.getItem("menuAuthsice")) || [];
    this.pathData = arr.map(function (e) {
      return e.path;
    });

    if (this.pathData.indexOf("home-page-cool") !== 0) {
      // this.$message({
      //   type: 'info',
      //   message: '无权限查看'
      // })
      this.$router.push({
        path: "/403"
      });
      return;
    }

    this.monthData = (0, _dayjs.default)().format("YYYY-MM-DD");
    this.getDayHistoryAnalysisData();
    this.getEnergyConsumptionCalendarData();
  },
  methods: {
    tagCheck: function tagCheck(e) {
      if (e === 0) {
        this.activeName = 'first';
      }

      if (e === 1) {
        this.activeName = 'second';
      }

      if (e === 2) {
        this.activeName = 'third';
      }

      if (e === 3) {
        this.activeName = 'fourth';
      }
    },
    //图表切换
    handleClick: function handleClick(tab, event) {
      this.k = tab.index;
      this.$refs.carousel.setActiveItem(tab.index); //  
      // if(e===0){
      //   this.activeName='first';
      // }
      // if(e===1){
      //   this.activeName='second';
      // }
      // if(e===2){
      //   this.activeName='third';
      // }
      // if(e===3){
      //   this.activeName='fourth';
      // }
    },
    handleShow: function handleShow(val) {
      this.isShow = val;

      if (val) {
        //瞬时
        this.dayData = (0, _dayjs.default)().format("YYYY-MM-DD");
        this.getDayHistoryAnalysisData();
      } else {
        this.monthData = (0, _dayjs.default)().format("YYYY-MM");
        this.getMonthHistoryAnalysisData();
      }
    },
    handleDay: function handleDay(val) {
      this.getDayHistoryAnalysisData();
    },
    handleMonth: function handleMonth(val) {
      this.getMonthHistoryAnalysisData();
    },
    handelCalendar: function handelCalendar() {
      this.getEnergyConsumptionCalendarData();
    },
    getDayHistoryAnalysisData: function getDayHistoryAnalysisData() {
      var _this = this;

      this.chartData = [];
      var params = {
        // timeDate: this.dayData,
        dateTime: this.dayData,
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataAnalysis.getAnalysisDataByDay)(params).then(function (res) {
        console.log(res);
        var data = Object.assign([], (0, _toConsumableArray2.default)(res));

        if (JSON.stringify(res.data) === "{}") {
          _this.chartData = [{
            name: "供冷分布",
            chartData: []
          }, {
            name: "系统每小时耗电量",
            chartData: []
          }, {
            name: "蓄冷槽冷量",
            chartData: []
          }, {
            name: "关键参数",
            chartData: []
          }];
        } else {
          _this.chartData = [];
          var name = "";
          data.map(function (e, i) {
            if (i === 0) {
              name = "供冷分布";
            } else if (i === 1) {
              name = "系统每小时耗电量";
            } else if (i === 2) {
              name = "蓄冷槽冷量";
            } else {
              name = "关键参数";
            }

            var arr = [];

            for (var _i in e) {
              var tempArr = [];

              for (var ii in e[_i]) {
                for (var k in e[_i][ii]) {
                  if (!e[_i][ii][k] && !(e[_i][ii][k] == 0)) {
                    e[_i][ii][k] = "";
                  }
                }

                tempArr.push([ii].concat((0, _toConsumableArray2.default)(e[_i][ii])));
              }

              arr.push({
                fieldMeaning: _i,
                data: tempArr
              });
            }

            _this.chartData.push({
              name: name,
              chartData: arr
            });
          });
          console.log("this.chartData------------", _this.chartData); // for (const key in res.data) {
          //   this.chartData.push({
          //     name: key,
          //     chartData: res.data[key]
          //   })
          // }
        }
      });
    },
    getMonthHistoryAnalysisData: function getMonthHistoryAnalysisData() {
      var _this2 = this;

      var params = {
        // timeDate: this.monthData,
        dateTime: this.monthData,
        projectId: this.$store.state.project.projectInfo.id
      };
      this.chartData = [];
      (0, _dataAnalysis.getAnalysisDataByMonth)(params).then(function (res) {
        _this2.chartData = [];

        if (JSON.stringify(res.data) === "{}") {
          _this2.chartData = [{
            name: "供冷分布",
            chartData: []
          }, {
            name: "系统每小时耗电量",
            chartData: []
          }, {
            name: "蓄冷槽冷量",
            chartData: []
          }, {
            name: "关键参数",
            chartData: []
          }];
        } else {
          var name = "";
          res.map(function (e, i) {
            if (i === 0) {
              name = "供冷分布";
            } else if (i === 1) {
              name = "系统每小时耗电量";
            } else if (i === 2) {
              name = "蓄冷槽冷量";
            } else {
              name = "关键参数";
            }

            var arr = [];

            for (var _i2 in e) {
              var tempArr = [];

              for (var ii in e[_i2]) {
                tempArr.push([ii].concat((0, _toConsumableArray2.default)(e[_i2][ii])));
              }

              arr.push({
                fieldMeaning: _i2,
                data: tempArr
              });
            }

            _this2.chartData.push({
              name: name,
              chartData: arr
            });
          });
          console.log("this.chartData--------", _this2.chartData);
        }
      });
    },
    getEnergyConsumptionCalendarData: function getEnergyConsumptionCalendarData() {
      var _this3 = this;

      var params = {
        timeDate: this.calendar,
        projectId: this.$store.state.project.projectInfo.id,
        projectType: this.$store.state.project.projectInfo.projectType
      };
      (0, _dataAnalysis.getColdConsumptionCalendar)(params).then(function (res) {
        _this3.consumptionCalendarData = res;
      });
    }
  }
};
exports.default = _default;
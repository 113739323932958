"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    pieData: {
      type: Array,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      timeType: 0,
      dayData: null,
      monthData: null,
      yearData: null,
      pieList: [],
      colorType: '',
      echartfc: '',
      // 图表颜色
      subEchartfc: '',
      // 图表颜色
      zoom: '',
      zoomrAtio: '',
      energyTotal: 0
    };
  },
  watch: {
    timeType: function timeType(val) {
      if (val === 0) {
        this.pieList = this.dayData;
      } else if (val === 1) {
        this.pieList = this.monthData;
      } else {
        this.pieList = this.yearData;
      }

      this.getPieEcharts();
    },
    pieData: function pieData(val) {
      var _this2 = this;

      this.pieList = [];
      val.map(function (e, index) {
        for (var key in val[index]) {
          if (key === "年") {
            _this2.yearData = _this2.changeData(val[index][key]);
          } else if (key === "月") {
            _this2.monthData = _this2.changeData(val[index][key]);
          } else {
            _this2.dayData = _this2.changeData(val[index][key]);
          }
        }
      });
      this.pieList = this.timeType == 0 ? this.dayData : this.timeType == 1 ? this.monthData : this.yearData;
      this.getPieEcharts();
    },
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;

      if (this.colorType == 'theme_jyh') {
        this.echartfc = '#fff';
        this.subEchartfc = '#e3e3e3';
      } else if (this.colorType == 'theme_ytl') {
        this.echartfc = '#fff';
        this.subEchartfc = '#e3e3e3';
      } else {
        this.echartfc = '#fff';
        this.subEchartfc = '#e3e3e3';
      }

      this.getPieEcharts();
    }
  },
  created: function created() {
    this.colorType = this.$store.state.project.colorType;

    if (this.colorType == 'theme_jyh') {
      this.echartfc = '#fff';
      this.subEchartfc = '#e3e3e3';
    } else if (this.colorType == 'theme_ytl') {
      this.echartfc = '#fff';
      this.subEchartfc = '#e3e3e3';
    } else {
      this.echartfc = '#fff';
      this.subEchartfc = '#e3e3e3';
    }
  },
  mounted: function mounted() {
    // 窗口发生变化从新执行echarts()方法
    var ts = this;
    var resizeOb = new ResizeObserver(function (entries) {
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = entries[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var entry = _step.value;
          ts.$echarts.getInstanceByDom(entry.target).resize();
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    });
    resizeOb.observe(document.getElementById('pieCharts'));
  },
  methods: {
    changeData: function changeData(data) {
      var arr = [];

      for (var key in data) {
        for (var i in data[key]) {
          var obj = {
            name: i,
            value: data[key][i][0],
            unit: data[key][i][1]
          };
          arr.push(obj);
        }
      }

      return arr;
    },
    compare: function compare(property, desc) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];

        if (desc === true) {
          // 升序排列
          return value1 - value2;
        } else {
          // 降序排列
          return value2 - value1;
        }
      };
    },
    getPieEcharts: function getPieEcharts() {
      var _this3 = this;

      var count = 0;

      if (this.timeType == 0) {
        for (var k in this.dayData) {
          count += Number(this.dayData[k].value);
        }
      } else if (this.timeType == 1) {
        for (var _k in this.monthData) {
          count += Number(this.monthData[_k].value);
        }
      } else {
        for (var _k2 in this.yearData) {
          count += Number(this.yearData[_k2].value);
        }
      }

      this.energyTotal = count.toFixed(2);

      var _this = this;

      var pieData = _this.pieList.sort(_this.compare("value", false));

      var pieEcharts = this.$echarts.init(document.getElementById("pieCharts"));
      var option = {
        tooltip: {
          trigger: "item",
          borderWidth: 0,
          formatter: function formatter(param) {
            return "".concat(param.marker).concat(param.name, ": ").concat(param.value.toFixed(2), "kwh, \u5360\u6BD4: ").concat(param.percent, "%");
          },
          backgroundColor: "rgba(255,255,255,1)",
          extraCssText: "box-shadow:0 0 10px rgba(0,120,234,0.1);color:#333333;font-size:16px",
          textStyle: {
            fontSize: 24,
            lineHeight: 50
          },
          padding: [8, 15]
        },
        color: ["#5570c6", "#91cc76", "#fac858", "#ed6666", "#73c0de", "#3ba272", "#fc8352", "#9a61b4", "#ecc49f"],
        legend: {
          type: "plain",
          orient: "vertical",
          icon: "circle",
          show: true,
          y: 'center',
          right: "8%",
          align: 'left',
          itemHeight: 10,
          // itemGap: 4,
          itemGap: 2,
          formatter: function formatter(name) {
            var total = 0;
            var target;

            for (var i = 0, l = _this.pieList.length; i < l; i++) {
              total += _this.pieList[i].value;

              if (_this.pieList[i].name === name) {
                target = _this.pieList[i].value;
              }
            }

            var arr = [];

            if (target === 0) {
              arr = ["{a|" + 0 + "%}", "{b|" + name + "\n}"];
            } else {
              arr = ["{a|" + (target / total * 100).toFixed(2) + "%}", "{b|" + name + "\n}"];
            }

            return arr.join("\n");
          },
          textStyle: {
            color: this.echartfc,
            align: "center",
            rich: {
              a: {
                fontSize: 16,
                lineHeight: 26,
                fontWeight: 400
              },
              b: {
                fontSize: 12,
                color: this.subEchartfc
              }
            }
          }
        },
        title: {
          text: this.energyTotal,
          subtext: "kWh",
          top: "42%",
          left: "34%",
          textAlign: "center",
          textStyle: {
            color: '#fff'
          },
          subtextStyle: {
            color: '#e3e3e3'
          }
        },
        series: [{
          type: "pie",
          radius: ["50%", "70%"],
          center: ["35%", "50%"],
          data: pieData,
          // 最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
          label: {
            show: false,
            position: "inside",
            formatter: "{b}:{d}%"
          },
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(9, 45, 97, 0.2)",
            shadowOffsetX: 0,
            shadowOffsetY: 0
          }
        }]
      };
      pieEcharts.setOption(option);
      this.$nextTick(function () {
        _this3.zoom = document.body.style.zoom;
        _this3.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    }
  }
};
exports.default = _default2;
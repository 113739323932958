"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _auth = require("@/utils/auth");

/*
 * @Author: zlt
 * @Date: 2021-11-23 15:51:24
 * @LastEditTime: 2021-12-07 17:14:19
 * @LastEditors: zlt
 * @Description: 文件描述
 * @FilePath: \yilong\src\store\modules\user.js
 */
var state = {
  token: (0, _auth.getToken)(),
  projectOrderPermissions: []
};
var getters = {
  GET_ProjectOrderPermissions: function GET_ProjectOrderPermissions(state) {
    var status = false;
    state.projectOrderPermissions.forEach(function (item) {
      if (item.workOrderIdent == 1) status = true;
    });
    return status;
  }
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_ProjectOrderPermissions: function SET_ProjectOrderPermissions(state, projectOrderPermissions) {
    state.projectOrderPermissions = projectOrderPermissions;
  }
};
var actions = {
  // remove token
  resetToken: function resetToken(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      commit("SET_TOKEN", "");
      (0, _auth.removeToken)();
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
exports.default = _default;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "tabs" },
      _vm._l(_vm.tablist, function(item, index) {
        return _c(
          "div",
          {
            staticClass: "parallelogram",
            class: _vm.currentIndex == index ? "isActive" : "",
            on: {
              key: index,
              click: function($event) {
                return _vm.go(index)
              }
            }
          },
          [_c("span", [_vm._v(_vm._s(item.name))])]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProject = getProject;
exports.getProjectWorkOrderListBySubmitterId = getProjectWorkOrderListBySubmitterId;
exports.getProjectWorkOrderListByReviewerId = getProjectWorkOrderListByReviewerId;
exports.getProjectWorkOrderSendUsersBySendUserId = getProjectWorkOrderSendUsersBySendUserId;
exports.getprojectInfoByWorkOrderIdentList = getprojectInfoByWorkOrderIdentList;
exports.getuserInfoByProjectId = getuserInfoByProjectId;
exports.postUploadImage = postUploadImage;
exports.postInsertProjectWordOrder = postInsertProjectWordOrder;
exports.postOrderEdit = postOrderEdit;
exports.postOrderDetailEdit = postOrderDetailEdit;
exports.postOrderAdd = postOrderAdd;
exports.postOrderClose = postOrderClose;
exports.postUnOrderReceivingReassignment = postUnOrderReceivingReassignment;
exports.postReceivedOrderHandler = postReceivedOrderHandler;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * @description: 根据工单id工单处理信息
 * @param {type}
 * @return {type}
 */
// 获取项目列表
function getProject() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectInfoList",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}
/**
 * @description: 用户作为提交人id查询工单信息
 * @param {type}
 * @return {type}
 */


function getProjectWorkOrderListBySubmitterId(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderListBySubmitterId",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}
/**
 * @description: 用户作为处理人查询工单信息
 * @param {type}
 * @return {type}
 */


function getProjectWorkOrderListByReviewerId(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderListByReviewerId",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}
/**
 * @description: 用户作为抄送人查询工单信息
 * @param {type}
 * @return {type}
 */


function getProjectWorkOrderSendUsersBySendUserId(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderSendUsersBySendUserId",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 获取用户关联的有工单的项目信息


function getprojectInfoByWorkOrderIdentList() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectInfoByWorkOrderIdentList",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
} // 获取关联项目的用户id


function getuserInfoByProjectId(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectUser/userInfoByProjectId",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function postUploadImage(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/uploadFile",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
} // 创建工单


function postInsertProjectWordOrder(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/insertProjectWordOrder",
    method: 'post',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 修改工单


function postOrderEdit(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/updateProjectWordOrder",
    method: 'post',
    params: _objectSpread({}, data, {
      encryptType: encryptType,
      dataType: 'form'
    })
  });
} // 修改工单处理


function postOrderDetailEdit(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderHandlerUpdate",
    method: 'post',
    params: _objectSpread({}, data, {
      encryptType: encryptType,
      dataType: 'form'
    })
  });
} // 接单


function postOrderAdd(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/orderReceiving",
    method: 'post',
    params: _objectSpread({}, data, {
      encryptType: encryptType
    })
  });
} // 关闭工单


function postOrderClose(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/closeWorkOrder",
    method: 'post',
    params: _objectSpread({}, data, {
      encryptType: encryptType
    })
  });
} // 不接单直接转派下一个人


function postUnOrderReceivingReassignment(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/unOrderReceivingReassignment",
    method: 'post',
    params: _objectSpread({}, data, {
      encryptType: encryptType
    })
  });
} // 接单后处理 处理完成关闭工单/处理完成转派


function postReceivedOrderHandler(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/receivedOrderHandler",
    method: 'post',
    params: _objectSpread({}, data, {
      encryptType: encryptType,
      dataType: 'form'
    })
  });
}
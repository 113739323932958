"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProject = getProject;
exports.projectInfoCompanyList = projectInfoCompanyList;
exports.getProjectList = getProjectList;
exports.getProjectCoolList = getProjectCoolList;
exports.getEfficiencyRise = getEfficiencyRise;
exports.getColdPrice = getColdPrice;
exports.getCop = getCop;
exports.getProjectHyperLink = getProjectHyperLink;
exports.getProjectHeader = getProjectHeader;
exports.getLogoImg = getLogoImg;
exports.getProjectEueep = getProjectEueep;
exports.getSystemNameByUse = getSystemNameByUse;
exports.getSkin = getSkin;
exports.updateSkin = updateSkin;
exports.getEnergyConsumption = getEnergyConsumption;
exports.getCoemColdPrice = getCoemColdPrice;
exports.getEnergy = getEnergy;
exports.getUntreatedProjectAlarm = getUntreatedProjectAlarm;
exports.batchUpdateProjectAlarmState = batchUpdateProjectAlarmState;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// 获取项目列表
function getProject() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectInfoList",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}

function projectInfoCompanyList() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectInfoCompanyList",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
} // 常规项目信息


function getProjectList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHomePageData/projectHomePageDataList",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 蓄冷项目信息


function getProjectCoolList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHomePageData/homePageDataRealCoolStorage",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 获取能效抬头


function getEfficiencyRise(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/efficiencyRise/efficiencyRiseList",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getColdPrice(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHomePageData/homePageDataHistoryColdPrice",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getCop(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHomePageData/homePageDataHistoryCop",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getProjectHyperLink(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var hyperLinkAddressType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return (0, _request.default)({
    url: "/projectHyperLink/projectHyperLinkByProjectId",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType,
      hyperLinkAddressType: hyperLinkAddressType
    })
  });
}

function getProjectHeader(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHeader/projectHeaderInfo",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getLogoImg(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectLogoImage/projectLogoImageByProjectId",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getProjectEueep(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectEfficiency/projectEfficiencyByProjectId",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getSystemNameByUse() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/systemName/systemNameByUser",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}

function getSkin(id) {
  return (0, _request.default)({
    url: "/users/getSkin",
    method: 'get',
    params: {
      id: id
    }
  });
}

function updateSkin(id, skin) {
  return (0, _request.default)({
    url: "/users/updateSkin",
    method: 'post',
    params: {
      id: id,
      skin: skin
    }
  });
}

function getEnergyConsumption(ids) {
  return (0, _request.default)({
    url: "/coem/getEnergyConsumption",
    method: 'get',
    params: {
      ids: ids
    }
  });
}

function getCoemColdPrice(ids) {
  return (0, _request.default)({
    url: "/coem/getColdPrice",
    method: 'get',
    params: {
      ids: ids
    }
  });
}

function getEnergy(params) {
  return (0, _request.default)({
    url: "/coem/getEnergy",
    method: 'post',
    params: _objectSpread({}, params)
  });
} //告警通知查询


function getUntreatedProjectAlarm(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectAlarm/getUntreatedProjectAlarm",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //批量修改状态（只传当前数据id集合


function batchUpdateProjectAlarmState(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectAlarm/batchUpdateProjectAlarmState",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}
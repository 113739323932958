"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dataQuery = require("@/api/dataQuery");

var _dayjs = _interopRequireDefault(require("dayjs"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      isShow: 1,
      dayData: (0, _dayjs.default)().format("YYYY-MM-DD"),
      loadingOne: false,
      monthData: "",
      yearData: "",
      loading: false,
      userInfo: {},
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      // 表头数据
      tableHead: [],
      tableData: [],
      variableHeight: null
    };
  },
  mounted: function mounted() {
    this.getHeight();
  },
  watch: {
    isShow: function isShow(val) {
      if (val === 1) {
        this.dayData = (0, _dayjs.default)().format("YYYY-MM-DD");
      } else if (val === 2) {
        this.monthData = (0, _dayjs.default)().format("YYYY-MM");
      } else {
        this.yearData = (0, _dayjs.default)().format("YYYY");
      }

      this.getEnergyConsumptionSummaryData();
    }
  },
  created: function created() {
    this.getEnergyConsumptionSummaryData();

    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
  },
  methods: {
    getHeight: function getHeight() {
      var height = window.screen.height * window.devicePixelRatio;

      if (height >= 2160) {
        this.variableHeight = 850;
      } else if (height >= 1800) {
        this.variableHeight = 865;
      } else if (height >= 1600) {
        this.variableHeight = 945;
      } else if (height >= 1440) {
        this.variableHeight = 835;
      } else if (height >= 1000) {
        this.variableHeight = 730;
      } else if (height >= 800) {
        this.variableHeight = 960;
      } else if (height >= 700) {
        this.variableHeight = 690;
      } else {
        this.variableHeight = 650;
      }
    },
    handleTime: function handleTime() {
      this.getEnergyConsumptionSummaryData();
    },
    columnStyle: function columnStyle(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (columnIndex === 0) {
        // 第三第四列的背景色就改变了2和3都是列数的下标
        return "background:rgb(22 101 216 / 5%)";
      }
    },
    handleDown: function handleDown() {
      this.getEnergyConsumptionSummaryExportDown();
    },
    handleQuery: function handleQuery() {// this.getEnergyConsumptionSummaryData()
    },
    getEnergyConsumptionSummaryExportDown: function getEnergyConsumptionSummaryExportDown() {
      var _this = this;

      this.loading = true;
      setTimeout(function () {
        _this.loading = false;
      }, 2000);
      var timeStr = "";

      if (this.isShow === 1) {
        timeStr = this.dayData;
      } else if (this.isShow === 2) {
        timeStr = this.monthData;
      } else {
        timeStr = this.yearData;
      }

      var params = {
        timeType: this.isShow,
        projectId: this.$store.state.project.projectInfo.id,
        timeStr: timeStr
      };
      (0, _dataQuery.getEnergyConsumptionSummaryExport)(params).then(function (res) {
        var link = document.createElement("a");
        link.href = res;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    getEnergyConsumptionSummaryData: function getEnergyConsumptionSummaryData() {
      var _this2 = this;

      var timeStr = "";

      if (this.isShow === 1) {
        timeStr = this.dayData;
      } else if (this.isShow === 2) {
        timeStr = this.monthData;
      } else {
        timeStr = this.yearData;
      }

      this.loadingOne = true;
      var params = {
        timeType: this.isShow,
        projectId: this.$store.state.project.projectInfo.id,
        timeStr: timeStr
      };
      this.tableHead = [];
      this.tableData = []; //

      (0, _dataQuery.getEnergyConsumptionSummary)(params).then(function (res) {
        if (res.headerList.length !== 0) {
          res.headerList[0].map(function (e, i) {
            var obj = {};
            obj["name".concat(i)] = "name".concat(i);
            obj["title"] = e;

            _this2.tableHead.push(obj);
          });
        }

        if (res.aggregateDataList.length !== 0) {
          var _loop = function _loop(i) {
            var obj = {};
            res.aggregateDataList[i].map(function (e, i) {
              obj["name".concat(i)] = e;
            });

            _this2.tableData.push(obj);
          };

          for (var i = 0; i < res.aggregateDataList.length; i++) {
            _loop(i);
          }
        }

        _this2.loadingOne = false;
      });
    }
  }
};
exports.default = _default;
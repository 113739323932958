"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetRouter = resetRouter;
exports.default = exports.constantRoutes = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("@babel/runtime/helpers/interopRequireWildcard"));

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

/*
 * @Author: your name
 * @Date: 2020-11-24 15:52:23
 * @LastEditTime: 2022-02-10 14:07:17
 * @LastEditors: zlt
 * @Description: In User Settings Edit
 * @FilePath: \yilong\src\router\index.js
 */
_vue.default.use(_vueRouter.default);
/** Layout */


var constantRoutes = [{
  path: "/",
  component: _layout.default,
  redirect: "home",
  children: [{
    path: "/home-page",
    name: "HomePage",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/home-page/index"));
      });
    },
    meta: {
      layout: "default",
      title: "HomePage",
      icon: "form"
    }
  }, {
    path: "/home-company",
    name: "HomeCompany",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/home/index-company"));
      });
    },
    meta: {
      title: "HomeCompany",
      icon: "form"
    }
  }, {
    path: "/home-page-cool",
    name: "HomePageCool",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/home-page/index-cool"));
      });
    },
    meta: {
      layout: "cool",
      title: "HomePageCool",
      icon: "form"
    }
  }, {
    path: "/system-details",
    name: "SystemDetails",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system-details/index"));
      });
    },
    meta: {
      layout: "default",
      title: "SystemDetails",
      icon: "form"
    }
  }, {
    path: "/system-details-cool",
    name: "SystemDetailsCool",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/system-details/index-cool"));
      });
    },
    meta: {
      layout: "cool",
      title: "SystemDetailsCool",
      icon: "form"
    }
  }, {
    path: "/home-skin",
    name: "HomeSkin",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/home/home-skin"));
      });
    },
    meta: {
      layout: "default",
      title: "homeSkin",
      icon: "form"
    }
  }, // {
  //     path: "/data-analyse",
  //     name: "DataAnalyse",
  //     component: () => import("@/views/data-analyse/index"),
  //     meta: {
  //         layout: "default",
  //         title: "DataAnalyse",
  //         icon: "form",
  //     },
  // },
  // {
  //     path: "/data-analyse-cool",
  //     name: "DataAnalyseCool",
  //     component: () => import("@/views/data-analyse/index-cool"),
  //     meta: {
  //         layout: "cool",
  //         title: "DataAnalyseCool",
  //         icon: "form",
  //     },
  // },
  {
    path: "/data-query",
    name: "DataQuery",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/data-query/index"));
      });
    },
    meta: {
      layout: "default",
      title: "DataQuery",
      icon: "form"
    }
  }, {
    path: "/data-query-cool",
    name: "DataQueryCool",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/data-query/index-cool"));
      });
    },
    meta: {
      layout: "cool",
      title: "DataQueryCool",
      icon: "form"
    }
  }, {
    path: "/report-download",
    name: "ReportDownload",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/report-download/index"));
      });
    },
    meta: {
      layout: "default",
      title: "ReportDownload",
      icon: "form"
    }
  }, {
    path: "/report-download-cool",
    name: "ReportDownloadCool",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/report-download/index-cool"));
      });
    },
    meta: {
      layout: "cool",
      title: "ReportDownloadCool",
      icon: "form"
    }
  }, {
    path: "/feedback",
    name: "Feedback",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/feedback/index"));
      });
    },
    meta: {
      layout: "default",
      title: "Feedback",
      icon: "form"
    }
  }, {
    path: "/feedback-cool",
    name: "FeedbackCool",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/feedback/index-cool"));
      });
    },
    meta: {
      layout: "cool",
      title: "FeedbackCool",
      icon: "form"
    }
  }, {
    path: "/operation",
    name: "Operation",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/operation"));
      });
    },
    meta: {
      layout: "default",
      title: "Operation",
      icon: "form"
    }
  }, {
    path: "/operation-cool",
    name: "OperationCool",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/order/operation-cool"));
      });
    },
    meta: {
      layout: "cool",
      title: "OperationCool",
      icon: "form"
    }
  }, {
    path: "/403",
    name: "403",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/user/403"));
      });
    },
    meta: {
      layout: "default",
      title: "403"
    }
  }]
}, {
  path: "/login",
  name: "Login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/user/login"));
    });
  },
  meta: {
    title: "Login"
  }
}, {
  path: "/privacy",
  name: "Privacy",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/privacy"));
    });
  },
  meta: {
    title: "Privacy"
  }
}, {
  path: "/home",
  name: "Home",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/home/index"));
    });
  },
  meta: {
    title: "Home"
  }
}, {
  path: "/bigscreen",
  name: "BigScreen",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/bigScreen/index"));
    });
  },
  meta: {
    title: "BigScreen"
  }
}, {
  path: "*",
  redirect: "/",
  hidden: true
}];
exports.constantRoutes = constantRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter();

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var originalPush = _vueRouter.default.prototype.push;

_vueRouter.default.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};

var _default = router;
exports.default = _default;
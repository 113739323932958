var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-right" }, [
    _c("div", { staticClass: "header" }, [_c("headr")], 1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-box" },
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.activeName,
              callback: function($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName"
            }
          },
          _vm._l(_vm.menusList, function(item) {
            return _c(
              "el-tab-pane",
              { attrs: { label: item.name, name: "tag" + item.id } },
              [
                _vm.activeName == "tag" + item.id && item.name == "日报表"
                  ? _c("day")
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeName == "tag" + item.id && item.name == "月报表"
                  ? _c("month")
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeName == "tag" + item.id && item.name == "年报表"
                  ? _c("year")
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeName == "tag" + item.id && item.name == "设备运行日志"
                  ? _c("runType")
                  : _vm._e(),
                _vm._v(" "),
                _vm.activeName == "tag" + item.id && item.name == "报警"
                  ? _c("alarm")
                  : _vm._e()
              ],
              1
            )
          }),
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOrderInfo = getOrderInfo;
exports.getOrderHandleInfo = getOrderHandleInfo;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * @description: 根据工单id查询工单信息
 * @param {type}
 * @return {type}
 */
function getOrderInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderByWorOrderId",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}
/**
 * @description: 根据工单id查询工单处理信息
 * @param {type}
 * @return {type}
 */


function getOrderHandleInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectWorkOrder/projectWorkOrderHandlerByWorkOrderId",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}
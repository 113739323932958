"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEnergyConsumptionSummary = getEnergyConsumptionSummary;
exports.getEnergyConsumptionSummaryExport = getEnergyConsumptionSummaryExport;
exports.getProjectSystem = getProjectSystem;
exports.getProjectSystemDevice = getProjectSystemDevice;
exports.getProjectFieldInfo = getProjectFieldInfo;
exports.getProjectFieldInfoEnergy = getProjectFieldInfoEnergy;
exports.getDataStatistics = getDataStatistics;
exports.getHistoricalDatExport = getHistoricalDatExport;
exports.getEnergyConsumptionQuery = getEnergyConsumptionQuery;
exports.getEnergyConsumptionQueryExport = getEnergyConsumptionQueryExport;
exports.getDataQuery = getDataQuery;
exports.getDataQueryExport = getDataQueryExport;
exports.getProjectEnergySystem = getProjectEnergySystem;
exports.getProjectEnergySystemDevice = getProjectEnergySystemDevice;
exports.getProjectConsumptionPercentage = getProjectConsumptionPercentage;
exports.getCoolNhfb = getCoolNhfb;
exports.getCoolGlfb = getCoolGlfb;
exports.getCoolNhgl = getCoolNhgl;
exports.getProjectPlan = getProjectPlan;
exports.delProjectPlan = delProjectPlan;
exports.insertOrUpdateProjectPlan = insertOrUpdateProjectPlan;
exports.defaultParams = defaultParams;
exports.energyDefaultParams = energyDefaultParams;
exports.projectSystemAllList = projectSystemAllList;
exports.projectAllFieldInfoByEnergySystem = projectAllFieldInfoByEnergySystem;
exports.projectSystemAllChildrenList = projectSystemAllChildrenList;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function getEnergyConsumptionSummary(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/consumptionSummary/energyConsumptionSummaryReport",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getEnergyConsumptionSummaryExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/consumptionSummary/energyConsumptionSummaryExport",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getProjectSystem(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectSystem/projectSystemList",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getProjectSystemDevice(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectSystemDevice/projectSystemDeviceListByArray",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getProjectFieldInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectFieldInfo/projectFieldInfoBySystemDeviceIds",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getProjectFieldInfoEnergy(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectFieldInfo/projectFieldInfoByEnergyAndSystemDeviceIds",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getDataStatistics(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHistoricalData/historicalDataList",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getHistoricalDatExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectHistoricalData/historicalDatExport",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getEnergyConsumptionQuery(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/energyConsumptionQuery/energyConsumptionQueryList",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getEnergyConsumptionQueryExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/energyConsumptionQuery/energyConsumptionQueryExport",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getDataQuery(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/dataStatistics/dataStatisticsList",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getDataQueryExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/dataStatistics/dataStatisticsExport",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getProjectEnergySystem(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectFieldInfo/projectFieldInfoByEnergySystem",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getProjectEnergySystemDevice(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectFieldInfo/projectFieldInfoBySystemDevice",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 根据项目id，时间类型，时间查询耗能(projectId 项目id,timeType 1:月份，2:年,dayTime 月yyyy-MM 年yyyy）


function getProjectConsumptionPercentage(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectConsumptionPercentage/projectConsumptionPercentage",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 蓄冷月报表、年报表 获取能耗分布


function getCoolNhfb(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolMonthReport/getCoolNhfb",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 蓄冷月报表、年报表 获取供冷分布


function getCoolGlfb(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolMonthReport/getCoolGlfb",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getCoolNhgl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolMonthReport/getCoolNhgl",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getProjectPlan(params) {
  return (0, _request.default)({
    url: "/projectPlan/getProjectPlan",
    method: "get",
    params: _objectSpread({}, params)
  });
}

function delProjectPlan(params) {
  return (0, _request.default)({
    url: "/projectPlan/delProjectPlan",
    method: "get",
    params: _objectSpread({}, params)
  });
}

function insertOrUpdateProjectPlan(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectPlan/insertOrUpdateProjectPlan",
    method: "POST",
    params: {
      encryptType: encryptType
    },
    data: data
  });
} //历史数据报表查询默认参数


function defaultParams(params) {
  return (0, _request.default)({
    url: "/projectHistoricalData/defaultParams",
    method: "get",
    params: _objectSpread({}, params)
  });
} //能耗查询默认参数


function energyDefaultParams(params) {
  return (0, _request.default)({
    url: "/energyConsumptionQuery/defaultParams",
    method: "get",
    params: _objectSpread({}, params)
  });
} //查询所有树状结构 历史数据


function projectSystemAllList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectSystem/projectSystemAllList",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //查询所有树状结构  能耗查询


function projectAllFieldInfoByEnergySystem(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectFieldInfo/projectAllFieldInfoByEnergySystem",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //查询所有树状结构 所有子类数据


function projectSystemAllChildrenList(params) {
  return (0, _request.default)({
    url: "/projectSystem/projectSystemAllChildrenList",
    method: "get",
    params: _objectSpread({}, params)
  });
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _projectInfo = require("@/api/projectInfo");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      projectName: "",
      pathData: [],
      //蓄冷 菜单路由
      pathDataIce: [],
      //常规 菜单路由
      pathDataCon: [],
      projectData: [],
      projectInfo: {},
      projectList: [],
      regionList: [],
      routerIndexList: {
        "home-page": 0,
        "system-details": 1,
        "data-analyse": 2,
        "data-query": 3,
        "report-download": 4,
        "feed-back": 5
      },
      regionProps: {
        value: "id",
        label: "regionName"
      },
      itemImgIndex: -1,
      snowflakeicon: require('./../../assets/snowflakeicon.png'),
      snowflakeiconcount: require('./../../assets/snowflakeiconcount.png')
    };
  },
  created: function created() {
    if (sessionStorage.getItem("projectName")) {
      this.projectName = sessionStorage.getItem("projectName");
    }

    this.getProjectInfoData();
    this.getProjectRegionListData(); // 在home页面中已经存储了，这里直接获取

    var arr = JSON.parse(window.sessionStorage.getItem("menuAuths")) || [];
    this.pathDataCon = arr.map(function (e) {
      return e.path;
    });
    var arrice = JSON.parse(window.sessionStorage.getItem("menuAuthsice")) || [];
    this.pathDataIce = arrice.map(function (e) {
      return e.path;
    });
  },
  // 如果是不同类型的系统切换， 蓄冷/常规切换时重置routerIndex
  computed: {
    projectType: function projectType() {
      return this.$store.state.project.projectInfo.projectType;
    }
  },
  watch: {
    projectType: function projectType(newV, oldV) {
      sessionStorage.setItem("routerIndex", 0);
    }
  },
  methods: {
    // 鼠标经过切换图标
    handelMouseover: function handelMouseover(projectType, index) {
      if (projectType) {
        this.itemImgIndex = index;
      }
    },
    // 鼠标离开切换图标
    handelLeave: function handelLeave(projectType) {
      if (projectType) {
        this.itemImgIndex = -1;
      }
    },
    getProjectInfoData: function getProjectInfoData() {
      var _this = this;

      var params = {
        page: 1,
        rows: 500
      };
      (0, _projectInfo.getProjectInfo)(params).then(function (res) {
        _this.projectData = res.rows;

        _this.projectData.map(function (e) {
          if (_this.$store.state.app) {
            if (e.id === Number(_this.$store.state.app.projectId)) {
              _this.projectInfo = e;
            }
          }
        });
      });
    },
    getProjectRegionListData: function getProjectRegionListData() {
      var _this2 = this;

      (0, _projectInfo.getProjectRegionList)().then(function (res) {
        return [_this2.regionList = res];
      });
    },
    getProjectData: function getProjectData() {
      var _this3 = this;

      getProject().then(function (res) {
        _this3.projectList = res;
      });
    },
    // 获取该项目是否关联了运维管理模块
    jumpHomepage: function jumpHomepage() {
      var status = this.getOrderPermissions(); // 同级别都关联了运维管理模块就正常跳转 如果没有就跳转首页

      if (status) {
        this.$router.go(0);
      } else {
        this.$router.push({
          path: 'home-page'
        });
        sessionStorage.setItem("routerIndex", 0);
      }
    },
    // 获取该项目是否关联了运维管理模块
    getOrderPermissions: function getOrderPermissions() {
      var projectInfo = this.$store.state.project.projectInfo;
      var list = this.$store.state.user.projectOrderPermissions;
      var status = false;
      list.map(function (item) {
        if (item.projectId == projectInfo.id && item.workOrderIdent == 1) {
          status = true;
        }
      });
      return status;
    },
    // 清除历史数据能耗查询的缓存
    clearCache_his_energy: function clearCache_his_energy() {
      // 常规历史 能耗查询
      if (localStorage.getItem('hisAllCheckedIds')) {
        localStorage.removeItem('hisAllCheckedIds');
      }

      if (localStorage.getItem('allTreeChildrenIds')) {
        localStorage.removeItem('allTreeChildrenIds');
      }

      if (localStorage.getItem('energyAllCheckedIds')) {
        localStorage.removeItem('energyAllCheckedIds');
      }

      if (localStorage.getItem('allEnergyTreeChildrenIds')) {
        localStorage.removeItem('allEnergyTreeChildrenIds');
      } // 蓄冷历史 能耗查询


      if (localStorage.getItem('iceEnergyAllCheckedIds')) {
        localStorage.removeItem('iceEnergyAllCheckedIds');
      }

      if (localStorage.getItem('allTreeChildrenIdsIce')) {
        localStorage.removeItem('allTreeChildrenIdsIce');
      }

      if (localStorage.getItem('iceHisAllCheckedIds')) {
        localStorage.removeItem('iceHisAllCheckedIds');
      }

      if (localStorage.getItem('allEnergyTreeChildrenIdsIce')) {
        localStorage.removeItem('allEnergyTreeChildrenIdsIce');
      }
    },
    handleClick: function handleClick(row) {
      this.projectName = row.entryName;
      this.projectInfo = row; // 切换项目清除上个系统额缓存

      this.clearCache_his_energy();
      sessionStorage.setItem("projectInfo", JSON.stringify(this.projectInfo));

      if (this.$store.state.project.projectInfo) {
        if (this.$store.state.project.projectInfo.id !== row.id) {
          sessionStorage.removeItem("queryForm");
          sessionStorage.removeItem("energyForm");
        }
      }

      this.$store.dispatch("project/setProject", row);
      var path = "";
      this.pathData = [];

      if (row.projectType == 1) {
        document.body.className = '';
        this.$store.dispatch("project/setProjectType", 1);
        this.pathData = this.pathDataIce;
        path = this.pathData[0];
      } else {
        this.$store.dispatch("project/setProjectType", 0);
        this.pathData = this.pathDataCon;
        path = this.pathData[0];
        var skin = localStorage.getItem('skin');
        document.body.className = skin;
      } //path为空时


      var oldPath = sessionStorage.getItem("path");

      if (oldPath == null || oldPath == "") {
        this.$router.push({
          path: "/".concat(path)
        });
        sessionStorage.setItem("path", path);
        return;
      } // 相同类型项目切换


      if (oldPath == path) {
        this.$router.go(0);
      } else {
        this.$router.push({
          path: "/".concat(path)
        });
      }

      sessionStorage.setItem("path", path);
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProjectInfo = getProjectInfo;
exports.postProjectInfo = postProjectInfo;
exports.putProjectInfo = putProjectInfo;
exports.deleteProjectInfo = deleteProjectInfo;
exports.relevanceProject = relevanceProject;
exports.relevanceProjectOrder = relevanceProjectOrder;
exports.getPCLoginImg = getPCLoginImg;
exports.getAPPLoginImg = getAPPLoginImg;
exports.postLoginImg = postLoginImg;
exports.putLoginImg = putLoginImg;
exports.getUserProject = getUserProject;
exports.getProjectRegionList = getProjectRegionList;
exports.postElectrovalence = postElectrovalence;
exports.getElectrovalence = getElectrovalence;
exports.postConsumptionBatch = postConsumptionBatch;
exports.postColdPriceBatch = postColdPriceBatch;
exports.getConsumptionBatch = getConsumptionBatch;
exports.getColdPriceInfo = getColdPriceInfo;
exports.getLogoImg = getLogoImg;
exports.postLogoImg = postLogoImg;
exports.removeLogoImg = removeLogoImg;
exports.getSystemNameByUse = getSystemNameByUse;
exports.putSystemNameByUse = putSystemNameByUse;
exports.getProjectAlarm = getProjectAlarm;
exports.getProjectAlarmCount = getProjectAlarmCount;
exports.getProjectAlarmStatistics = getProjectAlarmStatistics;
exports.getProjectAlarmLevel = getProjectAlarmLevel;
exports.getProjectEliminate = getProjectEliminate;
exports.getProjectRunDiagnosticHistory = getProjectRunDiagnosticHistory;
exports.getManualProjectRunDiagnosticHistoryDataExport = getManualProjectRunDiagnosticHistoryDataExport;
exports.getManualProjectRunDiagnosticData = getManualProjectRunDiagnosticData;
exports.getManualProjectRunDiagnosticDataExport = getManualProjectRunDiagnosticDataExport;
exports.energySavedHistoryStatistics = energySavedHistoryStatistics;
exports.energySavedHistoryCarbonEmission = energySavedHistoryCarbonEmission;
exports.energySavedHistoryTrend = energySavedHistoryTrend;
exports.energySavedHistoryData = energySavedHistoryData;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function getProjectInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectInfo/projectInfoPage",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function postProjectInfo(params, data) {
  var encryptType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return (0, _request.default)({
    url: "/projectInfo/insertProjectInfo",
    method: 'post',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    }),
    data: data
  });
}

function putProjectInfo(params, data) {
  var encryptType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return (0, _request.default)({
    url: "/projectInfo/updateProjectInfo",
    method: 'put',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    }),
    data: data
  });
}

function deleteProjectInfo(id) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectInfo/deleteProjectInfo/".concat(id),
    method: 'delete',
    params: {
      encryptType: encryptType
    }
  });
} // 设置关联项目


function relevanceProject(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectUser/insertProjectInfo",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
} // 用户关联的项目信息,工单标识信息


function relevanceProjectOrder(userId) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectIdOrWorkOrderIdentListByUserId/".concat(userId),
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}

function getPCLoginImg(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/landingImage/energyLandingImageInfo",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}

function getAPPLoginImg(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/landingImage/appEnergyLandingImageInfo",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}

function postLoginImg(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/landingImage/energyLandingImageInfo",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}

function putLoginImg(params, data) {
  var encryptType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  return (0, _request.default)({
    url: "/landingImage/updateEnergyLandingImage",
    method: 'put',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    }),
    data: data
  });
}

function getUserProject(userId) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectUser/projectInfoListByUserId/".concat(userId),
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}

function getProjectRegionList() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/projectRegion/projectRegionList",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}

function postElectrovalence(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectElectFee/insertEnergyProjectElectFeeBatch",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}

function getElectrovalence(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectElectFee/energyProjectElectFeeList",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 能耗区间新增--常规/蓄冷


function postConsumptionBatch(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectConsumption/insertProjectConsumptionBatch",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}

function postColdPriceBatch(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectColdPrice/insertProjectColdPriceBatch",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
} // 能耗区间获取--常规/蓄冷


function getConsumptionBatch(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectConsumption/projectConsumptionList",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getColdPriceInfo(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectColdPrice/projectColdPriceInfo",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //logo维护


function getLogoImg(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectLogoImage/projectLogoImageByProjectId",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function postLogoImg(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectLogoImage/insertProjectLogoImage",
    method: 'post',
    params: {
      encryptType: encryptType
    },
    data: data
  });
}

function removeLogoImg(id) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectLogoImage/deleteProjectLogoImage/".concat(id),
    method: 'delete',
    params: {
      encryptType: encryptType
    }
  });
}

function getSystemNameByUse() {
  var encryptType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return (0, _request.default)({
    url: "/systemName/systemNameByUser",
    method: 'get',
    params: {
      encryptType: encryptType
    }
  });
}

function putSystemNameByUse(data) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/systemName/updateSystemName",
    method: 'put',
    params: {
      encryptType: encryptType
    },
    data: data
  });
} //告警表格展示


function getProjectAlarm(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectAlarm/getProjectAlarm",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //告警数量


function getProjectAlarmCount(params) {
  return (0, _request.default)({
    url: "/projectAlarm/getProjectAlarmCount",
    method: 'get',
    params: _objectSpread({}, params)
  });
} //告警统计


function getProjectAlarmStatistics(params) {
  return (0, _request.default)({
    url: "/projectAlarm/getProjectAlarmStatistics",
    method: 'get',
    params: _objectSpread({}, params)
  });
} //告警等级


function getProjectAlarmLevel(params) {
  return (0, _request.default)({
    url: "/projectAlarm/getProjectAlarmLevel",
    method: 'get',
    params: _objectSpread({}, params)
  });
} //告警消除


function getProjectEliminate(params) {
  return (0, _request.default)({
    url: "/projectAlarm/getProjectEliminate",
    method: 'get',
    params: _objectSpread({}, params)
  });
} //运行诊断查询（前台根据时间查询）


function getProjectRunDiagnosticHistory(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectRunDiagnostic/getProjectRunDiagnosticHistory",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //运行诊断历史下载


function getManualProjectRunDiagnosticHistoryDataExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectRunDiagnostic/getManualProjectRunDiagnosticHistoryDataExport",
    method: 'get',
    responseType: 'blob',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //手动运行诊断


function getManualProjectRunDiagnosticData(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectRunDiagnostic/getManualProjectRunDiagnosticData",
    method: 'get',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //手动运行诊断下载


function getManualProjectRunDiagnosticDataExport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectRunDiagnostic/getManualProjectRunDiagnosticDataExport",
    method: 'get',
    responseType: 'blob',
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //节能量统计+累计减少排放量


function energySavedHistoryStatistics(params) {
  return (0, _request.default)({
    url: "/energySavedHistory/energySavedHistoryStatistics",
    method: 'get',
    params: _objectSpread({}, params)
  });
} //减少二氧化碳排放


function energySavedHistoryCarbonEmission(params) {
  return (0, _request.default)({
    url: "/energySavedHistory/energySavedHistoryCarbonEmission",
    method: 'get',
    params: _objectSpread({}, params)
  });
} //节能量趋势


function energySavedHistoryTrend(params) {
  return (0, _request.default)({
    url: "/energySavedHistory/energySavedHistoryTrend",
    method: 'get',
    params: _objectSpread({}, params)
  });
} //节能量数据


function energySavedHistoryData(params) {
  return (0, _request.default)({
    url: "/energySavedHistory/energySavedHistoryData",
    method: 'get',
    params: _objectSpread({}, params)
  });
}
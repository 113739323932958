"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dataQuery = require("@/api/dataQuery");

var _to_xlsx = _interopRequireDefault(require("@/utils/to_xlsx"));

var _dayjs = _interopRequireDefault(require("dayjs"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      projectName: this.$store.state.project.projectInfo.entryName,
      isActive: false,
      acitveRow: {
        date: "",
        dateName: ""
      },
      isShow: 1,
      dayData: (0, _dayjs.default)().format("YYYY-MM-DD"),
      loadingOne: false,
      // 获取每个月的第一天
      monthFirstDay: (0, _dayjs.default)().startOf('month').format('YYYY-MM-DD'),
      // 获取当前天数
      monthToday: (0, _dayjs.default)().format("YYYY-MM-DD"),
      monthData: (0, _dayjs.default)().startOf('month').format('YYYY-MM-DD') == (0, _dayjs.default)().format("YYYY-MM-DD") ? (0, _dayjs.default)().subtract(1, "month").format("YYYY-MM") : (0, _dayjs.default)().format("YYYY-MM"),
      yearData: (0, _dayjs.default)().startOf('year').format('YYYY-MM') == (0, _dayjs.default)().format("YYYY-MM") ? (0, _dayjs.default)().subtract(1, "year").format("YYYY") : (0, _dayjs.default)().format("YYYY"),
      loading: false,
      userInfo: {},
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      fieldTypeDict: {
        主机: "6",
        冷却水泵: "4",
        冷冻水泵: "3",
        冷却塔: "5",
        末端设备: "13"
      },
      // 表头数据
      tableHead: [],
      tableData: [],
      tableColumn: [],
      downLoading: false,
      colorType: '',
      echartfc: '',
      // 图表颜色
      variableHeight: null,
      zoom: '',
      zoomrAtio: ''
    };
  },
  watch: {
    isShow: function isShow(val) {
      if (val === 1) {
        this.monthData = (0, _dayjs.default)().startOf('month').format('YYYY-MM-DD') == (0, _dayjs.default)().format("YYYY-MM-DD") ? (0, _dayjs.default)().subtract(1, "month").format("YYYY-MM") : (0, _dayjs.default)().format("YYYY-MM");
      } else if (val === 2) {
        this.yearData = (0, _dayjs.default)().startOf('year').format('YYYY-MM') == (0, _dayjs.default)().format("YYYY-MM") ? (0, _dayjs.default)().subtract(1, "year").format("YYYY") : (0, _dayjs.default)().format("YYYY");
      }

      this.getEnergyConsumptionSummaryData();
    },
    acitveRow: function acitveRow(val) {
      if (val) {
        this.getPieEcharts();
      }
    },
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;

      if (this.colorType == 'theme_jyh') {
        this.echartfc = '#fff';
      } else if (this.colorType == 'theme_ytl') {
        this.echartfc = '#333';
      } else {
        this.echartfc = '#333';
      }

      this.getPieEcharts();
    }
  },
  created: function created() {
    this.colorType = this.$store.state.project.colorType;

    if (this.colorType == 'theme_jyh') {
      this.echartfc = '#fff';
    } else if (this.colorType == 'theme_ytl') {
      this.echartfc = '#333';
    } else {
      this.echartfc = '#333';
    }

    this.getEnergyConsumptionSummaryData();

    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
  },
  mounted: function mounted() {
    this.getHeight();
  },
  methods: {
    getHeight: function getHeight() {
      var height = window.screen.height * window.devicePixelRatio;

      if (height >= 2160) {
        this.variableHeight = 835;
      } else if (height >= 1800) {
        this.variableHeight = 845;
      } else if (height >= 1600) {
        this.variableHeight = 930;
      } else if (height >= 1440) {
        this.variableHeight = 815;
      } else if (height >= 1000) {
        this.variableHeight = 720;
      } else if (height >= 800) {
        this.variableHeight = 965;
      } else if (height >= 700) {
        this.variableHeight = 680;
      } else {
        this.variableHeight = 635;
      }
    },
    exportExcel: function exportExcel() {
      this.downLoading = true;
      var excelName = this.projectName + (this.isShow == 2 ? this.yearData : this.monthData) + "设备能耗占比.xlsx";
      var xlsx = new _to_xlsx.default("outTable", excelName, this);
      xlsx.createCustomizeTable();
      this.downLoading = false;
    },
    handleRowClick: function handleRowClick(e) {
      this.acitveRow = e;
      this.isActive = true;
    },
    handleTime: function handleTime() {
      //  
      this.getEnergyConsumptionSummaryData();
    },
    columnStyle: function columnStyle(_ref) {
      var row = _ref.row,
          column = _ref.column,
          rowIndex = _ref.rowIndex,
          columnIndex = _ref.columnIndex;

      if (columnIndex === 0) {
        // 第三第四列的背景色就改变了2和3都是列数的下标
        return "background:rgb(181 181 181 / 10%)";
      }
    },
    getEnergyConsumptionSummaryData: function getEnergyConsumptionSummaryData() {
      var _this2 = this;

      var timeStr = "";

      if (this.isShow === 1) {
        timeStr = this.monthData;
      } else if (this.isShow === 2) {
        timeStr = this.yearData;
      }

      this.loadingOne = true;
      var params = {
        timeType: this.isShow,
        projectId: this.$store.state.project.projectInfo.id,
        dateTime: timeStr
      };
      this.tableData = [];
      this.tableColumn = {};
      (0, _dataQuery.getProjectConsumptionPercentage)(params).then(function (res) {
        if (res && res.length !== 0 && Object.values(res).length !== 0) {
          var tabelData = [];
          var count = 0;
          var maxLnet = 0;

          for (var i = 0; i < Object.values(res).length; i++) {
            if (Object.keys(Object.values(res)[i]).length > maxLnet) {
              maxLnet = Object.keys(Object.values(res)[i]).length;
              count = i;
            }
          }

          var keyList = Object.keys(Object.values(res)[count]);

          for (var key in _this2.fieldTypeDict) {
            if (keyList.includes(_this2.fieldTypeDict[key])) {
              _this2.tableColumn[key] = _this2.fieldTypeDict[key];
            }
          }

          for (var day in res) {
            var obj = {};
            obj.date = (0, _dayjs.default)(day).format("MM/D");
            obj.dateName = (0, _dayjs.default)(day).format("MM月D日");
            obj = Object.assign(obj, res[day]);
            tabelData.push(obj);
          }

          _this2.tableData = tabelData;
          var length = tabelData.length - 1;
          _this2.acitveRow = tabelData[length];
          _this2.loadingOne = false;
        } else {
          _this2.loadingOne = false;
        }
      });
    },
    compare: function compare(property, desc) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];

        if (desc === true) {
          // 升序排列
          return value1 - value2;
        } else {
          // 降序排列
          return value2 - value1;
        }
      };
    },
    getPieEcharts: function getPieEcharts() {
      var _this3 = this;

      var _this = this;

      var pieData = [];

      for (var i in this.acitveRow) {
        if (i == "date" || i == "dateName") {
          continue;
        }

        var obj = {};

        for (var key in this.fieldTypeDict) {
          if (i == this.fieldTypeDict[key]) {
            obj.name = key;
          }
        }

        obj.value = this.acitveRow[i][0];
        pieData.push(obj);
      }

      pieData = pieData.sort(_this.compare("value", false));
      var pieEcharts = this.$echarts.init(document.getElementById("pieCharts"));
      var option = {
        tooltip: {
          trigger: "item",
          borderWidth: 0,
          formatter: function formatter(param) {
            return "".concat(param.marker).concat(param.name, ": ").concat(param.value.toFixed(2), "kwh, \u5360\u6BD4: ").concat(param.percent, "%");
          },
          backgroundColor: "rgba(255,255,255,1)",
          extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#000000",
          textStyle: {
            fontSize: 16,
            lineHeight: 30
          },
          padding: 15
        },
        color: ["#5570c6", "#91cc76", "#fac858", "#ed6666", "#73c0de", "#3ba272", "#fc8352", "#9a61b4", "#ecc49f"],
        legend: {
          type: "plain",
          orient: "horizontal",
          icon: "circle",
          show: true,
          bottom: 0,
          // width: "50%",
          top: "74%",
          itemGap: 30,
          formatter: function formatter(name) {
            var total = 0;
            var target;

            for (var i = 0, l = pieData.length; i < l; i++) {
              total += pieData[i].value;

              if (pieData[i].name === name) {
                target = pieData[i].value;
              }
            }

            var arr = [];

            if (target === 0) {
              arr = ["{a|" + 0 + "%}", "{b|" + name + "}"];
            } else {
              arr = ["{a|" + (target / total * 100).toFixed(2) + "%}", "{b|" + name + "}"];
            }

            return arr.join("\n");
          },
          textStyle: {
            rich: {
              a: {
                fontSize: 20,
                verticalAlign: "top",
                align: "left",
                color: this.echartfc,
                padding: [0, 11, 6, 0]
              },
              b: {
                fontSize: 14,
                align: "left",
                color: this.echartfc,
                padding: [0, 10, 0, 0],
                lineHeight: 20
              }
            }
          }
        },
        series: [{
          type: "pie",
          radius: "60%",
          center: ["50%", "35%"],
          // data: _this.pieList,
          data: pieData,
          // roseType: 'radius',
          // 最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
          label: {
            show: false,
            position: "inside",
            formatter: "{b}:{d}%"
          },
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(91, 12, 10, 0.6)",
            shadowOffsetX: 0,
            shadowOffsetY: 0
          }
        }]
      };
      pieEcharts.setOption(option);
      this.$nextTick(function () {
        _this3.zoom = document.body.style.zoom;
        _this3.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _home = require("@/api/home");

var _auth = require("@/utils/auth");

var _user = require("@/api/user");

var _order = require("@/api/order");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      userInfo: "",
      projectList: [],
      href: "",
      indexNum: "",
      orderCount: 0,
      questionCount: "",
      //蓄冷 菜单路由
      pathDataIce: [],
      //常规 菜单路由
      pathDataCon: [],
      pathData: [],
      routerIndexList: {
        "home-page": 0,
        "system-details": 1,
        "data-analyse": 2,
        "data-query": 3,
        "report-download": 4,
        "feed-back": 5
      }
    };
  },
  computed: {
    orderPermissions: function orderPermissions() {
      return this.$store.getters["user/GET_ProjectOrderPermissions"];
    }
  },
  created: function created() {
    var _this = this;

    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }

    this.indexNum = this.$route.query.id;
    this.getProjectQuestionCountData();
    this.getProjectData();
    this.getOrderCount(); // if (this.userInfo.roleIdentity !== 1001) {
    //   this.handleUserProjectOrder();
    // }

    this.handleUserProjectOrder();
    (0, _auth.getMenusAuthInfo)().then(function (res) {
      var arr = JSON.parse(window.sessionStorage.getItem("menuAuths")) || [];
      _this.pathDataCon = arr.map(function (e) {
        return e.path;
      });
      _this.pathData = _this.pathDataCon;
    });
    (0, _auth.getMenusAuthInfoIce)().then(function (res) {
      var arr = JSON.parse(window.sessionStorage.getItem("menuAuthsice")) || [];
      _this.pathDataIce = arr.map(function (e) {
        return e.path;
      });

      _this.pathData.concat(_this.pathDataIce);
    });
  },
  mounted: function mounted() {},
  methods: {
    getOrderCount: function getOrderCount() {
      var _this2 = this;

      var parmers = {};
      (0, _order.getProjectWorkOrderListByReviewerId)(parmers).then(function (res) {
        var count = 0;

        if (res) {
          var total = res["total"];
          res.rows.forEach(function (item) {
            if (item.handlerType == 1) count++;
          });
        }

        _this2.orderCount = count; // console.log('ffff',)
      });
    },
    // 获取用户关联项目工单权限
    handleUserProjectOrder: function handleUserProjectOrder() {
      var _this3 = this;

      (0, _user.relevanceProjectOrder)((0, _auth.getUserId)()).then(function (res) {
        if (res) {
          _this3.$store.commit("user/SET_ProjectOrderPermissions", res);
        }
      });
    },
    getProjectQuestionCountData: function getProjectQuestionCountData() {
      var _this4 = this;

      (0, _user.getProjectQuestionCount)().then(function (res) {
        _this4.questionCount = res;
      });
    },
    handleGoToProject: function handleGoToProject(item) {
      if (this.$store.state.project.projectInfo) {
        if (this.$store.state.project.projectInfo.id !== item.id) {
          sessionStorage.removeItem("queryForm");
          sessionStorage.removeItem("energyForm");
        }
      }

      this.$store.dispatch("project/setProject", item);
      var path = ""; // 点击蓄冷项目

      if (item.projectType) {
        this.$store.dispatch("project/setProjectType", 1);
        path = this.pathDataIce[0];
      } else {
        this.$store.dispatch("project/setProjectType", 0);
        path = this.pathDataCon[0];
      }

      sessionStorage.setItem("routerIndex", this.routerIndexList[this.pathData[0]]);
      this.$router.push({
        path: "/".concat(path)
      });
      sessionStorage.setItem("path", path);
    },
    goToOrderMananger: function goToOrderMananger() {
      var routeUrl = this.$router.resolve({
        path: "order"
      });
      window.open(routeUrl.href);
    },
    goToHomeCompany: function goToHomeCompany() {
      var routeUrl = this.$router.resolve({
        path: "home-company"
      });
      window.open(routeUrl.href);
    },
    goToMananger: function goToMananger() {
      var _document$location = document.location,
          origin = _document$location.origin,
          pathname = _document$location.pathname;
      var url = origin + pathname + "www/";
      window.open(url);
    },
    goToManangerPath: function goToManangerPath() {
      var _document$location2 = document.location,
          origin = _document$location2.origin,
          pathname = _document$location2.pathname;
      var url = origin + pathname + "www/?type=1";
      window.open(url);
    },
    handOut: function handOut() {
      var _this5 = this;

      this.$confirm("确认退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        sessionStorage.clear();
        (0, _auth.removeToken)();

        _this5.$router.push({
          path: "/login"
        });
      }).catch(function () {});
    },
    getProjectData: function getProjectData() {
      var _this6 = this;

      (0, _home.getProject)().then(function (res) {
        _this6.projectList = res;
        sessionStorage.setItem("projectList", JSON.stringify(_this6.projectList));
      });
    }
  }
};
exports.default = _default;
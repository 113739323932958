"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dayjs = _interopRequireDefault(require("dayjs"));

var _report = require("@/api/report");

var _dataQuery = require("@/api/dataQuery");

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      params: {
        dataTime: "",
        //	开始时间	query	false
        endTime: "",
        //	结束时间	query	false
        projectId: this.$store.state.project.projectInfo.id
      },
      dataTime: "",
      fileData: [],
      api: process.env.VUE_APP_BASE_API,
      userInfo: {},
      dialogVisible: false,
      reportLogoVisible: 1,
      yearReport: {
        pjlj: '',
        //		平均冷价	string
        glsc: '',
        // 日均供冷时长
        cop: '',
        //		平均cop	string
        pjdj: '',
        //	平均电价	string	
        zsnx: '',
        //	折算能效	string
        zgll: '',
        //	总供冷量	string	
        gllp: '',
        //		  供冷量平	string	
        gllf: '',
        //	  供冷量峰	string
        gllg: '',
        //		  供冷量谷	string
        glts: '',
        //	供冷天数	string	
        ygjsdf: '',
        //	预估节省电费	string
        ygdf: '',
        //	预估电费	string	
        xfl: '',
        //		削峰率	string
        zhdl: '',
        //	总耗电量	string
        xlcfll: '',
        //		蓄冷槽放冷量	string
        xlcfllp: '',
        //		蓄冷槽放冷量平	number(double)
        xlcfllf: '',
        //		蓄冷槽放冷量峰	number(double)
        xlcfllg: '',
        //		蓄冷槽放冷量谷	number(double)
        zxll: '',
        //		总蓄冷量	number(double)
        qnzglsc: '',
        //	全年总供冷时长	string
        zhdlp: '',
        //		  总耗电量平	string
        zhdlf: '',
        //		总耗电量峰	string
        zhdlg: '' //		总耗电量谷

      },
      year: "",
      magnifyChart: {
        first: null,
        thirdly: null,
        fourth: null
      },
      secondSbnh: [],
      nhglObj: {
        dataTime: [],
        gllData: [],
        hdlData: [],
        ydfDate: []
      },
      glscObj: {
        xdata: [],
        psd: [],
        gfd: [],
        dgd: [],
        xlgll: [],
        zjgll: []
      },
      nxglObj: {
        dataTime: [],
        lj: [],
        nx: []
      },
      previewSrc: "",
      uploud: {
        projectId: this.$store.state.project.projectInfo.id,
        yearTime: ""
      },
      visible: false,
      isConfirm: true,
      headers: {
        token: ""
      },
      expireTimeOPtion: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的
        }
      },
      loading: false,
      colorType: "",
      echartfc: "",
      // 图表颜色
      echartYLine: "",
      loadingReport: false,
      powerType: [{
        name: "耗电量"
      }, {
        name: "供冷量"
      }, {
        name: "蓄冷放冷量"
      }],
      activeIndexNhfb: 0,
      powerType1: [{
        name: "耗电量"
      }, {
        name: "供冷量"
      }, {
        name: "供冷分布"
      }],
      activeIndexGlfb: 0,
      nhfbTotal: "",
      zoom: '',
      zoomrAtio: '',
      yl_zoom: '',
      yl_zoomrAtio: ''
    };
  },
  watch: {
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;

      if (!this.colorType) {
        this.echartfc = "#333";
        this.echartYLine = "#ddd";
      } else {
        this.echartfc = "#fff";
        this.echartYLine = "#333";
      }

      this.getFirstChart();
      this.getSecondChartSbnh();
      this.getThirdChart();
      this.getFourthChart();
    }
  },
  mounted: function mounted() {
    var _this = this;

    setTimeout(function () {
      _this.$nextTick(function () {
        _this.zoom = document.body.style.zoom;
        _this.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    }, 2000);
  },
  created: function created() {
    this.colorType = this.$store.state.project.colorType;

    if (!this.colorType) {
      this.echartfc = "#000";
      this.echartYLine = "#ddd";
    } else {
      this.echartfc = "#000";
      this.echartYLine = "#ddd";
    }

    this.headers.token = (0, _auth.getToken)();
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;

    if (month == 1) {
      this.year = (parseInt(year) - 1).toString();
    } else {
      this.year = (0, _dayjs.default)().format("YYYY");
    }

    this.uploud.yearTime = year;
    this.getNhzb();
    this.getNhgl();
    this.getNdbg();
    this.getGl();
    this.getYearReportList();
  },
  methods: {
    // 能耗分布按钮切换
    handelPowerType: function handelPowerType(value) {
      this.activeIndexNhfb = value;
      this.getNhzb();
    },
    // 能数据统计按钮切换
    handelPowerType1: function handelPowerType1(value) {
      this.activeIndexGlfb = value;
      this.getGl();
    },
    // 上传文件前提示
    upLoadFile: function upLoadFile() {
      var _this2 = this;

      // 若未选择需求，则提示选择
      this.$confirm("上传会覆盖之前的，是否继续上传", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this2.toSelectFile();
      }).catch(function (error) {
        return error;
      });
    },
    // toSelectFile方法可以是自己需要做的操作，然后继续选择文件上传
    toSelectFile: function toSelectFile() {
      // 通过ref 触发上传控件
      this.$refs["upload"].$refs["upload-inner"].handleClick();
    },
    yearChange: function yearChange() {
      this.uploud.yearTime = this.year;
      this.getNhzb();
      this.getNhgl();
      this.getNdbg();
      this.getGl();
      this.getYearReportList();
    },
    //
    initChart: function initChart(index) {
      var _this3 = this;

      var opens = document.getElementById("magnifyChart1");
      opens.style.display = "block";
      var lineCharts = this.$echarts.init(document.getElementById("magnifyChartData"));
      lineCharts.clear();

      if (index == 1) {
        lineCharts.setOption(this.magnifyChart.first);
      } else if (index == 3) {
        lineCharts.setOption(this.magnifyChart.thirdly);
      } else if (index == 4) {
        lineCharts.setOption(this.magnifyChart.fourth);
      }

      this.$nextTick(function () {
        _this3.yl_zoom = document.body.style.zoom;
        _this3.yl_zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    },
    // 上传
    uploadSuccess: function uploadSuccess(response, file) {
      if (response.code === 200) {
        this.visible = true;
        this.$message("上传成功");
        this.getYearReportList();
      } else {
        this.$message.error("上传失败" + response.msg);
      }
    },
    getYearReportList: function getYearReportList() {
      var _this4 = this;

      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        year: this.uploud.yearTime
      };
      (0, _report.getYearReportList)(par).then(function (res) {
        //
        if (res.length > 0) {
          var n = res.length - 1;
          _this4.previewSrc = res[n].reportFilePath;
        } else {
          _this4.previewSrc = false;
        }
      });
    },
    getClass: function getClass() {
      if (this.previewSrc) {
        return ["download-box"];
      } else {
        return ["download-box-ban"];
      }
    },
    // 下载
    handlOneDown: function handlOneDown() {
      var _this5 = this;

      this.loading = true;

      if (this.previewSrc) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', this.previewSrc, true);
        xhr.responseType = 'blob';

        xhr.onload = function () {
          if (xhr.status === 200) {
            var blob = xhr.response;
            var url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;

            var fileName = _this5.previewSrc.split('/').pop();

            link.download = fileName;
            link.click();
            window.URL.revokeObjectURL(url);
          }
        };

        xhr.send();
      }

      setTimeout(function () {
        _this5.loading = false;
      }, 1000);
    },
    handlePreview: function handlePreview() {
      // this.dialogVisible = true;
      // this.show = true;
      if (this.previewSrc) {
        window.open(this.previewSrc, "_blank");
      } else {
        this.$message("暂无年度报告");
      }
    },
    // 弹窗 关闭
    handleClose: function handleClose() {
      var _this6 = this;

      var opens = document.getElementById("magnifyChart1");
      opens.style.display = "none";
      this.$nextTick(function () {
        _this6.yl_zoom = '';
        _this6.yl_zoomrAtio = '';
      });
    },
    //年度报告
    getNdbg: function getNdbg() {
      var ts = this;
      ts.loadingReport = true;
      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        dateTime: ts.year + "-01-01 00:00:00"
      };
      (0, _report.getCoolYearReport)(par).then(function (res) {
        if (res) {
          ts.yearReport.pjlj = res.averageColdPrice ? Math.floor(res.averageColdPrice * 100) / 100 : 0;
          ts.yearReport.glsc = res.averageCoolingTime ? Math.floor(res.averageCoolingTime * 100) / 100 : 0;
          ts.yearReport.cop = res.averageCop ? Math.floor(res.averageCop * 100) / 100 : 0;
          ts.yearReport.pjdj = res.averageElectricityPrice ? Math.floor(res.averageElectricityPrice * 100) / 100 : 0;
          ts.yearReport.zsnx = res.conversionEfficiency ? Math.floor(res.conversionEfficiency * 100) / 100 : 0;
          ts.yearReport.zgll = res.coolingCapacity ? Math.floor(res.coolingCapacity * 100) / 100 : 0;
          ts.yearReport.gllp = res.coolingCapacityFlat ? Math.floor(res.coolingCapacityFlat * 100) / 100 : 0;
          ts.yearReport.gllf = res.coolingCapacitySummit ? Math.floor(res.coolingCapacitySummit * 100) / 100 : 0;
          ts.yearReport.gllg = res.coolingCapacityValley ? Math.floor(res.coolingCapacityValley * 100) / 100 : 0;
          ts.yearReport.glts = res.coolingDays ? Math.floor(res.coolingDays * 100) / 100 : 0;
          ts.yearReport.ygjsdf = res.estimatedSaving ? Math.floor(res.estimatedSaving * 100) / 100 : 0;
          ts.yearReport.ygdf = res.estimatedTariff ? Math.floor(res.estimatedTariff * 100) / 100 : 0;
          ts.yearReport.xfl = res.peakReductionRate ? Math.floor(res.peakReductionRate * 100) / 100 : 0;
          ts.yearReport.zhdl = res.powerConsumption ? Math.floor(res.powerConsumption * 100) / 100 : 0;
          ts.yearReport.xlcfll = res.putColdAmount ? Math.floor(res.putColdAmount * 100) / 100 : 0;
          ts.yearReport.xlcfllp = res.putColdAmountFlat ? Math.floor(res.putColdAmountFlat * 100) / 100 : 0;
          ts.yearReport.xlcfllf = res.putColdAmountSummit ? Math.floor(res.putColdAmountSummit * 100) / 100 : 0;
          ts.yearReport.xlcfllg = res.putColdAmountValley ? Math.floor(res.putColdAmountValley * 100) / 100 : 0;
          ts.yearReport.zxll = res.totalColdStorage ? Math.floor(res.totalColdStorage * 100) / 100 : 0;
          ts.yearReport.qnzglsc = res.totalCoolingTime ? (res.totalCoolingTime * 1).toFixed(2) : 0;
          ts.yearReport.zhdlp = res.totalPowerConsumptionFlat ? Math.floor(res.totalPowerConsumptionFlat * 100) / 100 : 0;
          ts.yearReport.zhdlf = res.totalPowerConsumptionSummit ? Math.floor(res.totalPowerConsumptionSummit * 100) / 100 : 0;
          ts.yearReport.zhdlg = res.totalPowerConsumptionValley ? Math.floor(res.totalPowerConsumptionValley * 100) / 100 : 0;
          ts.loadingReport = false;
        }
      });
    },
    //能耗概览
    getNhgl: function getNhgl() {
      var _this7 = this;

      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        reportType: 1,
        time: this.year + "-01-01 00:00:00"
      }; // 能耗概览部分

      this.nhglObj.dataTime = [];
      this.nhglObj.gllData = [];
      this.nhglObj.hdlData = [];
      this.nhglObj.ydfDate = []; // 能效概览部分

      this.nxglObj.dataTime = [];
      this.nxglObj.lj = [];
      this.nxglObj.nx = [];
      (0, _report.getCoolNhglAndNxgl)(par).then(function (res) {
        // 能耗概览部分
        _this7.nhglObj.dataTime = res.map(function (e) {
          return e.time;
        });
        _this7.nhglObj.gllData = res.map(function (e) {
          return e.coolingCapacity;
        });
        _this7.nhglObj.hdlData = res.map(function (e) {
          return e.powerConsumption;
        });
        _this7.nhglObj.ydfDate = res.map(function (e) {
          return e.estimatedTariff.toFixed(2);
        });

        _this7.getFirstChart(); // 能效概览部分


        _this7.nxglObj.dataTime = res.map(function (e) {
          return e.time;
        });
        _this7.nxglObj.lj = res.map(function (e) {
          return e.dailyColdPrice.toFixed(2);
        });
        _this7.nxglObj.nx = res.map(function (e) {
          return e.dailyAverageCop.toFixed(2);
        });

        _this7.getFourthChart();
      });
    },
    //能耗分布
    getNhzb: function getNhzb() {
      var _this8 = this;

      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        reportType: 1,
        time: this.year + "-01-01 00:00:00",
        type: this.activeIndexNhfb
      };
      (0, _dataQuery.getCoolNhfb)(par).then(function (res) {
        if (res && res.length !== 0 && Object.values(res).length !== 0) {
          _this8.secondSbnh = [];

          _this8.secondSbnh.push({
            value: res.normalInterval,
            name: "平时段"
          });

          _this8.secondSbnh.push({
            value: res.peakPeriod,
            name: "高峰时段"
          });

          _this8.secondSbnh.push({
            value: res.troughPeriod,
            name: "低谷时段"
          });
        }

        _this8.nhfbTotal = res.normalInterval * 1 + res.peakPeriod * 1 + res.troughPeriod * 1 + "kWh";

        _this8.getSecondChartSbnh();
      });
    },
    //能耗概览第三张图
    getGl: function getGl() {
      var _this9 = this;

      var par = {
        projectId: this.$store.state.project.projectInfo.id,
        reportType: 1,
        time: this.year + "-01-01 00:00:00",
        type: this.activeIndexGlfb
      };
      this.glscObj.xdata = [];
      this.glscObj.psd = [];
      this.glscObj.gfd = [];
      this.glscObj.dgd = [];
      this.glscObj.xlgll = [];
      this.glscObj.zjgll = [];
      (0, _dataQuery.getCoolGlfb)(par).then(function (res) {
        _this9.glscObj.xdata = res.map(function (e) {
          return e.time;
        });
        _this9.glscObj.psd = res.map(function (e) {
          return e.normalInterval;
        });
        _this9.glscObj.gfd = res.map(function (e) {
          return e.peakPeriod;
        });
        _this9.glscObj.dgd = res.map(function (e) {
          return e.troughPeriod;
        });
        _this9.glscObj.xlgll = res.map(function (e) {
          return e.xlgll;
        });
        _this9.glscObj.zjgll = res.map(function (e) {
          return e.zjgll;
        });

        _this9.getThirdChart();
      });
    },
    // 能耗概览图
    getFirstChart: function getFirstChart() {
      var lineCharts = this.$echarts.init(document.getElementById("first"));
      lineCharts.clear();
      var option = {
        grid: {
          left: "14%",
          right: "6%",
          top: "20%",
          bottom: "20%" // 也可设置left和right设置距离来控制图表的大小

        },
        color: ["#5570c6", "#91cc76", "#fac858"],
        tooltip: {
          trigger: "axis",
          borderWidth: 0,
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          },
          formatter: function formatter(param) {
            //  //console.log("param---" + JSON.stringify(param));
            var str = "";
            var d = "";
            param.forEach(function (data) {
              d = data.axisValue + "</br>";

              if (data.seriesName == "月电费") {
                str += "".concat(data.marker).concat(data.seriesName, ":").concat(data.data, "\u5143");
              } else {
                str += "".concat(data.marker).concat(data.seriesName, ":").concat(data.data, "kWh </br>");
              }
            });
            return d + str;
          }
        },
        title: {
          right: 10,
          top: 10,
          textStyle: {
            fontSize: 16,
            // color: "#333",
            fontWeight: 400,
            color: this.echartfc
          }
        },
        dataZoom: {
          // 放大和缩放
          type: "inside"
        },
        textStyle: {
          fontSize: 14
        },
        legend: {
          type: "plain",
          orient: "horizontal",
          icon: "circle",
          show: true,
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          },
          formatter: function formatter(param) {
            if (param == "月电费") {
              return "".concat(param, "-\u5143");
            }

            return "".concat(param, "-kWh");
          }
        },
        xAxis: {
          axisPointer: {
            type: "shadow"
          },
          type: "category",
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0,
            //使x轴文字显示全
            rotate: 90
          },
          data: this.nhglObj.dataTime
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: this.echartYLine //type:'dashed'虚线

            }
          }
        },
        series: [{
          name: "供冷量",
          data: this.nhglObj.gllData,
          type: "bar"
        }, {
          name: "耗电量",
          data: this.nhglObj.hdlData,
          type: "bar"
        }, {
          name: "月电费",
          data: this.nhglObj.ydfDate,
          type: "bar"
        }]
      };
      this.magnifyChart.first = option;
      lineCharts.setOption(option);
    },
    // 能耗占比图
    getSecondChartSbnh: function getSecondChartSbnh() {
      var lineCharts = this.$echarts.init(document.getElementById("second"));
      lineCharts.clear();
      var option = {
        tooltip: {
          trigger: "item",
          borderWidth: 0,
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          formatter: "{b} : {c}kWh  占比:{d}%"
        },
        textStyle: {
          fontSize: 14
        },
        legend: {
          bottom: 6,
          left: "center",
          textStyle: {
            fontSize: 14,
            color: this.echartfc,
            lineHeight: 30
          }
        },
        series: [{
          bottom: "15%",
          type: "pie",
          radius: "66%",
          data: this.secondSbnh,
          color: ["#fac858", "#ed6666", "#5570c6", "#91cc76"],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          },
          label: {
            color: this.echartfc,
            formatter: function formatter(params) {
              return "".concat(params.name);
            }
          },
          itemStyle: {
            label: {
              show: true,
              formatter: function formatter(param) {
                return "".concat(param.name, ": ").concat(param.value, "%  ");
              }
            },
            labelLine: {
              show: true
            }
          }
        }]
      };
      lineCharts.setOption(option);
    },
    // 能耗概览第三张图
    getThirdChart: function getThirdChart() {
      var lineCharts = this.$echarts.init(document.getElementById("third"));
      lineCharts.clear(); // 耗电量 供冷量用同一类型的图标  供冷分布则用其他的
      // 定义耗电量供冷量数据

      var gdl_hdl_series = [{
        name: "低谷时段",
        type: "bar",
        barWidth: "30%",
        stack: "one",
        emphasis: this.glscObj.dgd,
        data: this.glscObj.dgd.length > 0 ? this.glscObj.dgd : [0, 0, 0, 0, 0, 0, 0]
      }, {
        name: "平时段",
        type: "bar",
        barWidth: "30%",
        stack: "one",
        emphasis: this.glscObj.psd,
        data: this.glscObj.psd.length > 0 ? this.glscObj.psd : [0, 0, 0, 0, 0, 0, 0]
      }, {
        name: "高峰时段",
        type: "bar",
        barWidth: "30%",
        stack: "one",
        emphasis: this.glscObj.gfd,
        data: this.glscObj.gfd.length > 0 ? this.glscObj.gfd : [0, 0, 0, 0, 0, 0, 0]
      }]; // 定义供冷分布数据

      var glfb_series = [{
        name: "主机供冷量",
        type: "bar",
        barWidth: "30%",
        stack: "one",
        emphasis: this.glscObj.zjgll,
        data: this.glscObj.zjgll.length > 0 ? this.glscObj.zjgll : [0, 0, 0, 0, 0, 0, 0]
      }, {
        name: "蓄冷供冷量",
        type: "bar",
        barWidth: "30%",
        stack: "one",
        emphasis: this.glscObj.xlgll,
        data: this.glscObj.xlgll.length > 0 ? this.glscObj.xlgll : [0, 0, 0, 0, 0, 0, 0]
      }];
      var option = {
        grid: {
          left: "5%",
          right: "4%",
          top: "22%",
          bottom: "10%",
          containLabel: true
        },
        legend: {
          data: this.activeIndexGlfb == 2 ? ["主机供冷量", "蓄冷供冷量"] : ["低谷时段", "平时段", "高峰时段"],
          top: "3%",
          textStyle: {
            color: this.echartfc
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: function formatter(params) {
            var result = params[0].name + "<br>"; // 初始化总值

            var allCount = 0;
            params.forEach(function (item) {
              // 处理value为null的情况
              if (!item.value) {
                item.value = 0;
              }

              allCount += item.value * 1;
            });
            params.forEach(function (item) {
              if (item.value) {
                result += item.marker + " " + item.seriesName + " : " + item.value + "kWh" + ' 占比' + (item.value / allCount * 100).toFixed(2) + "%</br>";
              } else {
                result += item.marker + " " + item.seriesName + " : - </br>";
              }
            });
            return result;
          }
        },
        xAxis: [{
          type: "category",
          data: this.glscObj.xdata.length > 0 ? this.glscObj.xdata : ["", "", "", "", "", "", "", "", "", "", "", ""]
        }],
        yAxis: [{
          type: "value",
          splitLine: {
            lineStyle: {
              color: this.echartYLine
            }
          }
        }],
        // 允许放大缩小
        dataZoom: {
          type: "inside"
        },
        series: this.activeIndexGlfb == 2 ? glfb_series : gdl_hdl_series
      };
      this.magnifyChart.thirdly = option;
      lineCharts.setOption(option);
    },
    // 能效概览 图
    getFourthChart: function getFourthChart() {
      var lineCharts = this.$echarts.init(document.getElementById("fourth"));
      lineCharts.clear();
      var option = {
        tooltip: {
          trigger: "axis",
          borderWidth: 0,
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          padding: 15
        },
        grid: {
          bottom: 70,
          left: 50
        },
        dataZoom: {
          // 放大和缩放
          type: "inside"
        },
        textStyle: {
          fontSize: 14
        },
        legend: {
          data: ["冷价", "能效"],
          // top: 10,
          icon: "circle",
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          }
        },
        xAxis: {
          axisPointer: {
            type: "shadow"
          },
          position: {
            bottom: 0
          },
          type: "category",
          axisTick: {
            show: false
          },
          boundaryGap: true,
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 90,
            textStyle: {
              color: "#999999" // 文字颜色

            }
          },
          data: this.nxglObj.dataTime
        },
        yAxis: [{
          min: 0,
          type: "value",
          position: "left",
          name: "冷价",
          splitLine: {
            show: false
          }
        }, {
          type: "value",
          position: "right",
          name: "能效",
          min: 0,
          splitLine: {
            show: false
          } // axisLabel: {
          //   formatter: "{value}%",
          // },

        }],
        series: [{
          name: "冷价",
          type: "bar",
          barWidth: "10",
          itemStyle: {
            color: "#34aa44",
            /// 折线点的颜色
            lineStyle: {
              color: "#34aa44" ///　折线的颜色

            }
          },
          data: this.nxglObj.lj
        }, {
          name: "能效",
          type: "line",
          yAxisIndex: 1,
          symbol: "circle",
          symbolSize: 10,
          //折线点的大小
          itemStyle: {
            color: "#1665d8",
            /// 折线点的颜色
            lineStyle: {
              color: "#1665d8" ///　折线的颜色

            }
          },
          data: this.nxglObj.nx
        }]
      };
      this.magnifyChart.fourth = option;
      lineCharts.setOption(option);
    }
  }
};
exports.default = _default;
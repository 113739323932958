"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _index = _interopRequireDefault(require("element-ui/lib/theme-chalk/index.css"));

var _lib = _interopRequireDefault(require("element-ui/lib"));

var _vue = _interopRequireDefault(require("vue"));

var _App = _interopRequireDefault(require("./App.vue"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var echarts = _interopRequireWildcard(require("echarts"));

var _auth = require("@/utils/auth");

require("vue-g2");

require("normalize.css/normalize.css");

require("./plugins/element.js");

require("./styles/element-variables.scss");

require("@/styles/index.scss");

require("./icons");

require("../static/font/font.css");

require("./permission");

var _dayjs = _interopRequireDefault(require("dayjs"));

require("@/assets/css/color.less");

require("@/assets/css/hover-min.css");

require("./assets/index.js");

var _zoom = require("./utils/zoom");

var _dataView = _interopRequireDefault(require("@jiaminghi/data-view"));

var _indexDefault = _interopRequireDefault(require("./layout/index-default.vue"));

var _indexCool = _interopRequireDefault(require("./layout/index-cool.vue"));

var _index4 = _interopRequireDefault(require("./layout/index.vue"));

var _mock = require("../mock");

/*
 * @Author: your name
 * @Date: 2020-11-24 15:52:23
 * @LastEditTime: 2021-06-24 10:17:45
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 *
 * @FilePath: \yiLong\src\main.js
 */
// 引入echarts
//引入vant g2
// A modern alternative to CSS resets
// global css
// icon
// permission control
// import './plugins/svg-icon';
// 将自动注册所有组件为全局组件
(0, _zoom.setRem)();

_vue.default.use(_dataView.default);

_vue.default.prototype.dayjs = _dayjs.default;

_vue.default.use(_lib.default);

_vue.default.prototype.$isBtnAuth = _auth.isBtnAuth; // 功能权限

_vue.default.prototype.$echarts = echarts; // 注册layout default-layout 常规  cool-layout 蓄冷

_vue.default.component('default-layout', _indexDefault.default);

_vue.default.component('cool-layout', _indexCool.default);

_vue.default.component('layout', _index4.default); // 监听sessionStorage


_vue.default.prototype.resetSetItem = function (key, newVal) {
  if (key === 'watchStorage') {
    var newStorageEvent = document.createEvent('StorageEvent');
    var storage = {
      setItem: function setItem(k, val) {
        sessionStorage.setItem(k, val);
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        window.dispatchEvent(newStorageEvent);
      }
    };
    return storage.setItem(key, newVal);
  }
};
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */


if (process.env.NODE_ENV === 'production') {
  (0, _mock.mockXHR)();
}

_vue.default.config.productionTip = false;
new _vue.default({
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount('#app');
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "banner" },
      [
        _c(
          "el-carousel",
          {
            attrs: {
              "indicator-position": "none",
              interval: _vm.interval,
              arrow: "never"
            }
          },
          _vm._l(_vm.imgList, function(item, index) {
            return _c("el-carousel-item", { key: index }, [
              _c("img", { attrs: { src: item } })
            ])
          }),
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tap-login" }, [
      _c(
        "div",
        { staticClass: "login-container" },
        [
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              attrs: {
                model: _vm.loginForm,
                rules: _vm.loginRules,
                "hide-required-asterisk": ""
              }
            },
            [
              _c("div", { staticClass: "title-container" }, [
                _c("h3", { staticClass: "title" }, [
                  _vm._v("数字化机房管理平台")
                ])
              ]),
              _vm._v(" "),
              _c(
                "el-popover",
                {
                  staticClass: "popoverz",
                  attrs: { trigger: "click", placement: "right" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "sys-version",
                      attrs: { slot: "reference" },
                      slot: "reference"
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.version) +
                          "\n              "
                      ),
                      _c("i", {
                        staticClass: "el-icon-info",
                        staticStyle: { color: "#1665D8", "margin-left": "5px" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "pre",
                    {
                      staticStyle: { "font-size": "12px", "z-index": "99999" }
                    },
                    [
                      _vm._v("              "),
                      _vm._v("\n"),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#333333",
                            "font-size": "13px",
                            "font-weight": "500",
                            "margin-bottom": "20px",
                            "margin-left": "14px"
                          }
                        },
                        [_vm._v("V6.1.0版本更新说明：")]
                      ),
                      _vm._v("\n"),
                      _c("div", { staticStyle: { color: "#333333" } }, [
                        _vm._v("\n  "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#333333",
                              "font-weight": "600",
                              "font-size": "13px",
                              "margin-bottom": "20px"
                            }
                          },
                          [_vm._v("新增功能：")]
                        ),
                        _vm._v(
                          "\n  1.新增设备管理功能\n  2.新增“远天蓝”皮肤\n  3.新增电话告警功能\n  "
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#333333",
                              "font-weight": "600",
                              "margin-top": "10px"
                            }
                          },
                          [_vm._v("功能优化：")]
                        ),
                        _vm._v(
                          "\n  1.数据查询功能优化\n  2.能耗查询功能优化\n  3.数据采集功能优化\n  4.离线告警功能优化\n  5.修改已知bug\n"
                        )
                      ]),
                      _vm._v("\n          ")
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "username" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { name: "username", placeholder: "请输入用户名" },
                      model: {
                        value: _vm.loginForm.username,
                        callback: function($$v) {
                          _vm.$set(_vm.loginForm, "username", $$v)
                        },
                        expression: "loginForm.username"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-user",
                        attrs: { slot: "prefix" },
                        slot: "prefix"
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "password" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "password", placeholder: "请输入密码" },
                      model: {
                        value: _vm.loginForm.password,
                        callback: function($$v) {
                          _vm.$set(_vm.loginForm, "password", $$v)
                        },
                        expression: "loginForm.password"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-lock",
                        attrs: { slot: "prefix" },
                        slot: "prefix"
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "login-submit" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submit",
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.handleLogin("loginForm")
                        }
                      }
                    },
                    [_vm._v("\n              登录\n            ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-foot" }, [
      _c(
        "a",
        {
          staticStyle: { color: "#666666" },
          attrs: {
            target: "_blank",
            href:
              "https://beian.miit.gov.cn/state/outPortal/loginPortal.action#/Integrated/index"
          }
        },
        [_vm._v("\n          粤ICP备2021015902号\n        ")]
      ),
      _vm._v(" "),
      _c("span", [_vm._v("|")]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticStyle: { display: "flex", "align-items": "center" },
          attrs: {
            target: "_blank",
            href:
              "https://beian.miit.gov.cn/state/outPortal/loginPortal.action#/Integrated/index"
          }
        },
        [
          _c("img", {
            staticStyle: { float: "left" },
            attrs: { src: require("../../assets/icon_ban.png") }
          }),
          _vm._v(" "),
          _c(
            "p",
            { staticStyle: { margin: "0px 0px 0px 5px", color: "#666666" } },
            [_vm._v("\n            粤公网安备 44030502007851号\n          ")]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "theme-box" }, [
    _c("div", { staticClass: "title" }, [_vm._v("报警记录")]),
    _vm._v(" "),
    _c("div", { staticClass: "content-box" }, [
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-table",
            {
              ref: "table",
              attrs: { data: _vm.tableData, height: 200 },
              on: {
                "cell-mouse-enter": _vm.mouseEnter,
                "cell-mouse-leave": _vm.mouseLeave
              }
            },
            [
              _c(
                "template",
                { slot: "empty" },
                [
                  _c("el-empty", {
                    attrs: { "image-size": 50, description: "当前暂无报警记录" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "content",
                  label: "报警内容",
                  "show-overflow-tooltip": ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "alarmTime",
                  label: "报警时间",
                  width: "120",
                  "show-overflow-tooltip": ""
                }
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "type", label: "是否确认", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              scope.row.everconfirmed == 1 ? "已确认" : "未确认"
                            ) +
                            "\n          "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.everconfirmed == 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "success", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.batchUpdateProjectAlarmState(
                                      scope.row.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("确认")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "info", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.batchUpdateProjectAlarmState(
                                      scope.row.id
                                    )
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            )
                      ]
                    }
                  }
                ])
              })
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
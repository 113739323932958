var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chart-box" }, [
    _c(
      "div",
      { staticClass: "title", staticStyle: { position: "relative" } },
      [
        _vm.name == "关键参数"
          ? _c(
              "el-popover",
              {
                attrs: {
                  placement: "top-start",
                  title: "",
                  width: "300",
                  trigger: "click",
                  content: ""
                }
              },
              [
                _c("div", [_vm._v("影响系统运行的主要关键参数")]),
                _vm._v(" "),
                _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
                  _vm._v("\n        " + _vm._s(_vm.name) + "\n        "),
                  _c("i", {
                    staticClass: "el-icon-info",
                    staticStyle: { color: "#686dca", "margin-left": "5px" }
                  })
                ])
              ]
            )
          : _c("div", [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" "),
        _vm.name === "供冷分布" || _vm.name === "系统每小时耗电量"
          ? _c("img", {
              staticStyle: { position: "absolute", left: "90px" },
              attrs: { src: require("../../../assets/pic_time.png"), alt: "" }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "line-chart" }, [
      _c("div", {
        style:
          "position: absolute;width: 100%;height: 100%;zoom:" +
          _vm.zoomrAtio +
          ";transform:scale(" +
          _vm.zoom +
          ");transform-origin:0px 0px;",
        attrs: { id: _vm.id }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.setToken = setToken;
exports.setUserId = setUserId;
exports.getUserId = getUserId;
exports.removeToken = removeToken;
exports.getMenusAuthInfo = getMenusAuthInfo;
exports.getMenusAuthInfoIce = getMenusAuthInfoIce;
exports.isBtnAuth = isBtnAuth;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _user = require("@/api/user");

var _index = require("@/utils/index");

var _home = require("@/api/home");

var _index2 = _interopRequireDefault(require("@/store/index"));

/*
 * @Author: your name
 * @Date: 2020-12-25 17:05:05
 * @LastEditTime: 2021-02-22 13:51:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yilong\src\utils\auth.js
 */
var TokenKey = 'Admin-Token';

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function setUserId(id) {
  return _jsCookie.default.set('userId', id);
}

function getUserId(id) {
  return _jsCookie.default.get('userId');
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
} // 获取常规页面路由和按钮权限


function getMenusAuthInfo() {
  return new Promise(function (resolve) {
    var MenusArr = [];
    var AuthArr = [];
    var projectType = 0;
    (0, _user.getCurrMenus)({
      projectType: projectType
    }).then(function (data) {
      getMenusList(MenusArr, data);
      getAuthCodes(AuthArr, data);
      sessionStorage.setItem('umenuauths', JSON.stringify(data));
      sessionStorage.setItem('menuAuths', JSON.stringify(MenusArr));
      sessionStorage.setItem('authCodes', JSON.stringify(AuthArr));
      resolve({
        menus: MenusArr,
        authCodes: AuthArr
      });
    });
    (0, _home.getSkin)(getUserId()).then(function (res) {
      if (_index2.default.state.project.projectInfo && _index2.default.state.project.projectInfo.projectType == 1) {
        document.body.className = '';
      } else {
        document.body.className = res;
      }

      localStorage.setItem('skin', res ? res : 'theme_default');
    });
  });
} // 获取蓄冷页面路由和按钮权限


function getMenusAuthInfoIce() {
  return new Promise(function (resolve) {
    var MenusArr = [];
    var AuthArr = [];
    var projectType = 1;
    (0, _user.getCurrMenus)({
      projectType: projectType
    }).then(function (data) {
      getMenusList(MenusArr, data);
      getAuthCodes(AuthArr, data);
      sessionStorage.setItem('umenuauthsice', JSON.stringify(data));
      sessionStorage.setItem('menuAuthsice', JSON.stringify(MenusArr));
      sessionStorage.setItem('authCodesice', JSON.stringify(AuthArr));
      resolve({
        menus: MenusArr,
        authCodes: AuthArr
      });
    });
    (0, _home.getSkin)(getUserId()).then(function (res) {
      if (_index2.default.state.project.projectInfo && _index2.default.state.project.projectInfo.projectType == 1) {
        document.body.className = '';
      } else {
        document.body.className = res;
      }
    });
  });
}
/* 是否有按钮权限 */


function isBtnAuth(btKey) {
  // 判断是常规系统还是蓄冷系统的权限码
  var authsKey = [];

  if (window.sessionStorage.getItem('projectType') == 1) {
    authsKey = window.sessionStorage.getItem('authCodesice');
  } else {
    authsKey = window.sessionStorage.getItem('authCodes');
  }

  var key = btKey.replace(/(^\s*)|(\s*$)/g, '');

  if ((0, _index.isEmpty)(authsKey)) {
    return false;
  } else if (authsKey.indexOf(key) > -1) {
    return true;
  } else {
    return false;
  }
} // 处理导航菜单


var getMenusList = function getMenusList(routers, data) {
  if (!(0, _index.isEmpty)(data) && Array.isArray(data)) {
    //  
    data.forEach(function (item) {
      if (item.type === 0) {
        var menu = {
          path: item.path,
          name: item.name,
          icon: item.icon,
          id: item.id,
          type: item.type,
          priority: item.priority,
          projectType: item.projectType
        };

        if (!(0, _index.isEmpty)(item.children)) {
          menu.children = [];
          getMenusList(menu.children, item.children);
        }

        routers.push(menu);
      }
    });
  }
}; // 处理权限码


var getAuthCodes = function getAuthCodes(arr, codeArr) {
  if (Array.isArray(codeArr) && codeArr.length !== 0) {
    codeArr.forEach(function (item) {
      if (!(0, _index.isEmpty)(item.code)) {
        arr.push(item.code.trim());
      }

      if (Array.isArray(item.children) && item.children.length !== 0) {
        getAuthCodes(arr, item.children);
      }
    });
  }
};
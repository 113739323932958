"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dayjs = _interopRequireDefault(require("dayjs"));

var _report = require("@/api/report");

var _home = require("@/api/home");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import axios from 'axios'
var _default = {
  data: function data() {
    return {
      resetReportLoading: false,
      homeDataPie: {
        coolingCapacityByHourMapList: {
          list1: [],
          list2: []
        }
      },
      value: (0, _dayjs.default)().format("YYYY-MM"),
      isShow: true,
      checked: true,
      calendarData: [],
      haveData: [],
      reportData: [],
      previewSrc: "",
      previewSrcOne: "",
      previewData: {},
      dialogVisible: false,
      reportLogoVisible: 1,
      dayActive: (0, _dayjs.default)().format("YYYY-MM-DD"),
      currentDay: (0, _dayjs.default)().format("YYYY-MM-DD"),
      monthData: (0, _dayjs.default)().format("YYYY-MM"),
      userInfo: {},
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
      loading: false,
      colorType: '',
      echartfc: '',
      // 图表颜色
      zoom: '',
      zoomrAtio: ''
    };
  },
  watch: {
    value: function value(val) {
      //console.log("-----------", val);
      this.monthData = (0, _dayjs.default)(val).format("YYYY-MM");
    },
    monthData: function monthData(val) {
      //console.log("-----------", val);
      this.dayActive = val;
      this.calendarData = [];
      this.getDayReportListData();
    },
    homeDataPie: function homeDataPie(val) {
      // console.log("--------变化了---", val);
      if (val) {
        this.getHdlChart();
        this.getGlfbChart();
        this.getZgllChart();
      }
    },
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;

      if (this.colorType == 'theme_jyh') {
        this.echartfc = '#fff';
      } else if (this.colorType == 'theme_ytl') {
        this.echartfc = '#333';
      } else {
        this.echartfc = '#333';
      }

      this.getHdlChart();
      this.getGlfbChart();
      this.getZgllChart();
    }
  },
  computed: {
    installedCapacity: function installedCapacity() {
      return this.$store.state.project.projectInfo.installedCapacity;
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    this.colorType = this.$store.state.project.colorType;

    if (this.colorType == 'theme_jyh') {
      this.echartfc = '#fff';
    } else if (this.colorType == 'theme_ytl') {
      this.echartfc = '#333';
    } else {
      this.echartfc = '#333';
    } // 每月1号显示上个月


    if (this.dayjs().date() == 1) {
      this.monthData = this.dayjs().subtract(1, "month").format("YYYY-MM");
      this.value = this.monthData;
      var dayNumber = this.dayjs(this.monthData).daysInMonth() - 1;
      this.currentDay = this.dayjs(this.monthData).add(dayNumber, "day").format("YYYY-MM-DD");
      this.dayTime = this.currentDay;
    }

    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } // 判断路由的数据


    if (this.$route.query.day) {
      this.value = this.$route.query.day;

      if ((0, _dayjs.default)(this.$route.query.day).format("YYYY-MM") === (0, _dayjs.default)().format("YYYY-MM")) {
        this.calendarData = [];
        this.getDayReportListData();
      }
    } else {
      this.getDayReportListData();
    }

    (0, _home.getProject)().then(function (res) {
      res.forEach(function (item) {
        if (item.id == _this2.$store.state.project.projectInfo.id) {
          _this2.$store.dispatch("project/setProject", item);
        }
      });
    });
  },
  methods: {
    // 重新生成日报
    handleResetReport: function handleResetReport() {
      var _this3 = this;

      this.resetReportLoading = true;
      (0, _report.getDayProjectCoolingReportRegenerate)({
        dayTime: (0, _dayjs.default)(this.dayActive).format("YYYY-MM-DD HH:mm:ss"),
        projectId: this.$store.state.project.projectInfo.id
      }).then(function (res) {
        _this3.resetReportLoading = false;

        _this3.$message.success("重新生成日报成功");
      }).catch(function (err) {
        _this3.resetReportLoading = false;
      });
    },
    // 获取图表数据
    getProjectListData: function getProjectListData(date) {
      var _this4 = this;

      (0, _report.getDayChartData)({
        dayReportDate: (0, _dayjs.default)(date).format("YYYY-MM-DD HH:mm:ss"),
        projectId: this.$store.state.project.projectInfo.id
      }).then(function (res) {
        _this4.homeDataPie = res;

        if (res) {
          // 处理耗电量数据
          if (res.powerConsumptionMapList && res.powerConsumptionMapList.length > 0) {
            var powerConsumptionMapList = {};

            _this4.homeDataPie.powerConsumptionMapList.map(function (obj) {
              for (var key in obj) {
                powerConsumptionMapList[key] = obj[key];
              }
            });

            var count = 0;

            for (var k in powerConsumptionMapList) {
              count += Number(powerConsumptionMapList[k]);
            }

            _this4.homeDataPie.powerConsumptionMapListCount = count.toFixed(2);
            _this4.homeDataPie.powerConsumptionMapList = powerConsumptionMapList;
          } // 处理当日设备能耗占比数据


          if (res.energyConsumptionMapList && res.energyConsumptionMapList.length > 0) {
            var data = _this4.homeDataPie.energyConsumptionMapList;
            var energyConsumptionMapList = [];

            for (var key in data) {
              for (var i in data[key]) {
                var obj = {
                  name: i,
                  value: data[key][i][0],
                  unit: data[key][i][1]
                };
                energyConsumptionMapList.push(obj);
              }
            }

            var _count = 0;

            for (var _k in energyConsumptionMapList) {
              _count += Number(energyConsumptionMapList[_k].value);
            }

            _this4.homeDataPie.energyConsumptionMapListCount = _count.toFixed(2);
            _this4.homeDataPie.energyConsumptionMapList = energyConsumptionMapList;
          } // 处理绘制供冷分布数据


          if (res.coolingCapacityByHourMapList && res.coolingCapacityByHourMapList.length > 0) {
            var coolingCapacityByHourMapList = {
              list1: [],
              list2: []
            };
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = res.coolingCapacityByHourMapList[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var item = _step.value;
                var _key = Object.keys(item)[0];
                var value = Number(Object.values(item)[0]);
                coolingCapacityByHourMapList.list1.push([_key, value, [value, (value / Number(_this4.installedCapacity) / 3.517 * 100).toFixed(2)]]);
                coolingCapacityByHourMapList.list2.push([_key, (value / Number(_this4.installedCapacity) / 3.517).toFixed(2) * 100, [value, (value / Number(_this4.installedCapacity) / 3.517 * 100).toFixed(2)]]);
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            _this4.homeDataPie.coolingCapacityByHourMapList = coolingCapacityByHourMapList;
          }
        }
      });
    },
    // 耗电量
    getHdlChart: function getHdlChart() {
      var _this5 = this;

      var lineCharts = this.$echarts.init(document.getElementById("hdl"));
      var data = this.homeDataPie.powerConsumptionMapList;
      var num = Math.floor((parseFloat(data.powerConsumptionLowEbb) + parseFloat(data.powerConsumptionSummit) + parseFloat(data.powerConsumptionLevel)) * 100) / 100;
      var zb = data.powerConsumptionLowEbb == '0.00' ? '0' : (data.powerConsumptionLowEbb / num * 100).toFixed(2);
      var zb1 = data.powerConsumptionSummit == '0.00' ? '0' : (data.powerConsumptionSummit / num * 100).toFixed(2);
      var zb2 = data.powerConsumptionLevel == '0.00' ? '0' : (data.powerConsumptionLevel / num * 100).toFixed(2);
      var seriesData = [{
        value: data.powerConsumptionLowEbb,
        name: "低谷时段",
        zb: zb
      }, {
        value: data.powerConsumptionSummit,
        name: "高峰时段",
        zb: zb1
      }, {
        value: data.powerConsumptionLevel,
        name: "平时段",
        zb: zb2
      }];
      lineCharts.clear();
      var option = {
        color: ["#91cc76", "#fac858", "#ed6666"],
        title: {
          right: 10,
          top: 10,
          textStyle: {
            fontSize: 14,
            color: this.echartfc,
            fontWeight: 400
          }
        },
        textStyle: {
          fontSize: 14
        },
        tooltip: {
          trigger: "item",
          borderWidth: 0,
          formatter: function formatter(params) {
            return "".concat(params.name, "<br />").concat(params.marker).concat(params.value, ", \u5360\u6BD4:").concat(params.data.zb, "%");
          },
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          padding: 15
        },
        legend: {
          bottom: 6,
          left: "center",
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          }
        },
        series: [{
          name: "耗电量",
          bottom: "15%",
          type: "pie",
          radius: "66%",
          data: seriesData,
          label: {
            formatter: function formatter(params) {
              return "".concat(params.name, "  ").concat(params.data.zb, "%");
            },
            color: this.echartfc
          }
        }]
      };
      lineCharts.setOption(option);
      this.$nextTick(function () {
        _this5.zoom = document.body.style.zoom;
        _this5.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    },
    // 绘制供冷分布
    getGlfbChart: function getGlfbChart() {
      var lineCharts = this.$echarts.init(document.getElementById("glfb"));
      lineCharts.clear();
      var option = {
        tooltip: {
          trigger: "item",
          borderWidth: 0,
          formatter: function formatter(params) {
            return "".concat(params.name, "<br /> \u4F9B\u51B7\u91CF    ").concat(params.value[2][0], "<br />  \u8D1F\u8377\u7387     ").concat(params.value[2][1], "%");
          },
          textStyle: {
            fontSize: 14,
            lineHeight: 30
          },
          padding: 15
        },
        grid: {
          bottom: 70,
          left: 60
        },
        textStyle: {
          fontSize: 14,
          color: this.echartfc
        },
        legend: {
          data: ["供冷量", "负荷率"],
          textStyle: {
            color: this.echartfc
          }
        },
        xAxis: {
          position: {
            bottom: 0
          },
          type: "category",
          axisTick: {
            show: false
          },
          boundaryGap: true
        },
        yAxis: [{
          min: 0,
          type: "value",
          name: "",
          splitLine: {
            show: false
          }
        }, {
          type: "value",
          name: "",
          min: 0,
          splitLine: {
            show: false
          },
          axisLabel: {
            formatter: "{value}%"
          }
        }],
        series: [{
          name: "供冷量",
          type: "bar",
          barWidth: "10",
          itemStyle: {
            color: "#91cc76",
            /// 折线点的颜色
            lineStyle: {
              color: "#91cc76" ///　折线的颜色

            }
          },
          data: this.homeDataPie.coolingCapacityByHourMapList.list1
        }, {
          name: "负荷率",
          type: "line",
          yAxisIndex: 1,
          symbol: "circle",
          symbolSize: 10,
          //折线点的大小
          itemStyle: {
            color: "#5570c6",
            /// 折线点的颜色
            lineStyle: {
              color: "#5570c6" ///　折线的颜色

            }
          },
          data: this.homeDataPie.coolingCapacityByHourMapList.list2
        }]
      };
      lineCharts.setOption(option);
    },
    // 当日设备能耗占比
    getZgllChart: function getZgllChart() {
      var _this = this;

      var pieData = _this.homeDataPie.energyConsumptionMapList.sort(_this.compare("value", false));

      var num = 0;
      pieData.forEach(function (ite) {
        if (ite.value && ite.value != '0.00') {
          num = Math.floor((num + ite.value) * 100) / 100;
        }
      });
      pieData.map(function (item) {
        item['zb'] = item.value == '0.00' ? '0' : (item.value / num * 100).toFixed(2);
      });
      var lineCharts = this.$echarts.init(document.getElementById("zgll"));
      lineCharts.clear();
      var option = {
        tooltip: {
          confine: true,
          trigger: "item",
          borderWidth: 0,
          color: this.echartfc,
          formatter: function formatter(param) {
            return "".concat(param.marker).concat(param.name, ": ").concat(param.value.toFixed(2), "kWh, \u5360\u6BD4: ").concat(param.data.zb, "%");
          },
          backgroundColor: "rgba(255,255,255,1)",
          extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#000000",
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          },
          padding: 15
        },
        color: ["#5570c6", "#91cc76", "#fac858", "#ed6666"],
        legend: {
          bottom: 20,
          padding: [0, 20],
          type: "scroll",
          icon: "circle",
          show: true,
          itemGap: -2,
          formatter: function formatter(name) {
            var total = 0;
            var target;

            for (var i = 0, l = pieData.length; i < l; i++) {
              total += pieData[i].value;

              if (pieData[i].name === name) {
                target = pieData[i].value;
              }
            }

            var arr = [];

            if (target === 0) {
              // arr = ["{a|" + 0 + "%}", "{b|" + name + "}"];
              arr = ["{b|" + name + "}"];
            } else {
              arr = [// "{a|" + ((target / total) * 100).toFixed(2) + "%}",
              // "{a|}",
              "{b|" + name + "}"];
            }

            return arr.join("\n");
          },
          textStyle: {
            rich: {
              a: {
                fontSize: 14,
                verticalAlign: "top",
                align: "left",
                padding: [0, 11, 28, 0],
                color: this.echartfc
              },
              b: {
                fontSize: 12,
                align: "left",
                color: this.echartfc,
                padding: [0, 10, 0, 0],
                lineHeight: 25
              }
            }
          }
        },
        series: [{
          type: "pie",
          radius: ["40%", "60%"],
          center: ["50%", "45%"],
          // data: _this.pieList,
          data: pieData,
          // roseType: 'radius',
          // 最小的扇区角度（0 ~ 360），用于防止某个值过小导致扇区太小影响交互
          label: {
            show: false,
            position: "inside",
            formatter: "{b}:{d}%",
            color: this.echartfc
          }
        }],
        title: {
          text: _this.homeDataPie.energyConsumptionMapListCount,
          subtext: "kWh",
          left: "center",
          top: "38%",
          textStyle: {
            color: this.echartfc
          } // color: this.echartfc,
          // subtextStyle: {
          //   fontSize: 16,
          // },

        }
      };
      lineCharts.setOption(option);
    },
    compare: function compare(property, desc) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];

        if (desc === true) {
          // 升序排列
          return value1 - value2;
        } else {
          // 降序排列
          return value2 - value1;
        }
      };
    },
    handlOneDown: function handlOneDown() {
      var _this6 = this;

      this.loading = true;

      if (this.previewSrc) {
        var link = document.createElement("a");
        link.href = this.previewSrc;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      setTimeout(function () {
        _this6.loading = false;
      }, 1000);
    },
    handlePreview: function handlePreview() {
      this.dialogVisible = true;
    },
    getDayWordPreviewData: function getDayWordPreviewData(date) {
      var _this7 = this;

      var params = {
        dayTime: date,
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _report.getDayWordPreview)(params).then(function (res) {
        _this7.previewData = res;
      });
      this.getProjectListData(date);
    },
    handlDay: function handlDay(date, data, isShow) {
      var _this8 = this;

      if (isShow) {
        this.dayActive = data.day;
        this.reportData.map(function (e) {
          if ((0, _dayjs.default)(e.reportTime).format("YYYY-MM-DD") === data.day) {
            _this8.previewSrc = e.reportFilePath;

            _this8.getDayWordPreviewData(data.day);
          }
        });
      } else {
        this.$message.warning("此日期暂无数据");
        this.$router.push({
          query: {}
        });
      }
    },
    handleBatchDown: function handleBatchDown() {
      if (this.calendarData.length !== 0) {
        this.getDayReportData();
      } else {
        this.$message.error("未选择下载日期");
      }
    },
    getDayReportListData: function getDayReportListData() {
      var _this9 = this;

      if (this.dayjs().month() == 0 && this.dayjs().date() == 1) {
        this.monthData = this.dayjs().subtract(1, "month").format("YYYY-MM");
      }

      var params = {
        projectId: this.$store.state.project.projectInfo.id,
        dateTime: this.monthData
      };
      (0, _report.getDayReportList)(params).then(function (res) {
        _this9.reportData = res;
        _this9.haveData = res.map(function (e) {
          return (0, _dayjs.default)(e.reportTime).format("YYYY-MM-DD");
        });

        if (res.length !== 0 && !_this9.$route.query.day) {
          var arr = res[res.length - 1];
          _this9.dayActive = (0, _dayjs.default)(arr.reportTime).format("YYYY-MM-DD");

          _this9.getDayWordPreviewData(_this9.dayActive);

          _this9.previewSrc = arr.reportFilePath;
        } // 通过url获取


        var currentDay = _this9.$route.query.day ? _this9.$route.query.day : null;

        if (currentDay) {
          var day = (0, _dayjs.default)(currentDay).format("YYYY-MM-DD");

          _this9.handlDay(new Date(day), {
            day: day
          }, _this9.haveData.indexOf(day) !== -1);
        }
      });
    },
    getDayReportData: function getDayReportData() {
      var params = {
        projectId: this.$store.state.project.projectInfo.id,
        dayTime: this.calendarData
      };
      (0, _report.getDayReport)(params).then(function (res) {
        var link = document.createElement("a");
        link.href = res;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    handleTime: function handleTime(val) {
      // //console.log("选择日期", val);
      this.value = val;
    },
    formatDate: function formatDate(year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
    initData: function initData(cur) {
      var that = this;
      var days = []; //   const leftcount = 0 // 存放剩余数量

      var date;

      if (cur) {
        date = new Date(cur);
      } else {
        var now = new Date();
        var d = new Date(that.formatDate(now.getFullYear(), now.getMonth(), 1));
        d.setDate(35);
        date = new Date(that.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      }

      that.currentDay = date.getDate();
      that.currentYear = date.getFullYear();
      that.currentMonth = date.getMonth() + 1;
      that.currentWeek = date.getDay(); // 1...6,0

      if (that.currentWeek === 0) {
        that.currentWeek = 7;
      }

      var str = that.formatDate(that.currentYear, that.currentMonth, that.currentDay);
      days.length = 0; // 今天是周日，放在第一行第7个位置，前面6个
      // 初始化本周

      for (var i = that.currentWeek - 1; i >= 0; i--) {
        var _d = new Date(str);

        _d.setDate(_d.getDate() - i);

        var dayobject = {}; // 用一个对象包装Date对象  以便为以后预定功能添加属性

        dayobject.day = _d;
        days.push(dayobject); // 将日期放入data 中的days数组 供页面渲染使用
      } // 其他周


      for (var _i = 1; _i <= 42 - that.currentWeek; _i++) {
        var _d2 = new Date(str);

        _d2.setDate(_d2.getDate() + _i);

        var _dayobject = {};
        _dayobject.day = _d2;
        days.push(_dayobject);
      }

      return days;
    },
    handlCalendar: function handlCalendar(date, data) {
      if (this.calendarData.indexOf(data.day) !== -1) {
        this.calendarData = this.calendarData.filter(function (e) {
          return e !== data.day;
        });
      } else {
        this.calendarData.push(data.day);
      }
    },
    handleCheckAll: function handleCheckAll() {
      this.isShow = !this.isShow;

      if (!this.isShow) {
        // const arr = this.initData(this.monthData)
        this.calendarData = this.haveData;
      } else {
        this.calendarData = [];
      }
    },
    changeDay: function changeDay(val) {
      var _this10 = this;

      this.calendarData = val.filter(function (item) {
        return _this10.haveData.indexOf(item) !== -1;
      });
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dayjs = _interopRequireDefault(require("dayjs"));

var _dataAnalysis = require("@/api/dataAnalysis");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      daysData: [],
      calendar: (0, _dayjs.default)().format("YYYY-MM"),
      month: (0, _dayjs.default)().format("MM"),
      daysOne: [],
      lengdeData: [],
      colors: ["rgba(0,0,0,0.02)", "rgba(243,88,88,0.30)", "rgba(245,166,35,0.30)", "rgba(10,189,103,0.30)", "rgba(22,101,216,0.30)"],
      fontColors: ["rgba(0,0,0,1)", "rgba(243,88,88,1)", "rgba(245,166,35,1)", "rgba(10,189,103,1)", "rgba(22,101,216,1)"],
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          if ((0, _dayjs.default)().format('YYYY-MM-DD') == (0, _dayjs.default)().startOf('month').format('YYYY-MM-DD')) {
            return time.getTime() > Date.now() - 1 * 24 * 60 * 60 * 1000;
          } else {
            return time.getTime() > Date.now();
          }
        }
      }
    };
  },
  mounted: function mounted() {
    this.getEnergyConsumptionCalendarData();
    this.getConsumptionBatchData();
  },
  methods: {
    jump: function jump(index) {
      //console.log(new Date(this.daysOne[index].day).toLocaleDateString());
      var day = new Date(this.daysOne[index].day).toLocaleDateString();
      this.$router.push("/report-download?day=" + day); // sessionStorage.setItem('routerIndex', 4)

      this.resetSetItem("watchStorage", 4);
    },
    getConsumptionBatchData: function getConsumptionBatchData() {
      var _this = this;

      var params = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataAnalysis.getConsumptionBatch)(params).then(function (res) {
        _this.lengdeData = res.map(function (e) {
          return e.energyConsumptionMin;
        });
      });
    },
    getEnergyConsumptionCalendarData: function getEnergyConsumptionCalendarData() {
      var _this2 = this;

      var time = null;

      if ((0, _dayjs.default)().format("DD") == "01" && (0, _dayjs.default)().format("MM") == (0, _dayjs.default)(this.calendar).format("MM")) {
        time = (0, _dayjs.default)().add(-1, "month").format("YYYY-MM");
        this.calendar = time;
      } else {
        time = this.calendar;
      }

      var params = {
        timeDate: time,
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataAnalysis.getEnergyConsumptionCalendar)(params).then(function (res) {
        _this2.month = parseInt((0, _dayjs.default)(_this2.calendar).format("MM"));

        var days = _this2.initData(_this2.formatDate((0, _dayjs.default)(_this2.calendar).format("YYYY"), _this2.month, 1));

        _this2.daysOne = _this2.getColorData(days, res); // this.daysOne = this.getFontColorData(days, res);
      });
    },
    handleTime: function handleTime() {
      this.getEnergyConsumptionCalendarData();
    },
    getBackColor: function getBackColor(val) {
      var color = "";

      switch (val[4]) {
        case 0:
          color = "background:".concat(this.colors[0]);
          break;

        case 1:
          color = "background:".concat(this.colors[1]);
          break;

        case 2:
          color = "background:".concat(this.colors[2]);
          break;

        case 3:
          color = "background:".concat(this.colors[3]);
          break;
        // eslint-disable-next-line no-fallthrough

        case 4:
          color = "background:".concat(this.colors[4]);
          break;
      }

      return color;
    },
    getColorData: function getColorData(days, colors) {
      for (var i = 0; i < days.length; i++) {
        for (var j = 0; j < colors.length; j++) {
          for (var key in colors[j]) {
            if (days[i].day.getDate() === Number((0, _dayjs.default)(key).format("DD"))) {
              days[i].calendarData = colors[j][key];
            }
          }
        }
      }

      return days;
    },
    formatDate: function formatDate(year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
    initData: function initData(cur) {
      var that = this;
      var days = []; //   const leftcount = 0 // 存放剩余数量

      var date;

      if (cur) {
        date = new Date(cur);
      } else {
        var now = new Date();
        var d = new Date(that.formatDate(now.getFullYear(), now.getMonth(), 1));
        d.setDate(35);
        date = new Date(that.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      }

      that.currentDay = date.getDate();
      that.currentYear = date.getFullYear();
      that.currentMonth = date.getMonth() + 1;
      that.currentWeek = date.getDay(); // 1...6,0

      if (that.currentWeek === 0) {
        that.currentWeek = 7;
      }

      var str = that.formatDate(that.currentYear, that.currentMonth, that.currentDay);
      days.length = 0; // 今天是周日，放在第一行第7个位置，前面6个
      // 初始化本周

      for (var i = that.currentWeek - 1; i >= 0; i--) {
        var _d = new Date(str);

        _d.setDate(_d.getDate() - i);

        var dayobject = {}; // 用一个对象包装Date对象  以便为以后预定功能添加属性

        dayobject.day = _d;
        days.push(dayobject); // 将日期放入data 中的days数组 供页面渲染使用
      } // 其他周


      for (var _i = 1; _i <= 42 - that.currentWeek; _i++) {
        var _d2 = new Date(str);

        _d2.setDate(_d2.getDate() + _i);

        var _dayobject = {};
        _dayobject.day = _d2;
        days.push(_dayobject);
      }

      return days;
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _projectInfo = require("@/api/projectInfo");

require("nprogress/nprogress.css");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      params: {
        endDate: '',
        //  	结束时间		false	
        projectId: this.$store.state.project.projectInfo.id,
        //  	项目id		false	
        startDate: '' //  	开始时间    

      },
      infors: {},
      count: 0,
      value2: '',
      value3: '',
      list: [],
      loading: true,
      loading1: false,
      fullscreenLoading: false,
      jdt: false,
      //进度条隐藏
      percentage: 0,
      //进度条值
      timeStart: '',
      expireTimeOPtion: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的 
        }
      },
      variableHeight: null,
      colorType: "",
      echartfc: '#DEE5EF',
      echartRadarColor: '#eeeeee'
    };
  },
  created: function created() {
    var time = new Date().getTime() - 24 * 60 * 60 * 1000;
    var yesterday = new Date(time);
    var month = yesterday.getMonth();
    var day = yesterday.getDate();
    yesterday = yesterday.getFullYear() + "-" + (yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : "0" + (yesterday.getMonth() + 1)) + "-" + (yesterday.getDate() > 9 ? yesterday.getDate() : "0" + yesterday.getDate());
    this.params.startDate = yesterday + ' 00:00:00';
    this.params.endDate = yesterday + ' 23:59:59';
    this.getProjectRunDiagnosticHistory();
  },
  mounted: function mounted() {
    this.colorType = this.$store.state.project.colorType;

    if (this.colorType == 'theme_jyh') {
      this.echartfc = '#DEE5EF';
      this.echartRadarColor = '#eeeeee';
    } else if (this.colorType == 'theme_ytl') {
      this.echartfc = '#a6c6eb';
      this.echartRadarColor = '#a6c6eb';
    } else {
      this.echartfc = '#DEE5EF';
      this.echartRadarColor = '#eeeeee';
    }

    this.getHeight();
  },
  methods: {
    getHeight: function getHeight() {
      var height = window.screen.height * window.devicePixelRatio;

      if (height >= 2160) {
        this.variableHeight = 340;
      } else if (height >= 1800) {
        this.variableHeight = 330;
      } else if (height >= 1600) {
        this.variableHeight = 380;
      } else if (height >= 1440) {
        this.variableHeight = 340;
      } else if (height >= 1000) {
        this.variableHeight = 270;
      } else if (height >= 700) {
        this.variableHeight = 360;
      } else {
        this.variableHeight = 280;
      }
    },
    //关闭
    closeShow: function closeShow() {
      this.jdt = false;
      this.increaseend();
    },
    // 默认查询昨天的数据
    getProjectRunDiagnosticHistory: function getProjectRunDiagnosticHistory() {
      var ts = this;
      (0, _projectInfo.getProjectRunDiagnosticHistory)(ts.params).then(function (res) {
        if (res.projectRunDiagnosticInfoVOS) {
          res.projectRunDiagnosticInfoVOS = res.projectRunDiagnosticInfoVOS.filter(function (item) {
            return item.abnormal != 1 && item.tooltip;
          });
        }

        ts.infors = res;
        ts.list.push(res.coolingWaterEnergyEfficiency); //冷却水系统能效

        ts.list.push(res.machineRoomEnergyEfficiency); //机房能效

        ts.list.push(res.coolingTowerEnergyEfficiency); //冷却塔系统能效

        ts.list.push(res.chilledWaterEnergyEfficiency); //冷冻水系统能效

        ts.list.push(res.mainEngineEnergyEfficiency); //主机能效 

        ts.radarEcharts();
        ts.vantChart();
      });
    },
    //历史诊断跳转
    getHistoryData: function getHistoryData() {
      this.$emit('eidtType', '2');
    },
    //诊断
    getRunDiagnosticData: function getRunDiagnosticData() {
      var ts = this; // ts.fullscreenLoading = true;  // 弹窗白色加载 需要就改为true
      // NProgress.start();

      ts.jdt = true;
      ts.list = [];
      (0, _projectInfo.getManualProjectRunDiagnosticData)(ts.params).then(function (res) {
        // ts.fullscreenLoading = false;
        // NProgress.done();
        ts.percentage = 100;
        ts.jdt = false;

        if (res) {
          if (res.projectRunDiagnosticInfoVOS) {
            res.projectRunDiagnosticInfoVOS = res.projectRunDiagnosticInfoVOS.filter(function (item) {
              return item.abnormal != 1 && item.tooltip;
            });
          }

          ts.infors = res;
          ts.list.push(ts.infors.coolingWaterEnergyEfficiency); //冷却水系统能效

          ts.list.push(ts.infors.machineRoomEnergyEfficiency); //机房能效

          ts.list.push(ts.infors.coolingTowerEnergyEfficiency); //冷却塔系统能效

          ts.list.push(ts.infors.chilledWaterEnergyEfficiency); //冷冻水系统能效

          ts.list.push(ts.infors.mainEngineEnergyEfficiency); //主机能效

          ts.radarEcharts();
          ts.vantChart();
        } else {
          // ts.fullscreenLoading = false;
          // NProgress.done();
          ts.$message('运行错误');
        }
      });
    },
    //xiazai
    getExport: function getExport() {
      var _this = this;

      this.loading1 = true; // 

      (0, _projectInfo.getManualProjectRunDiagnosticDataExport)(this.params).then(function (res) {
        //创建一个隐藏的a链接
        var link = document.createElement("a");
        var blob = new Blob([res], {
          type: "application/vnd.ms-excel"
        }); //文件流处理

        link.style.display = "none"; //去除a标签的样式
        //设置连接

        link.href = URL.createObjectURL(blob);
        link.download = '运行诊断报告.xlsx';
        document.body.appendChild(link); //模拟点击事件

        link.click(); //移除创建的a标签

        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        _this.loading1 = false;
      });
    },
    load: function load() {
      this.count += 2;
    },
    //八角图
    radarEcharts: function radarEcharts() {
      var ts = this;
      var radarEcharts = ts.$echarts.init(document.getElementById("radarEcharts"));
      var option = {
        tooltip: {
          trigger: 'axis',
          borderWidth: 0,
          textStyle: {
            fontSize: 12 // 字体大小

          }
        },
        radar: [{
          indicator: [{
            text: '冷却水系统能效',
            max: 5
          }, {
            text: '机房能效',
            max: 5
          }, {
            text: '冷却塔系统能效',
            max: 5
          }, {
            text: '冷冻水系统能效',
            max: 5
          }, {
            text: '主机能效',
            max: 5
          }],
          // center: ['50%', '50%'],
          // radius: 80,
          splitLine: {
            //配置雷达图的每一圈的网格线颜色
            lineStyle: {
              color: this.echartRadarColor
            }
          },
          // splitArea: {     //配置雷达图的网格线背景
          //     show: true,
          // },
          name: {
            //配置雷达图的每个指示器的名称颜色
            textStyle: {
              color: '#666666'
            }
          },
          axisLine: {
            //配置雷达图的射线样式颜色
            lineStyle: {
              color: '#eeeeee'
            }
          }
        }],
        series: [{
          type: 'radar',
          tooltip: {
            trigger: 'item',
            borderWidth: 0
          },
          areaStyle: {
            color: '#006CFF'
          },
          itemStyle: {
            //此属性的颜色和下面areaStyle属性的颜色都设置成相同色即可实现
            color: '#006CFF',
            borderColor: '#006CFF'
          },
          data: [{
            value: ts.list,
            name: '诊断分析'
          }]
        }]
      };
      radarEcharts.setOption(option);
    },
    //百分比
    vantChart: function vantChart() {
      document.getElementById('mountNode').innerHTML = '';
      var ts = this;
      var data1 = []; // let num = ts.infors.comprehensiveEvaluation;

      var num = ts.infors.comprehensiveEvaluation;
      var color = '#6758f3'; // if(num<60){
      //     color='#6758F3'
      // }
      // if(num>=60 && num<80){
      //     color='#1664d8'
      // }
      // if(num>=80 && num<90){
      //     color='#f7ab2e'
      // }
      // if(num>=90 && num<=100){
      //     color='#34aa44'
      // }

      for (var i = 0; i < 100; i++) {
        var item = {};
        item.type = i + '';
        item.value = 10;
        data1.push(item);
      }

      var data2 = [];

      for (var _i = 0; _i < 100; _i++) {
        var _item = {};
        _item.type = _i + '';
        _item.value = 10;

        if (_i === num) {
          _item.value = 14;
        }

        if (_i > num) {
          _item.value = 0;
        }

        data2.push(_item);
      }

      var chart = new G2.Chart((0, _defineProperty2.default)({
        container: 'mountNode',
        forceFit: true,
        height: window.innerHeight,
        padding: 0,
        width: 80
      }, "height", 240));
      chart.clear();
      chart.scale({
        type: {
          range: [0, 1]
        },
        value: {
          sync: true
        }
      });
      chart.legend(false);
      chart.tooltip(false);
      var view1 = chart.view();
      view1.source(data1);
      view1.axis(false);
      view1.coord('polar', {
        startAngle: -9 / 8 * Math.PI,
        endAngle: 1 / 8 * Math.PI,
        innerRadius: 0.8,
        radius: 0.7
      });
      view1.interval().position('type*value').color("".concat(this.echartfc)).size(2);
      var view2 = chart.view();
      view2.source(data1, {
        type: {
          tickCount: 2
        }
      });
      view2.axis('value', false);
      view2.axis('type', {
        grid: null,
        line: null,
        tickLine: null,
        label: {
          offset: -15,
          textStyle: {
            textAlign: 'center',
            fill: '#868688',
            fontSize: 12
          },
          formatter: function formatter(val) {
            if (val === '99') {
              return 100;
            }

            return val;
          }
        }
      });
      view2.coord('polar', {
        startAngle: -9 / 8 * Math.PI,
        endAngle: 1 / 8 * Math.PI,
        innerRadius: 0.98,
        radius: 0.53
      });
      view2.interval().position('type*value').color(color).size(1);
      var view3 = chart.view();
      view3.source(data2);
      view3.axis(false);
      view3.coord('polar', {
        startAngle: -9 / 8 * Math.PI,
        endAngle: 1 / 8 * Math.PI,
        innerRadius: 0.78,
        radius: 0.72
      });
      view3.interval().position('type*value').color('value', color).opacity(1).size(2);
      view3.guide().text({
        position: ['50%', '65%'],
        content: num ? num : 0 + '分',
        style: {
          fill: color,
          fontSize: 36,
          fontWeight: 400,
          textAlign: 'center',
          textBaseline: 'middle'
        }
      });
      chart.render();
    },
    increase: function increase() {
      var ts = this;
      ts.timeStart = setInterval(function () {
        if (ts.percentage < 95) {
          ts.percentage += 5;
        }

        if (ts.percentage > 100) {
          ts.percentage = 100;
        }
      }, 300);
    },
    increaseend: function increaseend() {
      var ts = this;
      ts.percentage = 100;
      clearInterval(ts.timeStart);
    }
  },
  watch: {
    // 监听loading状态控制进度条显示
    jdt: function jdt(value, newValue) {
      var that = this;
      console.log(value + "----newValue----" + newValue);

      if (value) {
        that.increase();
      } else {
        that.increaseend();
      }
    },
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;

      if (this.colorType == 'theme_jyh') {
        this.echartfc = '#DEE5EF';
        this.echartRadarColor = '#eeeeee';
      } else if (this.colorType == 'theme_ytl') {
        this.echartfc = '#a6c6eb';
        this.echartRadarColor = '#a6c6eb';
      } else {
        this.echartfc = '#DEE5EF';
        this.echartRadarColor = '#eeeeee';
      }

      this.vantChart();
      this.radarEcharts();
    }
  }
};
exports.default = _default;
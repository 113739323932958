"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
var _default = {
  name: 'SvgIcon',
  // 接收父组件传来的参数
  props: {
    iconClass: {
      // 定义接收的值类型
      type: String,
      // 必填项
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    iconName: function iconName(props) {
      return "#icon-".concat(props.iconClass);
    },
    svgClass: function svgClass(props) {
      if (props.iconClass) {
        return "svg-icon ".concat(props.className);
      } else {
        return 'svg-icon';
      }
    }
  }
};
exports.default = _default;
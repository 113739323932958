var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order content-right" },
    [
      _c("div", { staticClass: "body" }, [
        _c(
          "div",
          { staticClass: "filter" },
          [
            _c(
              "el-form",
              {
                staticClass: "filter-inline",
                attrs: { inline: true, model: _vm.filterItem }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "项目名称:" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "项目名称" },
                      model: {
                        value: _vm.filterItem.entryName,
                        callback: function($$v) {
                          _vm.$set(_vm.filterItem, "entryName", $$v)
                        },
                        expression: "filterItem.entryName"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "工单类型:" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "工单类型" },
                        model: {
                          value: _vm.filterItem.workOrderType,
                          callback: function($$v) {
                            _vm.$set(_vm.filterItem, "workOrderType", $$v)
                          },
                          expression: "filterItem.workOrderType"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "设备故障", value: "1" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "其他", value: "2" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "工单状态:" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "工单状态" },
                        model: {
                          value: _vm.filterItem.workOrderStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.filterItem, "workOrderStatus", $$v)
                          },
                          expression: "filterItem.workOrderStatus"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "未处理", value: "0" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "处理中", value: "1" }
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "已关闭", value: "2" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "picker_time", attrs: { label: "提交时间:" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        type: "daterange",
                        align: "right",
                        "unlink-panels": "",
                        "range-separator": "-",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      model: {
                        value: _vm.filterItem.filterTime,
                        callback: function($$v) {
                          _vm.$set(_vm.filterItem, "filterTime", $$v)
                        },
                        expression: "filterItem.filterTime"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.onSubmit }
                      },
                      [_vm._v("\n            查询\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.resetForm }
                      },
                      [_vm._v("\n            重置\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tablebody" },
          [
            _c(
              "div",
              { staticClass: "tablebody-title" },
              [
                _c(
                  "el-menu",
                  {
                    attrs: {
                      "default-active": _vm.activeName,
                      mode: "horizontal"
                    },
                    on: { select: _vm.handleActiveName }
                  },
                  [
                    _c("el-menu-item", { attrs: { index: "submit" } }, [
                      _vm._v("我提交的")
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-menu-item",
                      { attrs: { index: "review" } },
                      [
                        _vm.missOrderCount > 0
                          ? [
                              _c(
                                "el-badge",
                                { attrs: { value: _vm.missOrderCount } },
                                [
                                  _vm._v(
                                    "\n                我处理的\n              "
                                  )
                                ]
                              )
                            ]
                          : [_vm._v("\n              我处理的\n            ")]
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("el-menu-item", { attrs: { index: "send" } }, [
                      _vm._v("抄送我的")
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading"
                  }
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData,
                  "header-cell-style": { "text-align": "center" },
                  "cell-style": { "text-align": "center" },
                  "max-height": 650 + "px",
                  "cell-class-name": _vm.cellStyle
                }
              },
              [
                _vm._l(_vm.column, function(item, key) {
                  return [
                    item.prop != "handlerTypeName"
                      ? _c("el-table-column", {
                          key: key,
                          attrs: {
                            type: item.type,
                            prop: item.prop,
                            label: item.label,
                            width: item.width,
                            "min-width": item.minWidth
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeName == "review" && item.prop == "handlerTypeName"
                      ? _c("el-table-column", {
                          key: key,
                          attrs: {
                            type: item.type,
                            prop: item.prop,
                            label: item.label,
                            width: item.width,
                            "min-width": item.minWidth
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "span",
                                      { staticClass: "handleValueLabel" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.handlerTypeName) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      : _vm._e()
                  ]
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { width: "250", label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _vm.activeName == "submit"
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "showInfo",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleEditInfo(row)
                                    }
                                  }
                                },
                                [_vm._v("\n              编辑\n            ")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticClass: "showInfo",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.handleInfo(row)
                                }
                              }
                            },
                            [_vm._v("\n              查看\n            ")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table-page" },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.filterItem.page,
                    layout: "prev, pager, next, jumper",
                    total: _vm.filterItem.total,
                    "page-size": _vm.filterItem.rows,
                    "page-count": _vm.filterItem.pagecount
                  },
                  on: { "current-change": _vm.handleCurrentPageChange }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "order-el-dialog",
          attrs: { title: "编辑", visible: _vm.editStatus, width: "600px" },
          on: {
            "update:visible": function($event) {
              _vm.editStatus = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "order-edit" },
            [
              _c("div", { staticStyle: { padding: "20px 0" } }, [
                _vm._v("处理内容")
              ]),
              _vm._v(" "),
              _c("el-input", {
                staticStyle: { width: "100%", "font-size": "16px" },
                attrs: {
                  type: "textarea",
                  maxlength: "200",
                  "show-word-limit": "",
                  clearable: "",
                  rows: "5",
                  placeholder: "请输入",
                  resize: "none"
                },
                model: {
                  value: _vm.editForm.detail,
                  callback: function($$v) {
                    _vm.$set(_vm.editForm, "detail", $$v)
                  },
                  expression: "editForm.detail"
                }
              }),
              _vm._v(" "),
              _c("div", { staticStyle: { padding: "20px 0" } }, [
                _vm._v("上传图片(单张不得大于2M)")
              ]),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  ref: "elUpload",
                  staticStyle: { width: "100%" },
                  attrs: {
                    action: " ",
                    "list-type": "picture-card",
                    multiple: "",
                    accept: ".jpg,.jpeg,.png,.JPG,.JPEG",
                    "auto-upload": false,
                    "on-change": _vm.handleUpload,
                    limit: 4
                  }
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.editStatus = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.handleEdit("order")
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "order-el-dialog",
          attrs: { visible: _vm.showDetail, width: "600px" },
          on: {
            "update:visible": function($event) {
              _vm.showDetail = $event
            }
          }
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c(
                "el-breadcrumb",
                {
                  staticClass: "breadcrumb",
                  attrs: { "separator-class": "el-icon-arrow-right" }
                },
                [
                  _c(
                    "el-breadcrumb-item",
                    {
                      nativeOn: {
                        click: function($event) {
                          _vm.detailEditStatus = false
                          _vm.detailEndStatus = false
                          _vm.detailRedeployStatus = false
                          _vm.detailRedeployGoStatus = false
                        }
                      }
                    },
                    [_vm._v("\n          工单详情\n        ")]
                  ),
                  _vm._v(" "),
                  _vm.detailEditStatus
                    ? _c("el-breadcrumb-item", [_vm._v("编辑")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailEndStatus
                    ? _c(
                        "el-breadcrumb-item",
                        {
                          on: {
                            click: function($event) {
                              _vm.detailRedeployGoStatus = false
                            }
                          }
                        },
                        [_vm._v("\n          处理结果\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailRedeployGoStatus
                    ? _c("el-breadcrumb-item", [
                        _vm._v("\n          继续分配\n        ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailRedeployStatus
                    ? _c("el-breadcrumb-item", [
                        _vm._v("\n          工单转让\n        ")
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "order-detail" },
            [
              _vm.showDetail
                ? _c("orderDetails", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.orderDetailsStatus,
                        expression: "!orderDetailsStatus"
                      }
                    ],
                    ref: "orderDetails",
                    attrs: { "work-order-id": _vm.workOrderIds },
                    on: {
                      change: _vm.handleDetail,
                      showEdit: _vm.handleShowEdit,
                      handleLastOrderData: _vm.handleLastOrderData
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.detailEditStatus
                ? [
                    _c(
                      "div",
                      { staticClass: "order-edit" },
                      [
                        _c("div", { staticStyle: { padding: "20px 0" } }, [
                          _vm._v("处理内容")
                        ]),
                        _vm._v(" "),
                        _c("el-input", {
                          staticStyle: { width: "100%", "font-size": "16px" },
                          attrs: {
                            type: "textarea",
                            maxlength: "200",
                            "show-word-limit": "",
                            clearable: "",
                            rows: "5",
                            placeholder: "请输入",
                            resize: "none"
                          },
                          model: {
                            value: _vm.editForm.detail,
                            callback: function($$v) {
                              _vm.$set(_vm.editForm, "detail", $$v)
                            },
                            expression: "editForm.detail"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticStyle: { padding: "20px 0" } }, [
                          _vm._v("上传图片(单张不得大于2M)")
                        ]),
                        _vm._v(" "),
                        _c(
                          "el-upload",
                          {
                            ref: "elUpload",
                            staticStyle: { width: "100%" },
                            attrs: {
                              action: " ",
                              "list-type": "picture-card",
                              multiple: "",
                              accept: ".jpg,.jpeg,.png,.JPG,.JPEG",
                              "auto-upload": false,
                              "on-change": _vm.handleUpload,
                              limit: 4
                            }
                          },
                          [_c("i", { staticClass: "el-icon-plus" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "detail-dialog-footer" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleEdit("detail")
                                  }
                                }
                              },
                              [_vm._v("\n              确 定\n            ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    _vm.detailEditStatus = false
                                  }
                                }
                              },
                              [_vm._v("取 消")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.detailEndStatus || _vm.detailRedeployGoStatus
                ? [
                    _c(
                      "el-form",
                      {
                        ref: "detailRedeployForm",
                        attrs: {
                          "label-width": "120px",
                          "label-position": "top",
                          model: _vm.detailRedeployForm,
                          rules: _vm.detailRedeployFormRules
                        }
                      },
                      [
                        _vm.detailRedeployGoStatus
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "选择处理人",
                                    prop: "handleId"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "90%" },
                                      attrs: { placeholder: "请选择处理人" },
                                      model: {
                                        value: _vm.detailRedeployForm.handleId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.detailRedeployForm,
                                            "handleId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailRedeployForm.handleId"
                                      }
                                    },
                                    _vm._l(_vm.projectUserList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          value: item.id,
                                          label: item.name
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "选择抄送人",
                                    prop: "handleId"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "90%" },
                                      attrs: {
                                        multiple: "",
                                        placeholder: "请选择抄送人"
                                      },
                                      model: {
                                        value:
                                          _vm.detailRedeployForm.sendUserIds,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.detailRedeployForm,
                                            "sendUserIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "detailRedeployForm.sendUserIds"
                                      }
                                    },
                                    _vm._l(_vm.projectSendUserList, function(
                                      item,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          value: item.id,
                                          disabled: item.disabled,
                                          label: item.name
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.detailEndStatus
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "处理内容" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 4,
                                      placeholder: "请输入"
                                    },
                                    model: {
                                      value: _vm.detailRedeployForm.detail,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.detailRedeployForm,
                                          "detail",
                                          $$v
                                        )
                                      },
                                      expression: "detailRedeployForm.detail"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-form-item",
                                { attrs: { label: "上传图片" } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "elUpload",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        action: "",
                                        "list-type": "picture-card",
                                        multiple: "",
                                        accept: ".jpg,.jpeg,.png,.JPG,.JPEG",
                                        "auto-upload": false,
                                        "before-upload": _vm.beforeAvatarUpload,
                                        limit: 10,
                                        "on-exceed": _vm.handleExceed,
                                        "on-change": _vm.imageUpload,
                                        "on-preview":
                                          _vm.handlePictureCardPreview
                                      }
                                    },
                                    [_c("i", { staticClass: "el-icon-plus" })]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dialog",
                                    {
                                      attrs: { visible: _vm.dialogVisibleImg },
                                      on: {
                                        "update:visible": function($event) {
                                          _vm.dialogVisibleImg = $event
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "100%",
                                          src: _vm.dialogVisibleImgUrl,
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.detailRedeployStatus
                ? [
                    _c(
                      "el-form",
                      {
                        ref: "detailRedeployForm",
                        attrs: {
                          "label-width": "120px",
                          "label-position": "top",
                          model: _vm.detailRedeployForm,
                          rules: _vm.detailRedeployFormRules
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "选择处理人", prop: "handleId" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "90%" },
                                attrs: { placeholder: "请选择处理人" },
                                model: {
                                  value: _vm.detailRedeployForm.handleId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.detailRedeployForm,
                                      "handleId",
                                      $$v
                                    )
                                  },
                                  expression: "detailRedeployForm.handleId"
                                }
                              },
                              _vm._l(_vm.projectUserList, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { value: item.id, label: item.name }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "选择抄送人", prop: "sendUserIds" }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "90%" },
                                attrs: {
                                  multiple: "",
                                  placeholder: "请选择抄送人"
                                },
                                model: {
                                  value: _vm.detailRedeployForm.sendUserIds,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.detailRedeployForm,
                                      "sendUserIds",
                                      $$v
                                    )
                                  },
                                  expression: "detailRedeployForm.sendUserIds"
                                }
                              },
                              _vm._l(_vm.projectSendUserList, function(
                                item,
                                index
                              ) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    value: item.id,
                                    disabled: item.disabled,
                                    label: item.name
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "描述", prop: "detail" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 4,
                                placeholder: "请输入内容"
                              },
                              model: {
                                value: _vm.detailRedeployForm.detail,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.detailRedeployForm,
                                    "detail",
                                    $$v
                                  )
                                },
                                expression: "detailRedeployForm.detail"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          !_vm.detailEditStatus &&
          _vm.lastOrderData.handlerId == _vm.userId &&
          _vm.lastOrderData.workOrderStatus != 3 &&
          _vm.checkOrder.workOrderStatus != 2
            ? [
                _c(
                  "span",
                  {
                    staticClass: "orderAciton",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    !_vm.detailRedeployStatus &&
                    !_vm.detailEndStatus &&
                    !_vm.detailRedeployGoStatus
                      ? [
                          _vm.lastOrderData.handlerType == 1
                            ? _c("div", { on: { click: _vm.handleOrderAdd } }, [
                                _vm._v("\n            接单\n          ")
                              ])
                            : _c(
                                "div",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.detailEndStatus = true
                                      _vm.getProjectUserList()
                                    }
                                  }
                                },
                                [_vm._v("\n            处理完成\n          ")]
                              ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  _vm.detailRedeployStatus = true
                                  _vm.getProjectUserList()
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            处理不了，分配给别人\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { on: { click: _vm.handleClose } }, [
                            _vm._v("无需处理，直接关闭")
                          ]),
                          _vm._v("\n          .\n        ")
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detailRedeployStatus
                      ? [
                          _c(
                            "div",
                            { on: { click: _vm.handleDetailRedeploy } },
                            [_vm._v("确认转让")]
                          )
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detailEndStatus
                      ? [
                          _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  _vm.detailRedeployGoStatus = true
                                  _vm.detailEndStatus = false
                                }
                              }
                            },
                            [_vm._v("\n            分配给下一个人\n          ")]
                          ),
                          _vm._v(" "),
                          _c("div", { on: { click: _vm.handleDetailClose } }, [
                            _vm._v("处理完成,关闭工单")
                          ])
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.detailRedeployGoStatus
                      ? [
                          _c(
                            "div",
                            { on: { click: _vm.handleDetailRedeployGo } },
                            [_vm._v("确认分配")]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _orderDetails = require("@/api/orderDetails");

var _auth = require("@/utils/auth");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default = {
  components: {},
  props: ["workOrderId"],
  data: function data() {
    return {
      showMain: true,
      showResult: false,
      showContinue: false,
      showtransfer: false,
      orderInfo: {},
      detailInfo: [],
      lastOrderData: {},
      dict: {
        // 工单状态 0未处理 1处理中 2关闭
        workOrderStatus: {
          0: "未处理",
          1: "处理中",
          2: "已关闭"
        },
        // 工单类型 1：设备故障 2其他
        workOrderType: {
          1: "设备故障",
          2: "其他"
        },
        //  1待接单 2已接单 3处理完成
        handlerType: {
          1: "待接单",
          2: "已接单",
          3: "处理完成"
        }
      }
    };
  },
  computed: {
    userId: function userId() {
      return (0, _auth.getUserId)();
    }
  },
  watch: {
    workOrderId: {
      handler: function handler() {
        if (this.workOrderId) {
          this.init();
        }
      },
      immediate: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    init: function init() {
      this.getInfoData();
      this.getDealData();
    },
    // 字典
    handleDict: function handleDict(value, key) {
      return this.dict[key][value];
    },
    // 显示图片
    handleImageList: function handleImageList(list, key) {
      if (!list) return [];
      return list.map(function (item) {
        return item[key];
      });
    },
    // 返回
    handleBack: function handleBack() {
      this.$emit("handleBack");
    },
    // 获取工单信息
    getInfoData: function getInfoData() {
      var _this = this;

      (0, _orderDetails.getOrderInfo)({
        workOrderId: this.workOrderId
      }).then(function (res) {
        _this.orderInfo = res;
      });
    },
    // 获取工单处理信息
    getDealData: function getDealData() {
      var _this2 = this;

      (0, _orderDetails.getOrderHandleInfo)({
        workOrderId: this.workOrderId
      }).then(function (res) {
        _this2.detailInfo = res;
        _this2.lastOrderData = _objectSpread({}, res[res.length - 1]);

        _this2.$emit("handleLastOrderData", _this2.lastOrderData);
      });
    },
    // 编辑工单
    handleEdit: function handleEdit(detail) {
      this.$emit("showEdit", detail);
    },
    // 处理结果
    handleResult: function handleResult() {
      this.showMain = false;
      this.showResult = true;
    },
    handleToResult: function handleToResult() {},
    handleToContinue: function handleToContinue() {},
    handleToTransfer: function handleToTransfer() {},
    handleToModify: function handleToModify() {}
  }
};
exports.default = _default;
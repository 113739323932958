"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    id: {
      type: String,
      default: "chart1"
    },
    name: {
      type: String,
      default: ""
    },
    nameTip: {
      type: String,
      default: ""
    },
    showTip: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      color: "",
      colors: ["#3e89e7", "#fb8c3b", "#63be65", "#d9c32b"],
      colorType: '',
      echartfc: '',
      // 图表颜色
      zoom: '',
      zoomrAtio: ''
    };
  },
  watch: {
    data: function data() {
      //console.log("发生的方式", this.data);
      this.getLineChart();
    },
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;

      if (this.colorType == 'theme_jyh') {
        this.echartfc = '#fff';
      } else if (this.colorType == 'theme_ytl') {
        this.echartfc = '#333';
      } else {
        this.echartfc = '#333';
      }

      this.getLineChart();
    }
  },
  created: function created() {
    this.colorType = this.$store.state.project.colorType;

    if (this.colorType == 'theme_jyh') {
      this.echartfc = '#fff';
    } else if (this.colorType == 'theme_ytl') {
      this.echartfc = '#333';
    } else {
      this.echartfc = '#333';
    }
  },
  mounted: function mounted() {},
  methods: {
    getColor: function getColor() {
      if (this.colorType == 'theme_jyh') {
        return {
          color: '#fff'
        };
      } else if (this.colorType == 'theme_ytl') {
        return {
          color: '#333'
        };
      } else {
        return {
          color: '#333'
        };
      }
    },
    getLineChart: function getLineChart() {
      var _this = this;

      var lineChart = this.$echarts.init(document.getElementById(this.id));
      var seriesData = [];
      lineChart.clear();

      if (this.name === "关键参数") {
        this.colors = ["#3e89e7", "#fb8c3b", "#63be65", "#d9c32b"];
      } else {
        this.colors = ["#3e89e7", "#fb8c3b"];
      }

      this.data.map(function (el, i) {
        // if (el.fieldMeaning === '机房COP' || el.fieldMeaning === '主机系统COP' || el.fieldMeaning === '冷冻水总管供水温度') {
        seriesData.push({
          type: "line",
          name: el.fieldMeaning,
          symbol: "circle",
          showSymbol: false,
          data: el.data,
          dimensions: [el.company],
          smooth: true,
          itemStyle: {
            lineStyle: {
              color: _this.colors[i]
            }
          }
        }); // }
      });
      var option = {
        tooltip: {
          confine: true,
          trigger: "axis",
          backgroundColor: "rgba(255,255,255,1)",
          extraCssText: "box-shadow:0 0 18px rgba(0,120,234,0.1);color:#000000",
          textStyle: {
            fontSize: 14,
            lineHeight: 30,
            color: this.echartfc
          },
          padding: 15,
          formatter: function formatter(params) {
            var str = "";
            str = params[0].name;
            params.map(function (el) {
              str = str + "<br/><span class=\"color-circle\" style=\"background:".concat(el.color, ";\"></span> ").concat(el.seriesName, " :&nbsp;&nbsp;").concat(el.value[1], "  ").concat(el.dimensionNames[0] === "x" ? "" : el.dimensionNames[0]);
            });
            return str;
          }
        },
        color: this.colors,
        legend: {
          icon: "circle",
          textStyle: {
            // label: { show: true },
            color: this.echartfc
          }
        },
        grid: {
          left: "3%",
          right: "7%",
          bottom: "8%",
          top: "6%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          name: "(h)",
          splitLine: {
            show: false
          },
          // 去除网格线
          axisTick: {
            show: false
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#cfcfcf",
              width: 1,
              type: "solid"
            }
          },
          boundaryGap: false,
          axisLabel: {
            show: true,
            interval: "auto",
            formatter: function formatter(params) {
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 10; // 一行显示几个字

              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);

              if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  var start = p * provideNumber;
                  var end = start + provideNumber;

                  if (p === rowNumber - 1) {
                    tempStr = params.substring(start, paramsNameNumber);
                  } else {
                    tempStr = params.substring(start, end) + "\n";
                  }

                  newParamsName += tempStr;
                }
              } else {
                newParamsName = params;
              }

              return newParamsName;
            },
            textStyle: {
              color: "#999999" // 文字颜色

            }
          } //   data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']

        },
        yAxis: {
          type: "value",
          color: "red",
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          // axisLine: { // y轴
          //   show: false
          // }
          axisLine: {
            show: true,
            lineStyle: {
              color: "#cfcfcf",
              width: 1,
              type: "solid"
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999999" // 文字颜色

            }
          }
        },
        label: {
          // show: true,
          color: this.echartfc
        },
        series: seriesData
      };
      lineChart.setOption(option);
      this.$nextTick(function () {
        _this.zoom = document.body.style.zoom;
        _this.zoomrAtio = (1 / document.body.style.zoom).toFixed(2);
      });
    }
  }
};
exports.default = _default2;
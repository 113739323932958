"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setRem = setRem;

function setRem() {
  // 2560*1440 2k
  // 2560*1600 2k
  // 3200*1800 3k
  // 3840*2160 4k
  // 3840/1920 = 2
  var max = window.innerWidth;

  if (max >= 3840) {
    document.body.style.zoom = 2;
  } else if (max >= 2600 && max < 3840) {
    document.body.style.zoom = 1.65;
  } else if (max >= 2400 && max < 2600) {
    document.body.style.zoom = 1.36;
  } else if (max >= 1900 && max < 2400) {
    document.body.style.zoom = 1;
  } else if (max >= 1500 && max < 1900) {
    document.body.style.zoom = (max / 1920).toFixed(2);
  } else if (max >= 1200 && max < 1500) {
    document.body.style.zoom = (max / 1880).toFixed(2);
  } else if (max >= 800 && max < 1200) {
    document.body.style.zoom = (max / 1600).toFixed(2);
  } else if (max >= 500 && max < 800) {
    document.body.style.zoom = (max / 800).toFixed(2);
  } else {
    document.body.style.zoom = (max / 450).toFixed(2);
  }
} // class DevicePixelRatio {
// 	//获取系统类型
// 	_getSystem() {
// 		let flag = false;
// 		var agent = navigator.userAgent.toLowerCase();
// 		//现只针对windows处理，其它系统暂无该情况，如有，继续在此添加
// 		if(agent.indexOf("windows") >= 0) {
// 			return true;
// 		}
// 	}
// 	//监听方法兼容写法
// 	_addHandler(element, type, handler) {
// 		if(element.addEventListener) {
// 			element.addEventListener(type, handler, false);
// 		} else if(element.attachEvent) {
// 			element.attachEvent("on" + type, handler);
// 		} else {
// 			element["on" + type] = handler;
// 		}
// 	}
// 	//校正浏览器缩放比例
// 	_correct() {
// 		let t = this;
// 		const Width = window.innerWidth;
// 		if(Width >= 1900) {
// 			setRem();
// 		} else {
// 			//页面devicePixelRatio（设备像素比例）变化后，计算页面body标签zoom修改其大小，来抵消devicePixelRatio带来的变化。
// 			document.getElementsByTagName('body')[0].style.zoom = 1 / window.devicePixelRatio;
// 		}
// 	}
// 	//监听页面缩放
// 	_watch() {
// 		let t = this;
// 		t._addHandler(window, 'resize', function() { //注意这个方法是解决全局有两个window.resize
// 			//重新校正
// 			t._correct()
// 		})
// 	}
// 	//初始化页面比例
// 	init() {
// 		let t = this;
// 		if(t._getSystem()) { //判断设备，目前只在windows系统下校正浏览器缩放比例
// 			//初始化页面校正浏览器缩放比例
// 			t._correct();
// 			//开启监听页面缩放
// 			t._watch();
// 		}
// 	}
// }
// export default DevicePixelRatio;
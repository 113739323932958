"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _theme = _interopRequireDefault(require("./compontents/theme"));

var _energy = _interopRequireDefault(require("./compontents/energy"));

var _chart = _interopRequireDefault(require("./compontents/chart"));

var _systemParams = _interopRequireDefault(require("./compontents/systemParams"));

var _facilityParams = _interopRequireDefault(require("./compontents/facilityParams"));

var _pie = _interopRequireDefault(require("./compontents/pie"));

var _home = require("@/api/home");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "BuildOverview",
  components: {
    theme: _theme.default,
    energy: _energy.default,
    chart: _chart.default,
    system: _systemParams.default,
    facility: _facilityParams.default,
    pie: _pie.default
  },
  props: {},
  data: function data() {
    return {
      wsIp: process.env.VUE_APP_BASE_API,
      homeData: [],
      projectInfo: {},
      pathData: [],
      notification: {},
      timer: null,
      // 初始定时器变量名为null
      page: 1
    };
  },
  computed: {
    // type() {
    //   return this.$store.state.project.type
    // },
    isCold: function isCold() {
      return this.$store.state.project.isCold;
    }
  },
  watch: {
    isCold: function isCold(val) {
      this.getProjectListData();
      this.initWebSocket();
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$store.dispatch("project/setProjectCold", null);

    if (this.ws) {
      this.ws.close();
    }

    ; // js提供的clearInterval方法用来清除定时器

    clearInterval(this.timer);
    this.timer = null; // 这里最好清除一下，回归默认值
    // 众所周知，定时器返回一个随机整数，用于表示定时器的编号，后面通过名字可以取消这个定时器的执行。
    //console.log(this.timer);
  },
  created: function created() {
    this.getProjectListData();
    this.$store.dispatch("project/setProjectCold", 1); // 检测页面访问权限

    var arr = JSON.parse(window.sessionStorage.getItem("menuAuths")) || [];
    this.pathData = arr.map(function (e) {
      return e.path;
    });

    if (this.pathData.indexOf("home-page") !== 0) {
      this.$router.push({
        path: "/403"
      });
      return;
    }

    this.getUntreated(); // this.batchAlarmState();

    this.start();
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.setCanvas();
    });
  },
  methods: {
    // 设置canvas偏移位置
    setCanvas: function setCanvas() {
      var canvases = document.querySelectorAll('canvas');
      var zoom = document.body.style.zoom;
      var zoomrAtio = (1 / zoom).toFixed(2);
      var style = {
        zoom: zoomrAtio,
        transform: "scale(".concat(zoom, ")"),
        transformOrigin: '0px 0px'
      };

      for (var i = 0; i < canvases.length; i++) {
        canvases[i].style = style;
      }
    },
    start: function start() {
      var _this2 = this;

      // 将定时器名字赋值到变量中
      this.timer = setInterval(function () {
        _this2.notification = {
          rows: [],
          total: ''
        }; // 每重新都清空原来数据

        _this2.page = 1; // 每次默认第一页查询

        _this2.getUntreated();
      }, 30000);
    },
    // 下拉滑动出发
    getNextDataList: function getNextDataList(page) {
      this.page = page;

      if (page == 1) {
        this.notification = {
          rows: [],
          total: ''
        };
      }

      this.getUntreated();
    },
    getUntreated: function getUntreated() {
      var _this3 = this;

      var par = {
        page: this.page,
        //	页码	query	true	
        rows: 500,
        //	行数	query	true	
        alarmType: '',
        //	告警类型（告警等级）：1提醒2预警3告警	query	false	
        endDate: '',
        //	结束始时间	query	false	
        everconfirmed: '',
        //	是否确认 0.未确认 1.已确认 默认0	query	false	
        projectId: this.$store.state.project.projectInfo.id,
        //	项目id	query	false	
        startDate: '' //	开始时间

      };
      (0, _home.getUntreatedProjectAlarm)(par).then(function (res) {
        if (_this3.notification.rows && _this3.notification.rows.length > 0) {
          _this3.notification.rows = _this3.notification.rows.concat(res.rows); // 下拉加载
        } else {
          _this3.notification = res;
        }
      });
    },
    getProjectListData: function getProjectListData() {
      var _this4 = this;

      (0, _home.getProjectList)({
        projectId: this.$store.state.project.projectInfo.id,
        type: this.isCold || 1
      }).then(function (res) {
        _this4.homeData = res; //console.log("home data");
      });
    },
    initWebSocket: function initWebSocket() {
      var _this5 = this;

      if (this.ws) {
        this.ws.close();
      }

      if ("WebSocket" in window) {
        var i = this.wsIp.indexOf("//");
        var url = this.wsIp.slice(i);
        this.ws = new WebSocket("".concat(process.env.VUE_APP_WSS_STATUS == "true" ? "wss" : "ws", ":").concat(url, "/websocketServer/energyHomePageData/").concat(this.$store.state.project.projectInfo.id, "/").concat(this.isCold));

        this.ws.onopen = function () {};

        this.ws.onmessage = function (message) {
          _this5.homeData = JSON.parse(message.data);
        };
      }
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  data: function data() {
    return {
      currentIndex: 5,
      tablist: [{
        name: '主页',
        value: 0,
        path: '/home-page'
      }, {
        name: '详情',
        value: 1,
        path: '/system-details'
      }, {
        name: '数据',
        value: 3,
        path: '/data-query'
      }, {
        name: '报表',
        value: 4,
        path: '/report-download'
      }, {
        name: '运维管理',
        value: 6,
        path: '/operation'
      }]
    };
  },
  computed: {},
  watch: {},
  //方法集合
  methods: {
    go: function go(index) {
      this.currentIndex = index;

      if (index == 0) {
        this.$router.push({
          path: this.tablist[this.currentIndex].path,
          query: {
            id: index
          }
        });
      } else {
        this.$router.push(this.tablist[this.currentIndex].path);
      }

      sessionStorage.setItem("routerIndex", this.tablist[this.currentIndex].value);
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDayReport = getDayReport;
exports.getDayReportList = getDayReportList;
exports.getMonthReport = getMonthReport;
exports.getYearReportList = getYearReportList;
exports.getYearReportZip = getYearReportZip;
exports.getMonthWordYearList = getMonthWordYearList;
exports.getDayWordPreview = getDayWordPreview;
exports.getCoolDayWordPreview = getCoolDayWordPreview;
exports.getMonthWordPreview = getMonthWordPreview;
exports.monthProjectReportPreviewData = monthProjectReportPreviewData;
exports.getCoolMonthWordPreview = getCoolMonthWordPreview;
exports.getMonthColdStorageReportByDateSelect = getMonthColdStorageReportByDateSelect;
exports.getDayChartData = getDayChartData;
exports.getDayProjectCoolingReportRegenerate = getDayProjectCoolingReportRegenerate;
exports.getDayProjectCoolStorageReportRegenerate = getDayProjectCoolStorageReportRegenerate;
exports.getMonthProjectCoolingReportRegenerate = getMonthProjectCoolingReportRegenerate;
exports.getMonthProjectCoolStorageReportRegenerate = getMonthProjectCoolStorageReportRegenerate;
exports.getProjectDayReportByProjectIdOrGenerationListDate = getProjectDayReportByProjectIdOrGenerationListDate;
exports.getCoolNhglAndNxgl = getCoolNhglAndNxgl;
exports.getFpg = getFpg;
exports.getGlsc = getGlsc;
exports.getDayCop = getDayCop;
exports.getDayColdPrice = getDayColdPrice;
exports.getNxgl = getNxgl;
exports.getMonthNxgl = getMonthNxgl;
exports.getNhzb = getNhzb;
exports.getNhgl = getNhgl;
exports.getNdbg = getNdbg;
exports.getCoolYearReport = getCoolYearReport;
exports.getGl = getGl;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _request = _interopRequireDefault(require("@/utils/request"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function getDayReport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/downloadDayWordReportZip",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getDayReportList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectDayWord/projectDayWordMonthList",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getMonthReport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/downloadMonthWordReportZip",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getYearReportList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectYearWord/projectYearWordByProjectId",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getYearReportZip(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/downloadYearWordReportZip",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getMonthWordYearList(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectMonthWord/projectMonthWordYearList",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 日报表预览——常规、蓄冷


function getDayWordPreview(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/dayProjectReportData",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getCoolDayWordPreview(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolStorageProjectReport/generateColdStorageDayReport",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 月报表预览——常规、蓄冷


function getMonthWordPreview(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/monthProjectReportData",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function monthProjectReportPreviewData(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/monthProjectReportPreviewData",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}

function getCoolMonthWordPreview(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolStorageProjectReport/monthProjectReportData",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 蓄冷月报表


function getMonthColdStorageReportByDateSelect(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolStorageProjectReport/getMonthColdStorageReportByDateSelect",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 常规获取日报表补充数据（三期二批新增）


function getDayChartData(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReport/regularDailyReportSupplementByProjectIdOrDayReportDate",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 常规指定项目，日期生成常规日报


function getDayProjectCoolingReportRegenerate(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReportRegenerate/dayProjectCoolingReportRegenerate",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 根据指定蓄冷项目，日期重新生成日报表


function getDayProjectCoolStorageReportRegenerate(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReportRegenerate/dayProjectCoolStorageReportRegenerate",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 根据指定常规项目，日期重新生成月报表


function getMonthProjectCoolingReportRegenerate(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReportRegenerate/monthProjectCoolingReportRegenerate",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 根据指定蓄冷项目，日期重新生成月报表


function getMonthProjectCoolStorageReportRegenerate(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/projectReportRegenerate/monthProjectCoolStorageReportRegenerate",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 获取时间内天的供冷量、耗电量、日电费


function getProjectDayReportByProjectIdOrGenerationListDate(params) {
  return (0, _request.default)({
    url: "/ProjectMonthReport/getFirstChart",
    method: "get",
    params: _objectSpread({}, params)
  });
} // 获取蓄冷系统月报表 能耗概览 的供冷量、耗电量、日电费


function getCoolNhglAndNxgl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolMonthReport/getCoolNhglAndNxgl",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} // 获取时间内天的峰平谷


function getFpg(params) {
  return (0, _request.default)({
    url: "/projectReport/getFpg",
    method: "get",
    params: _objectSpread({}, params)
  });
} // 根据条件查询日开机时长数据(供冷时长)


function getGlsc(params) {
  return (0, _request.default)({
    url: "/projectBootTime/getGlsc",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

function getDayCop(params) {
  return (0, _request.default)({
    url: "/projectCopOrColdPrice/getDayCop",
    method: 'get',
    params: _objectSpread({}, params)
  });
}

function getDayColdPrice(params) {
  return (0, _request.default)({
    url: "/projectCopOrColdPrice/getDayColdPrice",
    method: 'get',
    params: _objectSpread({}, params)
  });
} //能效概览


function getNxgl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getNxgl",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //能效概览 月


function getMonthNxgl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getMonthNxgl",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //能耗占比


function getNhzb(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getNhzb",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //能耗概览


function getNhgl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getNhgl",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //年度报告


function getNdbg(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getNdbg",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //蓄冷年度报告


function getCoolYearReport(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/coolMonthReport/getCoolYearReport",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
} //供冷时长


function getGl(params) {
  var encryptType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return (0, _request.default)({
    url: "/annualStatement/getGlsc",
    method: "get",
    params: _objectSpread({}, params, {
      encryptType: encryptType
    })
  });
}
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _indexCool = _interopRequireDefault(require("@/components/head/index-cool"));

var _collect = _interopRequireDefault(require("./components-cool/collect"));

var _historical = _interopRequireDefault(require("./components-cool/historical"));

var _energy = _interopRequireDefault(require("./components-cool/energy"));

var _statistics = _interopRequireDefault(require("./components-cool/statistics"));

var _indexCool2 = _interopRequireDefault(require("../data-analyse/index-cool.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import pie from "./components/pie";
var _default = {
  components: {
    headr: _indexCool.default,
    collect: _collect.default,
    historical: _historical.default,
    statistics: _statistics.default,
    energy: _energy.default,
    analyse: _indexCool2.default
  },
  data: function data() {
    return {
      activeName: "first",
      firstInit: true,
      menusList: []
    };
  },
  destroyed: function destroyed() {
    sessionStorage.removeItem("firstInit");
  },
  created: function created() {
    // 初始化重置样式
    if (!sessionStorage.getItem("firstInit")) {
      //this.$router.go(0);
      sessionStorage.setItem("firstInit", true);
    } // 获取动态菜单


    var menus = JSON.parse(sessionStorage.getItem('umenuauthsice'));
    var list = [];

    if (menus.length > 0) {
      menus.forEach(function (item) {
        if (item.path == 'data-query-cool') {
          item.children.forEach(function (ite) {
            if (ite.type === 0) {
              list.push(ite);
            }
          });
        }
      });
      this.menusList = list.sort(this.sortBy('priority'));
      this.activeName = 'tag' + this.menusList[0].id;
    }
  },
  methods: {
    handleClick: function handleClick() {},
    // 排序
    sortBy: function sortBy(field) {
      //根据传过来的字段进行排序
      return function (x, y) {
        return x[field] - y[field];
      };
    }
  }
};
exports.default = _default;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chart-box" }, [
    _c(
      "div",
      { staticClass: "title" },
      [
        _vm.showTip
          ? _c(
              "el-popover",
              {
                attrs: {
                  placement: "top-start",
                  title: "",
                  width: "300",
                  trigger: "click",
                  content: ""
                }
              },
              [
                _c("div", [
                  _vm._v("\n        " + _vm._s(_vm.nameTip) + "\n      ")
                ]),
                _vm._v(" "),
                _c("div", { attrs: { slot: "reference" }, slot: "reference" }, [
                  _c("span", { style: _vm.getColor() }, [
                    _vm._v(_vm._s(_vm.name))
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-info",
                    staticStyle: { color: "#1665D8", "margin-left": "5px" }
                  })
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.showTip ? _c("div", [_vm._v(_vm._s(_vm.name))]) : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "line-chart" }, [
      _c("div", {
        style:
          "position: absolute;width: 100%;height: 100%;zoom:" +
          _vm.zoomrAtio +
          ";transform:scale(" +
          _vm.zoom +
          ");transform-origin:0px 0px;",
        attrs: { id: _vm.id }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conent" }, [
    _c("div", { staticClass: "bg" }, [
      _c("img", { attrs: { src: _vm.computedImageUrl, alt: "" } })
    ]),
    _vm._v(" "),
    _c(
      "head",
      { staticClass: "top" },
      [
        _c("today", { staticClass: "time" }),
        _vm._v(" "),
        _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("temperature", {
          staticClass: "heat",
          attrs: { "heat-data": _vm.homeData[0] }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("main", { staticClass: "box" }, [
      _c("div", { staticClass: "main_l" }, [
        _c(
          "div",
          { staticClass: "item-box" },
          [
            _c("big-border"),
            _vm._v(" "),
            _c("theme", { attrs: { "theme-data": _vm.homeData[1] } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-box" },
          [
            _c("big-border"),
            _vm._v(" "),
            _c("system", {
              attrs: {
                "system-data": _vm.homeData[3],
                computer: _vm.homeData[6]
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-box" },
          [_c("big-border"), _vm._v(" "), _c("alarm")],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "main_c" }, [
        _c("div", { staticClass: "item-box" }, [_c("tabs")], 1)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "main_r" }, [
        _c(
          "div",
          { staticClass: "item-box" },
          [
            _c("big-border"),
            _vm._v(" "),
            _c("chart", { attrs: { "chart-data": _vm.homeData[2] } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-box" },
          [
            _c("big-border"),
            _vm._v(" "),
            _c("pie", { attrs: { "pie-data": _vm.homeData[5] } })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "item-box" },
          [
            _c("big-border"),
            _vm._v(" "),
            _c("facility", { attrs: { "facility-data": _vm.homeData[4] } })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _theme = _interopRequireDefault(require("./compontents-cool/theme"));

var _energy = _interopRequireDefault(require("./compontents-cool/energy"));

var _chart = _interopRequireDefault(require("./compontents-cool/chart"));

var _systemParams = _interopRequireDefault(require("./compontents-cool/systemParams"));

var _facilityParams = _interopRequireDefault(require("./compontents-cool/facilityParams"));

var _pie = _interopRequireDefault(require("./compontents-cool/pie"));

var _home = require("@/api/home");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "BuildOverview",
  components: {
    theme: _theme.default,
    energy: _energy.default,
    chart: _chart.default,
    system: _systemParams.default,
    facility: _facilityParams.default,
    pie: _pie.default
  },
  props: {},
  data: function data() {
    return {
      wsIp: process.env.VUE_APP_BASE_API,
      homeData: [],
      projectInfo: {},
      pathData: []
    };
  },
  computed: {
    isCold: function isCold() {
      return this.$store.state.project.isCold;
    }
  },
  watch: {
    isCold: function isCold(val) {
      this.getProjectListData();
      this.initWebSocket();
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$store.dispatch("project/setProjectCold", null);

    if (this.ws) {
      this.ws.close();
    }
  },
  created: function created() {
    this.getProjectListData();
    this.$store.dispatch("project/setProjectCold", 2); // 检测页面访问权限

    var arr = JSON.parse(window.sessionStorage.getItem("menuAuthsice")) || [];
    this.pathData = arr.map(function (e) {
      return e.path;
    });

    if (this.pathData.indexOf("home-page-cool") !== 0) {
      this.$router.push({
        path: "/403"
      });
      return;
    }
  },
  methods: {
    // 获取首页数据
    getProjectListData: function getProjectListData() {
      var _this = this;

      (0, _home.getProjectCoolList)({
        projectId: this.$store.state.project.projectInfo.id,
        type: this.isCold || 2
      }).then(function (res) {
        _this.homeData = res;
      });
    },
    // 初始化websocket
    initWebSocket: function initWebSocket() {
      var _this2 = this;

      if (this.ws) {
        this.ws.close();
      }

      if ("WebSocket" in window) {
        var i = this.wsIp.indexOf("//");
        var url = this.wsIp.slice(i);
        this.ws = new WebSocket("".concat(process.env.VUE_APP_WSS_STATUS == "true" ? "wss" : "ws", ":").concat(url, "/websocketServer/homePageDataRealCoolStorage/").concat(this.$store.state.project.projectInfo.id, "/").concat(this.isCold));

        this.ws.onopen = function () {};

        this.ws.onmessage = function (message) {
          _this2.homeData = JSON.parse(message.data);
        };
      }
    }
  }
};
exports.default = _default;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home-box" }, [
    _c("div", { staticClass: "header-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("机房管理系统")]),
      _vm._v(" "),
      _c("div", { staticClass: "right-title" }, [
        _vm.userInfo.roleIdentity != 1001 && _vm.orderPermissions
          ? _c(
              "div",
              {
                staticClass: "name work_tip",
                on: { click: _vm.goToOrderMananger }
              },
              [
                _vm.orderCount
                  ? _c("div", { staticClass: "questionCountAll" }, [
                      _vm._v(_vm._s(_vm.orderCount))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "工单管理",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "imgBox",
                      attrs: {
                        src: require("../../assets/svg/workorder.svg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.userInfo.backStage == 0
          ? _c(
              "div",
              { staticClass: "name", on: { click: _vm.goToMananger } },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "后台管理",
                      placement: "bottom"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "imgBox",
                      attrs: {
                        src: require("../../assets/svg/icon6.svg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "ht-box",
            staticStyle: {
              padding: "0 10px",
              color: "rgb(22, 101, 216)",
              "font-size": "16px",
              "font-weight": "500"
            },
            on: { click: _vm.handOut }
          },
          [
            _vm._v("\n        " + _vm._s(_vm.userInfo.username) + "\n        "),
            _c("img", {
              staticClass: "imgBox",
              attrs: { src: require("../../assets/svg/top03.svg"), alt: "" }
            })
          ]
        ),
        _vm._v(" "),
        _vm.pathData.indexOf("feedback-list") !== -1
          ? _c(
              "div",
              {
                staticStyle: { position: "relative", cursor: "pointer" },
                on: { click: _vm.goToManangerPath }
              },
              [
                _vm.questionCount
                  ? _c("div", { staticClass: "questionCount" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.questionCount) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content-box" }, [
      _c(
        "div",
        { staticClass: "project-list" },
        _vm._l(_vm.projectList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "project-item",
              on: {
                click: function($event) {
                  return _vm.handleGoToProject(item)
                }
              }
            },
            [
              _c("div", { staticClass: "img-box" }, [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: item.projectPicture, alt: "" }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "group-box" }, [
                  _vm._v(
                    "\n            " + _vm._s(item.groupName) + "\n          "
                  )
                ]),
                _vm._v(" "),
                item.projectType == 1
                  ? _c("div", { staticClass: "group_ice" }, [
                      _c("img", {
                        staticClass: "ice",
                        attrs: {
                          src: require("../../assets/snowFlakeLogo.png"),
                          alt: ""
                        }
                      })
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(item.entryName))
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content-right" }, [
    _c("div", { staticClass: "content-box" }, [
      _c(
        "div",
        { staticClass: "item-box border-box" },
        [
          _c("theme", {
            attrs: {
              "theme-data": _vm.homeData[0],
              notification: _vm.notification
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-box border-box" },
        [_c("energy", { attrs: { "energy-data": _vm.homeData[1] } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-box" },
        [_c("chart", { attrs: { "chart-data": _vm.homeData[2] } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-box border-box" },
        [
          _c("system", {
            attrs: { "system-data": _vm.homeData[3], computer: _vm.homeData[6] }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-box  border-box" },
        [_c("facility", { attrs: { "facility-data": _vm.homeData[4] } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item-box" },
        [_c("pie", { attrs: { "pie-data": _vm.homeData[5] } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
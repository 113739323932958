"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _Header = _interopRequireDefault(require("@/components/Header"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "defaultLayout",
  components: {
    Header: _Header.default
  },
  computed: {
    key: function key() {
      return this.$route.fullPath;
    }
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    if (localStorage.getItem('skin')) {
      document.body.className = localStorage.getItem('skin');
    }
  },
  created: function created() {},
  watch: {}
};
exports.default = _default;
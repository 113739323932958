"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _auth = require("@/utils/auth");

var _user = require("@/api/user");

var _home = require("@/api/home");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      routerIndex: 0,
      fullscreen: false,
      pathData: [],
      userInfo: {},
      logoImg: ''
    };
  },
  computed: {
    orderPermissions: function orderPermissions() {
      var projectInfo = this.$store.state.project.projectInfo;
      var list = this.$store.state.user.projectOrderPermissions;
      var status = false;
      list.forEach(function (item) {
        if (item.projectId == projectInfo.id && item.workOrderIdent == 1) {
          status = true;
        }
      });
      return status;
    }
  },
  watch: {
    // 监听当前路由如果是首页则定义routerIndex=0
    $route: {
      handler: function handler(newV, oldV) {
        if (newV.fullPath == '/home-page-cool') {
          this.routerIndex = 0;
        }
      },
      deep: true
    }
  },
  created: function created() {
    var _this = this;

    this.getLogoImgData(); // 获取routerIndex

    window.addEventListener("setItem", function () {
      _this.routerIndex = sessionStorage.getItem("watchStorage");
      sessionStorage.setItem("routerIndex", _this.routerIndex);
    });

    if (sessionStorage.getItem("routerIndex")) {
      this.routerIndex = sessionStorage.getItem("routerIndex");
    }

    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } // 在home页面中已经存储了，这里直接获取


    var arr = JSON.parse(window.sessionStorage.getItem("menuAuthsice")) || [];
    this.pathData = arr.map(function (e) {
      return e.path;
    }); // getMenusAuthInfoIce().then((res) => {
    //   const arr = JSON.parse(window.sessionStorage.getItem("menuAuthsice")) || [];
    //   this.pathData = arr.map((e) => {
    //     return e.path;
    //   });
    // });

    if (this.$route.query.type) {
      this.routerIndex = 5;
    } // if (this.userInfo.roleIdentity !== 1001) {
    //   this.handleUserProjectOrder();
    // }


    this.handleUserProjectOrder();
    document.body.className = 'tt';
  },
  methods: {
    getLogoImgData: function getLogoImgData() {
      var _this2 = this;

      var params = {
        projectId: this.$store.state.project.projectInfo.id,
        type: 1
      };
      (0, _home.getLogoImg)(params).then(function (res) {
        if (res) {
          _this2.logoImg = res.projectLogoPicture;
        } else {
          _this2.logoImg = '';
        }
      });
    },
    // 获取用户关联项目工单权限
    handleUserProjectOrder: function handleUserProjectOrder() {
      var _this3 = this;

      (0, _user.relevanceProjectOrder)((0, _auth.getUserId)()).then(function (res) {
        // //console.log(res);
        if (res) {
          _this3.$store.commit("user/SET_ProjectOrderPermissions", res);
        }
      });
    },
    goToMananger: function goToMananger() {
      var _document$location = document.location,
          origin = _document$location.origin,
          pathname = _document$location.pathname;
      var url = origin + pathname + "www/?id=" + this.$store.state.project.projectInfo.id;
      localStorage.setItem("projectId", this.$store.state.project.projectInfo.id);
      window.open(url);
    },
    // 全屏事件
    handleFullScreen: function handleFullScreen() {
      var element = document.documentElement;

      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }

      this.fullscreen = !this.fullscreen;
    },
    handleRoute: function handleRoute(path, index) {
      if (index === 0) {
        this.$router.push({
          path: path,
          query: {
            id: index
          }
        });
        sessionStorage.setItem("routerIndex", index);
        this.routerIndex = index;
      } else if (index === 5) {
        var url = this.$router.resolve({
          name: "FeedbackCool",
          query: {
            type: 0
          }
        });
        window.open(url.href, "_blank");
      } else {
        this.$router.push({
          path: path
        });
        sessionStorage.setItem("routerIndex", index);
        this.routerIndex = index;
      }
    }
  }
};
exports.default = _default;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "theme-box" }, [
    _c("div", { staticClass: "content-box" }, [
      _c("div", { staticClass: "title" }, [_vm._v("系统实时参数")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "specific-box" },
        [
          _c("div", { staticClass: "item-box" }, [
            _c("div", [
              _vm.computer2Value > 0
                ? _c("img", {
                    staticClass: "img",
                    attrs: {
                      src: require("../../../assets/icon-cool/on1.gif"),
                      alt: ""
                    }
                  })
                : _c("img", {
                    staticClass: "img",
                    attrs: {
                      src: require("../../../assets/icon-cool/off1.png"),
                      alt: ""
                    }
                  }),
              _vm._v(" "),
              _vm.computer1Value > 0
                ? _c("img", {
                    staticClass: "img",
                    attrs: {
                      src: require("../../../assets/icon-cool/on2.gif"),
                      alt: ""
                    }
                  })
                : _c("img", {
                    staticClass: "img",
                    attrs: {
                      src: require("../../../assets/icon-cool/off2.png"),
                      alt: ""
                    }
                  })
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "sub-title",
                staticStyle: { "padding-top": "20px" }
              },
              [_vm._v("系统运行状态")]
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.parmsData, function(item, index) {
            return _c("div", { key: index, staticClass: "item-box" }, [
              _c("div", { staticClass: "t-mun" }, [
                _vm._v(_vm._s(item.value[0])),
                _c("span", { staticClass: "symbol" }, [
                  _vm._v(_vm._s(item.value[1]))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "sub-title" }, [
                _vm._v(_vm._s(item.name) + " ")
              ])
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
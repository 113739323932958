"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _projectInfo = require("@/api/projectInfo");

var _index = _interopRequireDefault(require("@/components/YearRangePicker/index.vue"));

var _dayjs = _interopRequireDefault(require("dayjs"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    "year-range": _index.default
  },
  data: function data() {
    var _this = this;

    return {
      params: {
        endDate: '',
        //		结束始时间	query	false	string(date-time)	
        projectId: this.$store.state.project.projectInfo.id,
        //		项目id	query	false	integer(int64)	
        id: this.$store.state.project.projectInfo.id,
        startDate: '',
        //		开始时间	query	false	string(date-time)
        type: ''
      },
      dataTimes: [],
      chartData: {
        dayData: 0,
        monthData: 0,
        yearData: 0,
        carbonEmission: 0,
        energyStandardTime: ''
      },
      energyCount: {
        dayData: [],
        monthData: [],
        yearData: []
      },
      energyInor: {
        dayData: [],
        monthData: [],
        yearData: []
      },
      energyData: {
        consumption: '',
        //	耗电量	number(double)	
        energySaved: '',
        //		节能量	number(double)	
        time: '' //	

      },
      energyInfor: [],
      consumption: [],
      energySaved: [],
      time: [],
      list: [],
      list1: [],
      count: [],
      count1: [],
      value: '',
      type: 1,
      type1: 1,
      type2: 0,
      expireTimeOPtion: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6; //如果没有后面的-8.64e6就是不可以选择今天的 
        }
      },
      yearRange: [2000, (0, _dayjs.default)().format("YYYY")],
      co2_unit: 'kg',
      startDate: '',
      endDate: '',
      startDatePicker: {
        //禁用的日期
        disabledDate: function disabledDate(time) {
          //如果已经已知结束日期，就把结束日期之后的日期禁用
          if (_this.endDate) {
            return time.getTime() > new Date(_this.endDate).getTime();
          } else {
            //否则 就把当前日期之后的日期禁用掉
            return time.getTime() > Date.now();
          }
        }
      },
      endDatePicker: {
        disabledDate: function disabledDate(time) {
          //如果已知开始日期，就把开始日期之前的日期禁用掉
          if (_this.startDate) {
            return time.getTime() < new Date(_this.startDate).getTime();
          }
        }
      },
      zoom: '',
      zoomrAtio: ''
    };
  },
  watch: {
    "$store.state.project.colorType": function $storeStateProjectColorType() {
      this.colorType = this.$store.state.project.colorType;

      if (this.colorType == 'theme_jyh') {
        this.echartfc = '#fff';
      } else if (this.colorType == 'theme_ytl') {
        this.echartfc = '#333';
      } else {
        this.echartfc = '#333';
      }

      this.areaEcharts();
      this.carbonEcharts();
      this.columnEchart();
    }
  },
  mounted: function mounted() {
    this.areaEcharts();
    this.carbonEcharts();
    this.columnEchart();
  },
  created: function created() {
    this.colorType = this.$store.state.project.colorType;

    if (this.colorType == 'theme_jyh') {
      this.echartfc = '#fff';
    } else if (this.colorType == 'theme_ytl') {
      this.echartfc = '#333';
    } else {
      this.echartfc = '#333';
    }

    this.setDates();
    this.energyCarbonEmission();
    this.energyStatistics();
    this.energySavedHistoryTrend();
    this.energySavedHistoryData();
  },
  methods: {
    // 处理节能量统计数据
    handleChartData: function handleChartData(val) {
      var value = val - 0;

      if (value >= 1000000) {
        return value = (value / 1000000).toFixed(2) + 'm';
      } else if (value >= 10000) {
        value = (value / 1000).toFixed(2) + 'k';
        return value;
      } else {
        return value;
      }
    },
    // 处理co2排放数据
    handleChartDataCo2: function handleChartDataCo2(val) {
      var value = val - 0;

      if (value >= 1000000) {
        this.co2_unit = 'kt';
        return value = (value / 1000000).toFixed(2);
      } else if (value >= 10000) {
        this.co2_unit = 't';
        value = (value / 1000).toFixed(2);
        return value;
      } else {
        this.co2_unit = 'kg';
        return value;
      }
    },
    handleYearChanged: function handleYearChanged(val) {
      this.yearRange = val;
    },
    // 初始化点击了日之后获取初始化时间
    setDates: function setDates() {
      // 这是他原来的方案
      // let t = dayjs().startOf('month').format('YYYY-MM-DD');
      // let d = dayjs().endOf('month').format('YYYY-MM-DD');
      // this.dataTimes.push(t);
      // this.dataTimes.push(d);
      // 修改后的日期组件方案
      this.startDate = (0, _dayjs.default)().startOf('month').format('YYYY-MM-DD');
      this.endDate = (0, _dayjs.default)().endOf('month').format('YYYY-MM-DD');
    },
    // 节能量趋势年月日
    checkDate: function checkDate(type) {
      this.type = type;
      var list = [];
      this.count = [];
      this.list = [];

      if (type == 1) {
        //天
        list = this.energyCount.dayData;
      }

      if (type == 2) {
        //月
        list = this.energyCount.monthData;
      }

      if (type == 3) {
        //年
        list = this.energyCount.yearData;
      } //数据处理，echart需要格式


      for (var i = 0; i < list.length; i++) {
        this.list.push(list[i].time);
        this.count.push(list[i].numerical);
      } //重新生产统计图


      this.areaEcharts();
    },
    // 减少碳排放年月日
    checkDate1: function checkDate1(type1) {
      this.type1 = type1;
      var list = [];
      this.count1 = [];
      this.list1 = [];

      if (type1 == 1) {
        //天
        list = this.energyInor.dayData;
      }

      if (type1 == 2) {
        //月
        list = this.energyInor.monthData;
      }

      if (type1 == 3) {
        //年
        list = this.energyInor.yearData;
      } //数据处理，echart需要格式


      for (var i = 0; i < list.length; i++) {
        this.list1.push(list[i].time);
        this.count1.push(list[i].numerical);
      } //重新生产统计图


      this.carbonEcharts();
    },
    // 节能量数据年月日
    checkDate2: function checkDate2(type2) {
      this.type2 = type2;
      this.consumption = [];
      this.energySaved = [];
      this.dataTimes = [];
      this.time = [];
      var myDate = new Date();
      var myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)

      var myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)

      var myToday = myDate.getDate(); //获取当前日(1-31)

      myMonth = myMonth < 10 ? '0' + myMonth : myMonth;
      myToday = myToday < 10 ? '0' + myToday : myToday;

      if (type2 === 0) {
        this.setDates();
      }

      if (type2 == 1) {
        var s = this.getRecentMonth_Date(6);
        var e = myYear + '-' + myMonth + '-' + myToday;
        this.dataTimes.push(s);
        this.dataTimes.push(e);
      }

      if (type2 == 2) {// 不做处理 使用默认值
      }

      this.energySavedHistoryData(); //数据处理，echart需要格式
    },
    // 获取最近日期
    // 近N月 - new Date()
    getRecentMonth_Date: function getRecentMonth_Date(n) {
      var result = '';
      var datenow = new Date();
      var dateend = datenow.getFullYear().toString() + '-' + (datenow.getMonth() + 1).toString() + '-' + datenow.getDate().toString();
      datenow.setMonth(datenow.getMonth() - n);
      var dyear = datenow.getFullYear();
      var dmonth = datenow.getMonth() + 1;
      dmonth = dmonth < 10 ? '0' + dmonth : dmonth;
      var dday = datenow.getDate();
      dday = dday < 10 ? '0' + dday : dday;
      var datestart = dyear.toString() + '-' + dmonth.toString() + '-' + dday.toString();
      result = datestart; // result += dateend;

      return result;
    },
    //节能量数据
    energySavedHistoryData: function energySavedHistoryData() {
      var _this2 = this;

      var ts = this; // 如果选中年份要做特殊处理

      if (ts.type2 == 2) {
        // 已经选择了一个年份，再获取获取当前年份 
        var startYear = this.yearRange[0];
        var endYear = this.yearRange[1];
        ts.params.startDate = startYear + '-' + '01' + '-' + '01' + " 00:00:00";
        ts.params.endDate = endYear + '-' + '12' + '-' + '31' + " 23:59:59";
      } else if (ts.type2 == 0) {
        // 关闭clearable 如果打开的话要做非空判断
        ts.params.startDate = ts.startDate + " 00:00:00";
        ts.params.endDate = ts.endDate + " 23:59:59";
      } else {
        ts.params.startDate = ts.dataTimes[0] + " 00:00:00";
        ts.params.endDate = ts.dataTimes[1] + " 23:59:59";
      }

      ts.params.type = ts.type2;
      this.time = [];
      this.consumption = [];
      this.energySaved = [];
      (0, _projectInfo.energySavedHistoryData)(ts.params).then(function (res) {
        ts.energyInfor = res; // ts.type2 = 0; // 设置默认获取日的统计数据

        for (var i = 0; i < _this2.energyInfor.length; i++) {
          _this2.time.push(_this2.energyInfor[i].time);

          _this2.consumption.push(_this2.energyInfor[i].consumption);

          _this2.energySaved.push(_this2.energyInfor[i].energySaved);
        } //重新生产统计图


        ts.columnEchart();
      });
    },
    //节能量统计
    energyStatistics: function energyStatistics() {
      var ts = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _projectInfo.energySavedHistoryStatistics)(par).then(function (res) {
        ts.chartData = res;
        ts.chartData.carbonEmission = Math.floor(ts.chartData.carbonEmission * 100) / 100;
        ts.chartData.dayData = ts.chartData.dayData * 1;
        ts.chartData.monthData = ts.chartData.monthData * 1;
        ts.chartData.yearData = ts.chartData.yearData * 1;
      });
    },
    //节能量趋势
    energySavedHistoryTrend: function energySavedHistoryTrend() {
      var ts = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id //projectId	query	false	

      };
      (0, _projectInfo.energySavedHistoryTrend)(par).then(function (res) {
        ts.energyCount = res;
        ts.type = 1; // 设置默认获取日的统计数据

        ts.checkDate(1); // 调用类型选择，进行echart统计
      });
    },
    //减少二氧化碳排放
    energyCarbonEmission: function energyCarbonEmission() {
      var ts = this;
      var par = {
        projectId: this.$store.state.project.projectInfo.id //projectId	query	false	

      };
      (0, _projectInfo.energySavedHistoryCarbonEmission)(par).then(function (res) {
        ts.energyInor = res;
        ts.type = 1; // 设置默认获取日的统计数据

        ts.checkDate1(1); // 调用类型选择，进行echart统计
      });
    },
    //节能量统计
    doughnutEcharts: function doughnutEcharts() {
      var doughnutEcharts = this.$echarts.init(document.getElementById("doughnutEcharts"));
      var option = {
        tooltip: {
          trigger: 'item',
          borderWidth: 0
        },
        legend: {
          top: '5%',
          left: 'center',
          textStyle: {
            color: this.echartfc
          }
        },
        color: ["#3B7FE2", "#91cc76", "#fac858", "#ed6666", "#73c0de", "#3ba272", "#fc8352", "#9a61b4", "#ecc49f"],
        series: [{
          // name: 'Access From',
          type: 'pie',
          radius: ['46%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [{
            value: 1048,
            name: '当月'
          }]
        }]
      };
      doughnutEcharts.setOption(option);
    },
    //节能量趋势
    areaEcharts: function areaEcharts() {
      var ts = this;
      var areaEcharts = this.$echarts.init(document.getElementById("areaEcharts"));
      var option = {
        grid: {
          left: "5%",
          right: "5%",
          top: "13%",
          bottom: "3%",
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          borderWidth: 0,
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          },
          valueFormatter: function valueFormatter(value) {
            return value + ' kWh';
          }
        },
        //         legend: {
        //              textStyle: { 
        //     color:this.echartfc,
        //   },
        //         },
        xAxis: [{
          type: 'category',
          data: ts.list,
          axisPointer: {
            type: 'shadow'
          }
        }],
        yAxis: [{
          type: 'value',
          name: ' ',
          axisLabel: {
            formatter: '{value}'
          },
          splitLine: {
            lineStyle: {
              color: this.echartYLine //type:'dashed'虚线

            }
          }
        }, {
          type: 'value',
          name: ' ',
          axisLabel: {
            formatter: '{value}'
          },
          splitLine: {
            lineStyle: {
              color: this.echartYLine //type:'dashed'虚线

            }
          }
        }],
        series: [{
          //name: ' ',
          type: 'bar',
          barWidth: '10%',
          barMaxWidth: '30%',
          data: ts.count,
          tooltip: {
            show: false
          }
        }, {
          //name: '',
          type: 'line',
          yAxisIndex: 1,
          data: ts.count
        }]
      };
      areaEcharts.setOption(option);
    },
    //减少碳排放
    carbonEcharts: function carbonEcharts() {
      var ts = this;
      var carbonEcharts = this.$echarts.init(document.getElementById("carbonEcharts"));
      var option = {
        grid: {
          left: "5%",
          right: "5%",
          top: "13%",
          bottom: "3%",
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          borderWidth: 0,
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          },
          valueFormatter: function valueFormatter(value) {
            return value.toFixed(2) + ' kg';
          }
        },
        legend: {
          data: ['数据'],
          textStyle: {
            color: this.echartfc
          }
        },
        xAxis: [{
          type: 'category',
          data: ts.list1,
          axisPointer: {
            type: 'shadow'
          }
        }],
        yAxis: [{
          type: 'value',
          name: ' ',
          axisLabel: {
            formatter: '{value}'
          },
          splitLine: {
            lineStyle: {
              color: this.echartYLine //type:'dashed'虚线

            }
          }
        }, {
          type: 'value',
          name: ' ',
          axisLabel: {
            formatter: '{value}'
          },
          splitLine: {
            lineStyle: {
              color: this.echartYLine //type:'dashed'虚线

            }
          }
        }],
        series: [{
          //name: ' ',
          type: 'bar',
          barWidth: '10%',
          barMaxWidth: '30%',
          color: '#92cc76',
          data: ts.count1
        }, {
          //name: '',
          type: 'line',
          color: '#5470c6',
          yAxisIndex: 1,
          data: ts.count1,
          tooltip: {
            show: false
          }
        }]
      };
      carbonEcharts.setOption(option);
    },
    //节能量数据
    columnEchart: function columnEchart() {
      var ts = this;
      var columnEchart = this.$echarts.init(document.getElementById("columnEchart"));
      var emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0,0,0,0.2)'
        }
      };
      var option = {
        grid: {
          left: "2%",
          right: "3%",
          top: "15%",
          bottom: "5%",
          containLabel: true
        },
        legend: {
          data: ['耗电量', '节能量'],
          left: '47%',
          top: "3%",
          textStyle: {
            color: this.echartfc
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: function formatter(params) {
            var result = params[0].name + "<br>"; // 初始化总值

            var allCount = 0;
            params.forEach(function (item) {
              // 处理value为null的情况
              if (!item.value) {
                item.value = 0;
              }

              allCount += item.value * 1;
            });
            params.forEach(function (item) {
              if (item.value) {
                result += item.marker + " " + item.seriesName + " : " + item.value + "kWh" + ' 占比' + (item.value / allCount * 100).toFixed(2) + "%</br>";
              } else {
                result += item.marker + " " + item.seriesName + " : - </br>";
              }
            });
            return result;
          }
        },
        xAxis: [{
          type: 'category',
          data: ts.time.length > 0 ? ts.time : ['', '', '', '', '', '', '', '', '', '', '', '']
        }],
        yAxis: [{
          type: 'value',
          splitLine: {
            lineStyle: {
              color: this.echartYLine //type:'dashed'虚线

            }
          }
        }],
        series: [{
          name: '耗电量',
          type: 'bar',
          barWidth: '4%',
          barMaxWidth: '30%',
          stack: 'one',
          emphasis: ts.consumption,
          data: ts.consumption.length > 0 ? ts.consumption : [0, 0, 0, 0, 0, 0, 0] // data: [-2.0, -4.9, -7.0, -23.2, -25.6, -76.7, -135.6, -162.2, -32.6, -20.0, -6.4, -3.3],

        }, {
          name: '节能量',
          type: 'bar',
          barWidth: '4%',
          barMaxWidth: '30%',
          stack: 'one',
          emphasis: ts.energySaved,
          data: ts.energySaved.length > 0 ? ts.energySaved : [0, 0, 0, 0, 0, 0, 0] // data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],

        }]
      };
      columnEchart.on('brushSelected', function (params) {
        var brushed = [];
        var brushComponent = params.batch[0];

        for (var sIdx = 0; sIdx < brushComponent.selected.length; sIdx++) {
          var rawIndices = brushComponent.selected[sIdx].dataIndex;
          brushed.push('[Series ' + sIdx + '] ' + rawIndices.join(', '));
        }

        columnEchart.setOption({
          title: {
            backgroundColor: '#333',
            text: 'SELECTED DATA INDICES: \n' + brushed.join('\n'),
            bottom: 0,
            right: '3%',
            width: 10,
            textStyle: {
              fontSize: 12,
              color: '#fff'
            }
          }
        });
      });
      columnEchart.setOption(option);
    }
  }
};
exports.default = _default;
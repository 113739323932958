"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/user");

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { isEmpty } from '@/utils/serve'
var _default = {
  name: "banner",
  data: function data() {
    return {
      version: "V6.1.0",
      loading: false,
      imgUrl: "",
      loginForm: {
        username: "",
        password: ""
      },
      loginRules: {
        username: [{
          required: true,
          message: "用户名不能为空",
          trigger: "blur"
        }],
        password: [{
          equired: true,
          message: "密码不能为空",
          trigger: "blur"
        }]
      },
      imgList: [],
      interval: 3000
    };
  },
  computed: {
    baseApiIsIP: function baseApiIsIP() {
      if (process.env.VUE_APP_BASE_API === "https://www.digitalhvac.com.cn:8081") {
        return false;
      } else {
        return true;
      }
    }
  },
  created: function created() {
    this.getImgData(); // this.play();
    // document.getElementById('bg').style.height = window.screen.availHeight + 'px'
  },
  methods: {
    handleLogin: function handleLogin(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.getLoginHandle();
        }
      });
    },
    getLoginHandle: function getLoginHandle() {
      var _this2 = this;

      (0, _user.getLogin)(this.loginForm).then(function (data) {
        _this2.loading = false;
        localStorage.setItem("userInfo", JSON.stringify(data));
        (0, _auth.setToken)(data.token);
        (0, _auth.setUserId)(data.id);

        _this2.$router.push("/home");
      }).catch(function () {
        _this2.loading = false;
      });
    },
    getImgData: function getImgData() {
      var _this3 = this;

      this.imgList = [];
      (0, _user.getImg)().then(function (res) {
        if (res.length > 0) {
          //  获取第一个间隔时间，
          _this3.interval = res[0].landingTime * 1000;
          res.forEach(function (item) {
            _this3.imgList.push(item.landingImage);
          });
          res.forEach(function (item) {
            _this3.imgList.push(item.landingImage);
          });
        }
      });
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _dayjs = _interopRequireDefault(require("dayjs"));

var _dataAnalysis = require("@/api/dataAnalysis");

var _middleUtil = _interopRequireDefault(require("@/utils/middleUtil.js"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      thisIndex: null,
      daysData: [],
      calendar: (0, _dayjs.default)().format("YYYY-MM"),
      month: (0, _dayjs.default)().format("MM"),
      daysOne: [],
      lengdeData: [],
      colors: ["#2726261a", "#ff0000", "#ffff00", "#09f709", "#4287fa"],
      pickerOptions0: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      }
    };
  },
  mounted: function mounted() {
    this.getEnergyConsumptionCalendarData();
  },
  methods: {
    jump: function jump(index) {
      var day = new Date(this.daysOne[index].day).toLocaleDateString();

      _middleUtil.default.$emit("fetchData", day);

      this.thisIndex = index;
    },
    getConsumptionBatchData: function getConsumptionBatchData() {
      var _this = this;

      var params = {
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataAnalysis.getConsumptionBatch)(params).then(function (res) {
        _this.lengdeData = res.map(function (e) {
          return e.energyConsumptionMin;
        });
      });
    },
    getEnergyConsumptionCalendarData: function getEnergyConsumptionCalendarData() {
      var _this2 = this;

      var params = {
        timeDate: this.calendar,
        projectId: this.$store.state.project.projectInfo.id
      };
      (0, _dataAnalysis.getEnergyConsumptionCalendar)(params).then(function (res) {
        _this2.month = parseInt((0, _dayjs.default)(_this2.calendar).format("MM"));

        var days = _this2.initData(_this2.formatDate((0, _dayjs.default)(_this2.calendar).format("YYYY"), _this2.month, 1));

        _this2.daysOne = _this2.getColorData(days, res);
      });
    },
    handleTime: function handleTime() {
      this.getEnergyConsumptionCalendarData();
    },
    getBackColor: function getBackColor(val) {
      var color = "";

      switch (val[4]) {
        case 0:
          color = "background:".concat(this.colors[0]);
          break;

        case 1:
          color = "background:".concat(this.colors[1]);
          break;

        case 2:
          color = "background:".concat(this.colors[2]);
          break;

        case 3:
          color = "background:".concat(this.colors[3]);
          break;
        // eslint-disable-next-line no-fallthrough

        case 4:
          color = "background:".concat(this.colors[4]);
          break;
      }

      return color;
    },
    getColorData: function getColorData(days, colors) {
      for (var i = 0; i < days.length; i++) {
        for (var j = 0; j < colors.length; j++) {
          for (var key in colors[j]) {
            if (days[i].day.getDate() === Number((0, _dayjs.default)(key).format("DD"))) {
              days[i].calendarData = colors[j][key];
            }
          }
        }
      }

      return days;
    },
    formatDate: function formatDate(year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
    initData: function initData(cur) {
      var that = this;
      var days = []; //   const leftcount = 0 // 存放剩余数量

      var date;

      if (cur) {
        date = new Date(cur);
      } else {
        var now = new Date();
        var d = new Date(that.formatDate(now.getFullYear(), now.getMonth(), 1));
        d.setDate(35);
        date = new Date(that.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
      }

      that.currentDay = date.getDate();
      that.currentYear = date.getFullYear();
      that.currentMonth = date.getMonth() + 1;
      that.currentWeek = date.getDay(); // 1...6,0

      if (that.currentWeek === 0) {
        that.currentWeek = 7;
      }

      var str = that.formatDate(that.currentYear, that.currentMonth, that.currentDay);
      days.length = 0; // 今天是周日，放在第一行第7个位置，前面6个
      // 初始化本周

      for (var i = that.currentWeek - 1; i >= 0; i--) {
        var _d = new Date(str);

        _d.setDate(_d.getDate() - i);

        var dayobject = {}; // 用一个对象包装Date对象  以便为以后预定功能添加属性

        dayobject.day = _d;
        days.push(dayobject); // 将日期放入data 中的days数组 供页面渲染使用
      } // 其他周


      for (var _i = 1; _i <= 42 - that.currentWeek; _i++) {
        var _d2 = new Date(str);

        _d2.setDate(_d2.getDate() + _i);

        var _dayobject = {};
        _dayobject.day = _d2;
        days.push(_dayobject);
      }

      return days;
    }
  }
};
exports.default = _default;
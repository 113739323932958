"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _axios = _interopRequireDefault(require("axios"));

var _vue = _interopRequireDefault(require("vue"));

var echarts = _interopRequireWildcard(require("echarts"));

var _home = require("@/api/home");

var _amapJsapiLoader = _interopRequireDefault(require("@amap/amap-jsapi-loader"));

var _dayjs = _interopRequireDefault(require("dayjs"));

var _systemParams = _interopRequireDefault(require("../home-page/compontents/systemParams.vue"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// 引入echarts
_vue.default.prototype.$axios = _axios.default;
window._AMapSecurityConfig = {
  securityJsCode: "6e3cad5b42f33d1f7c6941b328e56a21"
};
var _default = {
  components: {
    systemParams: _systemParams.default
  },
  data: function data() {
    return {
      buttonStyle: {
        isSelectAGlmj: "buttonClass isSelect",
        isSelectAJfrl: "buttonClass",
        isSelectBDay: "buttonClass isSelect",
        isSelectBMonth: "buttonClass",
        isSelectBYear: "buttonClass",
        isSelectCNx: "buttonClass isSelect",
        isSelectCLj: "buttonClass",
        isSelectDDay: "buttonClass isSelect",
        isSelectDMonth: "buttonClass",
        isSelectDYear: "buttonClass"
      },
      windowsStyle: "project-info-jm",
      mapDataJson: [],
      nowPath: "/gd.json",
      nowArea: [],
      leftData: {
        roomNumber: 0,
        coolingArea: 0,
        installedCapacity: 0
      },
      coolingArea: {
        jssp: 12000,
        sjzx: 40000,
        yggwzx: 56000,
        aaagwzx: 60000
      },
      installedCapacity: {
        jssp: 1400,
        sjzx: 6000,
        yggwzx: 2900,
        aaagwzx: 2400
      },
      time: "时间读取中...",
      variableInderx: 0,
      isShow: false,
      isReturn: false,
      projectList: [],
      nowProject: [],
      preview: [],
      tq: {
        szs: [],
        jms: []
      },
      nxlx: {
        jssp: [],
        sjzx: [],
        yggwzx: [],
        aaagwzx: [],
        time: [],
        data: new Map(),
        coldPrice: new Map()
      },
      energyData: null,
      loading: true,
      hierarchy: 0
    };
  },
  created: function created() {
    this.chanageLoading();
  },
  mounted: function mounted() {
    this.initData();
    setInterval(this.getDate, 1000);
  },
  methods: {
    initData: function initData() {
      this.getNxLxData();
      this.getEnergyData("day");
      this.getCoolingArea();
      this.initMapChart();
      this.getWeather("深圳市");
      this.getWeather("江门市");
      this.initDataMap();
    },
    chanageLoading: function chanageLoading() {
      this.loading = false;
    },
    showHidden: function showHidden(variant) {
      var preview = document.getElementById("preview");
      var timeLine = document.getElementById("time-line");

      if (variant) {
        preview.style.display = "block";
        timeLine.style.display = "block";
      } else {
        preview.style.display = "none";
        timeLine.style.display = "none";
      }
    },
    getNxLxData: function getNxLxData() {
      var _this2 = this;

      (0, _home.projectInfoCompanyList)().then(function (res) {
        _this2.projectList = res;
        var params = new Map();
        res.forEach(function (data) {
          params.set(data.id, data.entryName);
        });
        (0, _home.getEnergyConsumption)(JSON.stringify(_this2.strMapToObj(params))).then(function (d) {
          _this2.nxlx.data = d;

          for (var key in d) {
            if (key === "江山食品") {
              _this2.nxlx.time = d[key][0].dayCop.map(function (e) {
                return (0, _dayjs.default)(e.dataTime).format("YYYY-MM-DD");
              });
              _this2.nxlx.jssp = d[key][0].dayCop.map(function (e) {
                return e.copValue;
              });
            } else if (key === "数据中心") {
              _this2.nxlx.sjzx = d[key][0].dayCop.map(function (e) {
                return e.copValue;
              });
            } else if (key === "阳光购物中心") {
              _this2.nxlx.yggwzx = d[key][0].dayCop.map(function (e) {
                return e.copValue;
              });
            } else if (key === "AAA购物中心") {
              _this2.nxlx.aaagwzx = d[key][0].dayCop.map(function (e) {
                return e.copValue;
              });
            }
          }

          _this2.getNxLx(0, "day");
        });
        (0, _home.getCoemColdPrice)(JSON.stringify(_this2.strMapToObj(params))).then(function (d) {
          _this2.nxlx.coldPrice = d;
        });
      });
    },
    getEnergyData: function getEnergyData(time) {
      var _this3 = this;

      if (time === "day") {
        this.buttonStyle.isSelectBDay = "buttonClass isSelect";
        this.buttonStyle.isSelectBMonth = "buttonClass";
        this.buttonStyle.isSelectBYear = "buttonClass";
      } else if (time === "month") {
        this.buttonStyle.isSelectBDay = "buttonClass";
        this.buttonStyle.isSelectBMonth = "buttonClass isSelect";
        this.buttonStyle.isSelectBYear = "buttonClass";
      } else if (time === "year") {
        this.buttonStyle.isSelectBDay = "buttonClass";
        this.buttonStyle.isSelectBMonth = "buttonClass";
        this.buttonStyle.isSelectBYear = "buttonClass isSelect";
      }

      (0, _home.projectInfoCompanyList)().then(function (res) {
        _this3.projectList = res;

        _this3.projectList.forEach(function (d) {
          if ("江山食品" == d.entryName) {
            _this3.preview = d;
            sessionStorage.setItem("projectInfo", JSON.stringify(d));
          }
        });

        var ids = new Map();
        res.forEach(function (data) {
          ids.set(data.id, data.entryName);
        });
        var startTime = (0, _dayjs.default)().startOf(time).format("YYYY-MM-DD HH:mm:ss");
        var endTime = (0, _dayjs.default)().endOf(time).format("YYYY-MM-DD HH:mm:ss");
        var params = {
          startTime: startTime,
          endTime: endTime,
          ids: JSON.stringify(_this3.strMapToObj(ids))
        };
        (0, _home.getEnergy)(params).then(function (d) {
          _this3.energyData = d;

          _this3.getEnergyConsumption();
        });
      });
    },
    strMapToObj: function strMapToObj(strMap) {
      var obj = Object.create(null); //创建空的对象

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = strMap[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _step$value = (0, _slicedToArray2.default)(_step.value, 2),
              k = _step$value[0],
              v = _step$value[1];

          obj[k] = v;
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return obj;
    },
    getDate: function getDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var One = date.getDay();
      var timeString = date.toLocaleTimeString("chinese", {
        hour12: false
      });
      var weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
      var week = weeks[One];
      this.time = year + "-" + month + "-" + day + "   " + week + "   " + timeString;
    },
    getWeather: function getWeather(cityName) {
      var _this4 = this;

      _amapJsapiLoader.default.load({
        key: "1ca7aafea66f912420be9ae1eb72157b",
        // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",
        // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Weather"] // 需要使用的的插件列表，如比例尺'AMap.Scale'等

      }).then(function (AMap) {
        var weather = new AMap.Weather(); //执行实时天气信息查询

        weather.getLive(cityName, function (ErrorStatus, WeatherLiveResult) {
          if (!ErrorStatus) {
            if (cityName === "深圳市") {
              _this4.tq.szs = WeatherLiveResult;

              var imgsrc = _this4.changeIcon(_this4.tq.szs.weather);

              if (_this4.tq.szs.src != "") {
                _this4.tq.szs.src = require("../../assets/" + imgsrc);
              }
            }

            if (cityName === "江门市") {
              _this4.tq.jms = WeatherLiveResult;

              var imgsrc = _this4.changeIcon(_this4.tq.jms.weather);

              if (_this4.tq.jms.src != "") {
                _this4.tq.jms.src = require("../../assets/" + imgsrc);
              }
            }
          } else {//console.log("获取天气失败", JSON.stringify(ErrorStatus));
          }
        });
      }).catch(function (e) {//console.log(e);
      });
    },
    changeIcon: function changeIcon(name) {
      var data = name + "";
      var icon = "";

      if (data.indexOf("雨") != -1) {
        icon = "rain.png";
      } else if (data.indexOf("阴") != -1) {
        icon = "yt.png";
      } else if (data.indexOf("云") != -1) {
        icon = "dy.png";
      } else if (data.indexOf("晴") != -1) {
        icon = "fine.png";
      }

      return icon;
    },
    toHomePage: function toHomePage() {
      var routeUrl = this.$router.resolve({
        path: "/home-page"
      });
      window.open(routeUrl.href);
    },
    returnChart: function returnChart() {
      this.isReturn = false;
      this.initMapChart();
      this.showHidden(true);
      this.hierarchy = 0;
      this.isShow = false;
    },
    initDataMap: function initDataMap() {
      var voltageLevel = ["江山食品", "数据中心", "阳光购物中心", "AAA购物中心"];
      var timeLine = echarts.init(document.getElementById("time-line"));
      timeLine.clear();
      var option = {
        type: "timelineChange",
        timeline: {
          data: voltageLevel,
          axisType: "category",
          autoPlay: true,
          playInterval: 6000,
          left: "10%",
          right: "10%",
          bottom: "3%",
          width: "80%",
          label: {
            textStyle: {
              color: "#ddd"
            },
            emphasis: {
              textStyle: {
                color: "#fff"
              }
            }
          },
          symbolSize: 10,
          lineStyle: {
            color: "#555"
          },
          checkpointStyle: {
            borderColor: "#888",
            borderWidth: 2
          },
          controlStyle: {
            showNextBtn: true,
            showPrevBtn: true,
            normal: {
              color: "#666",
              borderColor: "#666"
            },
            emphasis: {
              color: "#aaa",
              borderColor: "#aaa"
            }
          }
        },
        options: []
      };
      timeLine.setOption(option);

      var _this = this;

      timeLine.on("timelinechanged", function (params) {
        if (_this.projectList) {
          if (params.currentIndex === 0) {
            _this.projectList.forEach(function (d) {
              if ("江山食品" == d.entryName) {
                _this.preview = d;

                if (_this.hierarchy === 0) {
                  sessionStorage.setItem("projectInfo", JSON.stringify(d));
                }
              }
            });
          } else if (params.currentIndex === 1) {
            _this.projectList.forEach(function (d) {
              if ("数据中心" == d.entryName) {
                _this.preview = d;

                if (_this.hierarchy === 0) {
                  sessionStorage.setItem("projectInfo", JSON.stringify(d));
                }
              }
            });
          } else if (params.currentIndex === 2) {
            _this.projectList.forEach(function (d) {
              if ("阳光购物中心" == d.entryName) {
                _this.preview = d;

                if (_this.hierarchy === 0) {
                  sessionStorage.setItem("projectInfo", JSON.stringify(d));
                }
              }
            });
          } else if (params.currentIndex === 3) {
            _this.projectList.forEach(function (d) {
              if ("AAA购物中心" == d.entryName) {
                _this.preview = d;

                if (_this.hierarchy === 0) {
                  sessionStorage.setItem("projectInfo", JSON.stringify(d));
                }
              }
            });
          }
        }
      });
    },
    initMapChart: function initMapChart() {
      var _this = this;

      var _JSON = JSON;
      var points = [{
        area: "江门市",
        projectId: "1",
        name: "江山食品",
        value: [112.926586, 22.804424],
        itemStyle: {
          color: "#1DE9B6"
        }
      }, {
        area: "江门市",
        projectId: "2",
        name: "数据中心",
        value: [113.021265, 22.789316],
        itemStyle: {
          color: "#1DE9B6"
        }
      }, {
        area: "深圳市",
        projectId: "3",
        name: "阳光购物中心",
        value: [113.834134, 22.675293],
        itemStyle: {
          color: "#1DE9B6"
        }
      }, {
        area: "深圳市",
        projectId: "4",
        name: "AAA购物中心",
        value: [113.91112793896482, 22.516295451865773],
        itemStyle: {
          color: "#1DE9B6"
        }
      }];

      _axios.default.get(this.nowPath).then(function (map) {
        echarts.registerMap("gd", map.data);
        var chart = echarts.init(document.getElementById("centre-map"));
        chart.off("click");
        chart.clear();
        var option = {
          legend: {
            orient: "vertical",
            top: "30",
            left: "center",
            align: "right",
            data: [],
            textStyle: {
              color: "#fff",
              fontSize: 20
            },
            itemWidth: 50,
            itemHeight: 30,
            selectedMode: "multiple"
          },
          geo: {
            show: false,
            map: "gd",
            projection: {
              project: function project(point) {
                return [point[0] / 180 * Math.PI, -Math.log(Math.tan((Math.PI / 2 + point[1] / 180 * Math.PI) / 2))];
              },
              unproject: function unproject(point) {
                return [point[0] * 180 / Math.PI, 2 * 180 / Math.PI * Math.atan(Math.exp(point[1])) - 90];
              }
            },
            label: {
              show: false,
              emphasis: {
                show: false
              }
            },
            roam: false,
            itemStyle: {
              show: true,
              areaColor: "transparent",
              borderColor: "#3fdaff",
              borderWidth: 2,
              shadowColor: "rgba(63, 218, 255, 0.5)",
              shadowBlur: 30,
              emphasis: {
                areaColor: "#2B91B7"
              }
            }
          },
          series: [{
            map: "gd",
            type: "map",
            projection: {
              project: function project(point) {
                return [point[0] / 180 * Math.PI, -Math.log(Math.tan((Math.PI / 2 + point[1] / 180 * Math.PI) / 2))];
              },
              unproject: function unproject(point) {
                return [point[0] * 180 / Math.PI, 2 * 180 / Math.PI * Math.atan(Math.exp(point[1])) - 90];
              }
            },
            aspectScale: 1.2,
            zoom: 1.2,
            selectedMode: "single",
            //选择类型,
            hoverable: false,
            //鼠标经过高亮
            roam: false,
            //鼠标滚轮缩放
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: 12,
                  fontWeight: 500
                }
              },
              emphasis: {
                textStyle: {
                  color: "rgb(183,185,14)"
                }
              }
            },
            itemStyle: {
              borderColor: "rgba(147, 235, 248, 1)",
              borderWidth: 1,
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [{
                  offset: 0,
                  color: "rgba(147, 235, 248, 0)" // 0% 处的颜色

                }, {
                  offset: 1,
                  color: "rgba(147, 235, 248, .2)" // 100% 处的颜色

                }],
                globalCoord: false // 缺省为 false

              },
              shadowColor: "rgba(128, 217, 248, 1)",
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 10,
              emphasis: {
                areaColor: "#389BB7",
                borderWidth: 0
              }
            }
          }, {
            type: "effectScatter",
            coordinateSystem: "geo",
            showEffectOn: "render",
            zlevel: 1,
            rippleEffect: (0, _defineProperty2.default)({
              brushType: "stroke",
              period: 15,
              scale: 4
            }, "brushType", "fill"),
            hoverAnimation: true,
            symbolSize: 12,
            data: points
          }]
        };
        chart.setOption(option);
        chart.on("click", function (params) {
          var _this5 = this;

          if (params.componentSubType == "effectScatter") {
            if (_this.hierarchy === 1) {
              _this.isShow = true;
            }

            _this.projectList.forEach(function (d) {
              if (params.data.name == d.entryName) {
                _this.nowProject = d;
                sessionStorage.setItem("projectInfo", _JSON.stringify(_this.nowProject));
              }
            });

            return;
          }

          if (params.name == "深圳市") {
            this.nowPath = "/sz.json";
            _this.windowsStyle = "project-info-sz";
          } else if (params.name == "江门市") {
            this.nowPath = "/jm.json";
            _this.windowsStyle = "project-info-jm";
          } else {
            return;
          }

          _this.hierarchy = 1;

          _this.showHidden(false);

          _this.isReturn = true;
          this.nowArea = [];
          points.forEach(function (data) {
            if (data.area == params.name) {
              _this5.nowArea.push(data);
            }
          });

          _axios.default.get(this.nowPath).then(function (map) {
            echarts.registerMap("gd", map.data);
            var chart = echarts.init(document.getElementById("centre-map"));
            chart.clear();
            var option = {
              geo: {
                show: false,
                silent: true,
                map: "gd",
                label: {
                  show: false,
                  emphasis: {
                    show: false
                  }
                },
                roam: false,
                itemStyle: {
                  show: true,
                  areaColor: "transparent",
                  borderColor: "#3fdaff",
                  borderWidth: 2,
                  shadowColor: "rgba(63, 218, 255, 0.5)",
                  shadowBlur: 30,
                  emphasis: {
                    areaColor: "#2B91B7"
                  }
                }
              },
              series: [{
                map: "gd",
                type: "map",
                silent: true,
                aspectScale: 1.2,
                zoom: 1.2,
                selectedMode: "single",
                //选择类型,
                hoverable: false,
                //鼠标经过高亮
                roam: false,
                //鼠标滚轮缩放
                label: {
                  normal: {
                    show: true,
                    textStyle: {
                      color: "#fff",
                      fontSize: 12,
                      fontWeight: 500
                    }
                  },
                  emphasis: {
                    textStyle: {
                      color: "rgb(183,185,14)"
                    }
                  }
                },
                itemStyle: {
                  borderColor: "rgba(147, 235, 248, 1)",
                  borderWidth: 1,
                  areaColor: {
                    type: "radial",
                    x: 0.5,
                    y: 0.5,
                    r: 0.8,
                    colorStops: [{
                      offset: 0,
                      color: "rgba(147, 235, 248, 0)" // 0% 处的颜色

                    }, {
                      offset: 1,
                      color: "rgba(147, 235, 248, .2)" // 100% 处的颜色

                    }],
                    globalCoord: false // 缺省为 false

                  },
                  shadowColor: "rgba(128, 217, 248, 1)",
                  shadowOffsetX: -2,
                  shadowOffsetY: 2,
                  shadowBlur: 10,
                  emphasis: {
                    areaColor: "#389BB7",
                    borderWidth: 0
                  }
                }
              }, {
                type: "effectScatter",
                coordinateSystem: "geo",
                showEffectOn: "render",
                zlevel: 1,
                rippleEffect: {
                  period: 15,
                  scale: 4,
                  brushType: "fill"
                },
                hoverAnimation: true,
                label: {
                  formatter: "{b}",
                  position: "right",
                  offset: [15, 0],
                  color: "#1DE9B6",
                  show: true
                },
                itemStyle: {
                  color: "#1DE9B6",
                  shadowBlur: 20,
                  shadowColor: "#333"
                },
                symbolSize: 12,
                data: _this5.nowArea
              }]
            };
            chart.setOption(option);
          });
        });
      });
    },
    getCoolingArea: function getCoolingArea() {
      this.buttonStyle.isSelectAGlmj = "buttonClass isSelect";
      this.buttonStyle.isSelectAJfrl = "buttonClass";
      var lineCharts = echarts.init(document.getElementById("data-chart"));
      lineCharts.clear();
      var option = {
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666"],
        tooltip: {
          trigger: "item",
          formatter: function formatter(params) {
            return "".concat(params.name, "<br />").concat(params.marker).concat(params.value, "\u33A1, \u5360\u6BD4:").concat(params.percent, "%");
          },
          textStyle: {
            fontSize: 18,
            lineHeight: 30
          },
          padding: 15
        },
        legend: {
          orient: "vertical",
          left: "left",
          top: "30px",
          textStyle: {
            color: "#f0f8ff"
          }
        },
        series: [{
          type: "pie",
          center: ["65%", "50%"],
          radius: "60%",
          label: {
            color: "#f0f8ff",
            fontSize: 10,
            position: "inner",
            normal: {
              formatter: function formatter(params) {
                return params.value + "㎡";
              }
            }
          },
          data: [{
            value: this.coolingArea.jssp,
            name: "江山食品"
          }, {
            value: this.coolingArea.sjzx,
            name: "数据中心"
          }, {
            value: this.coolingArea.yggwzx,
            name: "阳光购物中心"
          }, {
            value: this.coolingArea.aaagwzx,
            name: "AAA购物中心"
          }],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          },
          labelLine: {
            show: false
          }
        }]
      };
      lineCharts.setOption(option);
    },
    getInstalledCapacity: function getInstalledCapacity() {
      this.buttonStyle.isSelectAJfrl = "buttonClass isSelect";
      this.buttonStyle.isSelectAGlmj = "buttonClass";
      var lineCharts = echarts.init(document.getElementById("data-chart"));
      lineCharts.clear();
      var option = {
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666"],
        tooltip: {
          trigger: "item",
          formatter: function formatter(params) {
            return "".concat(params.name, "<br />").concat(params.marker).concat(params.value, "RT, \u5360\u6BD4:").concat(params.percent, "%");
          },
          textStyle: {
            fontSize: 18,
            lineHeight: 30
          },
          padding: 15
        },
        legend: {
          orient: "vertical",
          left: "left",
          top: "30px",
          textStyle: {
            color: "#f0f8ff"
          }
        },
        series: [{
          type: "pie",
          center: ["65%", "50%"],
          radius: "60%",
          label: {
            color: "#f0f8ff",
            fontSize: 10,
            position: "inner",
            formatter: function formatter(params) {
              return params.value + "RT";
            }
          },
          data: [{
            value: this.installedCapacity.jssp,
            name: "江山食品"
          }, {
            value: this.installedCapacity.sjzx,
            name: "数据中心"
          }, {
            value: this.installedCapacity.yggwzx,
            name: "阳光购物中心"
          }, {
            value: this.installedCapacity.aaagwzx,
            name: "AAA购物中心"
          }],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          },
          labelLine: {
            show: false
          }
        }]
      };
      lineCharts.setOption(option);
    },
    getEnergyConsumption: function getEnergyConsumption(time) {
      var lineCharts = echarts.init(document.getElementById("right-data-chart"));
      lineCharts.clear();
      var energy = [];
      var cooling = [];
      var name = [];
      var Obj = JSON.parse(JSON.stringify(this.energyData));

      for (var i in Obj) {
        name.push(i);
        cooling.push(JSON.parse(JSON.stringify(Obj[i])).cool);
        energy.push(JSON.parse(JSON.stringify(Obj[i])).elect);
      }

      var option = {
        color: ["#ee6666", "#fac858"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          // left: "43%",
          top: "5%",
          textStyle: {
            fontSize: 14,
            //字体大小
            color: "#ffffff" //字体颜色

          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          show: false
        },
        yAxis: {
          type: "category",
          data: name,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#ffffff"
            }
          }
        },
        series: [{
          name: "电量kWh",
          type: "bar",
          data: energy,
          itemStyle: {
            //上方显示数值
            label: {
              show: true,
              //开启显示
              position: "inside",
              //在右方显示
              textStyle: {
                //数值样式
                color: "#ffffff",
                fontSize: 12
              }
            }
          }
        }, {
          name: "冷量kWh",
          type: "bar",
          data: cooling,
          itemStyle: {
            //上方显示数值
            label: {
              show: true,
              //开启显示
              position: "inside",
              //在右方显示
              textStyle: {
                //数值样式
                color: "#ffffff",
                fontSize: 12
              }
            }
          }
        }]
      };
      lineCharts.setOption(option);
    },
    getNxLx: function getNxLx(type, time) {
      if (type === 0) {
        this.buttonStyle.isSelectCNx = "buttonClass isSelect";
        this.buttonStyle.isSelectCLj = "buttonClass";
      } else if (type === 1) {
        this.buttonStyle.isSelectCNx = "buttonClass";
        this.buttonStyle.isSelectCLj = "buttonClass  isSelect";
      }

      if (time === "day") {
        this.buttonStyle.isSelectDDay = "buttonClass isSelect";
        this.buttonStyle.isSelectDMonth = "buttonClass";
        this.buttonStyle.isSelectDYear = "buttonClass";
      } else if (time === "month") {
        this.buttonStyle.isSelectDDay = "buttonClass";
        this.buttonStyle.isSelectDMonth = "buttonClass isSelect";
        this.buttonStyle.isSelectDYear = "buttonClass";
      } else if (time === "year") {
        this.buttonStyle.isSelectDDay = "buttonClass";
        this.buttonStyle.isSelectDMonth = "buttonClass";
        this.buttonStyle.isSelectDYear = "buttonClass isSelect";
      }

      this.variableInderx = type;
      var lineCharts = echarts.init(document.getElementById("nx-lx-chart"));
      lineCharts.clear();
      var data = JSON.parse(JSON.stringify(this.nxlx.data));
      var coldPriceData = JSON.parse(JSON.stringify(this.nxlx.coldPrice));

      if (type === 0) {
        for (var key in data) {
          if (key === "江山食品") {
            if (time === "day") {
              this.nxlx.jssp = data[key][0].dayCop.map(function (e) {
                return e.copValue;
              });
              this.nxlx.time = data[key][0].dayCop.map(function (e) {
                return (0, _dayjs.default)(e.dataTime).format("YYYY-MM-DD");
              });
            } else if (time === "month") {
              this.nxlx.jssp = data[key][1].monthCop.map(function (e) {
                return e.copValue;
              });
              this.nxlx.time = data[key][1].monthCop.map(function (e) {
                return (0, _dayjs.default)(e.dataTime).format("YYYY-MM-DD");
              });
            } else if (time === "year") {
              this.nxlx.jssp = data[key][2].yearCop.map(function (e) {
                return e.copValue;
              });
              this.nxlx.time = data[key][2].yearCop.map(function (e) {
                return (0, _dayjs.default)(e.dataTime).format("YYYY-MM-DD");
              });
            }
          } else if (key === "数据中心") {
            if (time === "day") {
              this.nxlx.sjzx = data[key][0].dayCop.map(function (e) {
                return e.copValue;
              });
            } else if (time === "month") {
              this.nxlx.sjzx = data[key][1].monthCop.map(function (e) {
                return e.copValue;
              });
            } else if (time === "year") {
              this.nxlx.sjzx = data[key][2].yearCop.map(function (e) {
                return e.copValue;
              });
            }
          } else if (key === "阳光购物中心") {
            if (time === "day") {
              this.nxlx.yggwzx = data[key][0].dayCop.map(function (e) {
                return e.copValue;
              });
            } else if (time === "month") {
              this.nxlx.yggwzx = data[key][1].monthCop.map(function (e) {
                return e.copValue;
              });
            } else if (time === "year") {
              this.nxlx.yggwzx = data[key][2].yearCop.map(function (e) {
                return e.copValue;
              });
            }
          } else if (key === "AAA购物中心") {
            if (time === "day") {
              this.nxlx.aaagwzx = data[key][0].dayCop.map(function (e) {
                return e.copValue;
              });
            } else if (time === "month") {
              this.nxlx.aaagwzx = data[key][1].monthCop.map(function (e) {
                return e.copValue;
              });
            } else if (time === "year") {
              this.nxlx.aaagwzx = data[key][2].yearCop.map(function (e) {
                return e.copValue;
              });
            }
          }
        }
      } else {
        for (var key in coldPriceData) {
          if (key === "江山食品") {
            if (time === "day") {
              this.nxlx.jssp = coldPriceData[key][0].dayColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
              this.nxlx.time = coldPriceData[key][0].dayColdPrice.map(function (e) {
                return (0, _dayjs.default)(e.dataTime).format("YYYY-MM-DD");
              });
            } else if (time === "month") {
              this.nxlx.jssp = coldPriceData[key][1].monthColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
              this.nxlx.time = coldPriceData[key][1].monthColdPrice.map(function (e) {
                return (0, _dayjs.default)(e.dataTime).format("YYYY-MM-DD");
              });
            } else if (time === "year") {
              this.nxlx.jssp = coldPriceData[key][2].yearColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
              this.nxlx.time = coldPriceData[key][2].yearColdPrice.map(function (e) {
                return (0, _dayjs.default)(e.dataTime).format("YYYY-MM-DD");
              });
            }
          } else if (key === "数据中心") {
            if (time === "day") {
              this.nxlx.sjzx = coldPriceData[key][0].dayColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
            } else if (time === "month") {
              this.nxlx.sjzx = coldPriceData[key][1].monthColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
            } else if (time === "year") {
              this.nxlx.sjzx = coldPriceData[key][2].yearColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
            }
          } else if (key === "阳光购物中心") {
            if (time === "day") {
              this.nxlx.yggwzx = coldPriceData[key][0].dayColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
            } else if (time === "month") {
              this.nxlx.yggwzx = coldPriceData[key][1].monthColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
            } else if (time === "year") {
              this.nxlx.yggwzx = coldPriceData[key][2].yearColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
            }
          } else if (key === "AAA购物中心") {
            if (time === "day") {
              this.nxlx.aaagwzx = coldPriceData[key][0].dayColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
            } else if (time === "month") {
              this.nxlx.aaagwzx = coldPriceData[key][1].monthColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
            } else if (time === "year") {
              this.nxlx.aaagwzx = coldPriceData[key][2].yearColdPrice.map(function (e) {
                return e.coldPriceValue;
              });
            }
          }
        }
      } // const color = [
      //   "#EAEA26",
      //   "#906BF9",
      //   "#FE5656",
      //   "#01E17E",
      //   "#3DD1F9",
      //   "#FFAD05",
      // ]; //2个以上的series就需要用到color数组


      var color = ["#5470c6", "#91cc75", "#fac858", "#ee6666"];
      var title = {
        textStyle: {
          color: "#fff",
          fontSize: 16
        },
        padding: 0,
        top: 35,
        left: 25
      };
      var legend = {
        //data，就是取得每个series里面的name属性。
        orient: "horizontal",
        icon: "circle",
        //图例形状
        padding: 0,
        top: 35,
        right: 40,
        itemWidth: 14,
        //小圆点宽度
        itemHeight: 14,
        // 小圆点高度
        itemGap: 21,
        // 图例每项之间的间隔。[ default: 10 ]横向布局时为水平间隔，纵向布局时为纵向间隔。
        textStyle: {
          fontSize: 14,
          color: "#ffffff"
        }
      };
      var tooltip = {
        show: true,
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      };
      var seriesData = [{
        name: "江山食品",
        data: this.nxlx.jssp
      }, {
        name: "数据中心",
        data: this.nxlx.sjzx
      }, {
        name: "阳光购物中心",
        data: this.nxlx.yggwzx
      }, {
        name: "AAA购物中心",
        data: this.nxlx.aaagwzx
      }];

      var commonConfigFn = function commonConfigFn(index) {
        return {
          type: "line",
          smooth: true,
          symbol: "circle",
          //空心小圆点。线条小圆点形状
          symbolSize: 6,
          //小圆点大小
          itemStyle: {//还是小圆点设置
          },
          label: {
            show: false //不显示小圆点上的label文字

          },
          lineStyle: {
            width: 1 //线条设置

          },
          areaStyle: {
            //填充线条下面的面积区域颜色。（areaStyle只是锦上添花）
            opacity: 0.2,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: color[index] // 上处的颜色

              }, {
                offset: 1,
                color: "transparent" // 下处的颜色

              }],
              global: false // 缺省为 false

            }
          }
        };
      };

      seriesData = seriesData.map(function (item, index) {
        return _objectSpread({}, item, {}, commonConfigFn(index));
      });
      var option = {
        backgroundColor: "",
        color: color,
        title: title,
        tooltip: tooltip,
        legend: {
          top: "10px",
          textStyle: {
            fontSize: 11,
            color: "#ffffff"
          }
        },
        grid: {
          top: "20%",
          left: "3%",
          right: "4%",
          bottom: "5%",
          containLabel: true
        },
        xAxis: {
          show: true,
          //显示x轴+x轴label文字
          type: "category",
          boundaryGap: false,
          //从Y轴出发，这个false很好的
          axisLine: {
            show: true,
            //显示x坐标轴轴线
            lineStyle: {
              color: "rgba(255,255,255,.4)"
            }
          },
          axisTick: {
            show: false //不显示x坐标1cm刻度

          },
          axisLabel: {
            color: "#ffffff" //x轴label文字颜色

          },
          splitLine: {
            show: false //不显示grid竖向分割线

          },
          data: this.nxlx.time
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#ffffff"
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,.4)"
            }
          },
          splitLine: {
            show: false //不显示grid水平分割线

          }
        },
        series: seriesData
      };
      lineCharts.setOption(option);
    }
  }
};
exports.default = _default;
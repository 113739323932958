"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _mockjs = _interopRequireDefault(require("mockjs"));

var data = _mockjs.default.mock({
  'items|30': [{
    id: '@id',
    title: '@cparagraph',
    'status|1': ['published', 'draft', 'deleted'],
    author: '@cname',
    image: '@image(200x100)',
    display_time: '@datetime',
    pageviews: '@integer(300, 5000)'
  }]
});

var _default = [{
  url: '/map/info',
  type: 'get',
  response: function response(config) {
    var items = data.items;
    return {
      code: 20000,
      data: {
        total: items.length,
        items: items
      }
    };
  }
}];
exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _indexCool = _interopRequireDefault(require("@/components/head/index-cool"));

var _home = require("@/api/home");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    headr: _indexCool.default
  },
  data: function data() {
    return {
      srcLink: ''
    };
  },
  computed: {},
  created: function created() {
    this.getProjectHyperLinkData();
  },
  mounted: function mounted() {
    var oIframe = document.getElementById('bdIframe'); // const deviceWidth = document.documentElement.clientWidth
    // const deviceHeight = document.documentElement.clientHeight

    oIframe.style.width = '100%'; // 数字是页面布局宽度差值
    // oIframe.style.height = (Number(deviceHeight) - 130) + 'px' // 数字是页面布局高度差

    oIframe.style.height = '100%'; // 数字是页面布局高度差

    oIframe.style.marginTop = '24px'; // oIframe.style.marginRight = '80px'
  },
  methods: {
    getProjectHyperLinkData: function getProjectHyperLinkData() {
      var _this = this;

      (0, _home.getProjectHyperLink)({
        projectId: this.$store.state.project.projectInfo.id
      }).then(function (res) {
        _this.srcLink = res.hyperLinkAddress;
      });
    }
  }
};
exports.default = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _vue = _interopRequireDefault(require("vue"));

var _head = _interopRequireDefault(require("@/components/head"));

var _home = require("@/api/home");

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 安装axios后引入
_vue.default.prototype.$axios = _axios.default; //正确的使用 // 将axios挂载到原型上方便使用

var _default = {
  components: {
    headr: _head.default
  },
  data: function data() {
    return {
      skinList: null
    };
  },
  computed: {},
  created: function created() {
    var _this = this;

    _axios.default.get('/skin.json').then(function (data) {
      _this.skinList = data.data.data;
    });
  },
  mounted: function mounted() {},
  methods: {
    checkSkin: function checkSkin(color) {
      console.log('object', color);
    },
    updateSkinData: function updateSkinData(skin) {
      //console.log(skin)
      (0, _home.updateSkin)((0, _auth.getUserId)(), skin).then(function () {
        document.body.className = skin ? skin : 'theme_default';
        localStorage.setItem('skin', skin ? skin : 'theme_default'); // this.$message.success('修改成功')
      });
    }
  }
};
exports.default = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    systemData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    computer1: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    computer2: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      parmsData: [],
      computer1Value: '',
      computer2Value: ''
    };
  },
  watch: {
    systemData: function systemData(val) {
      var _this = this;

      this.parmsData = [];
      val.map(function (e, index) {
        for (var key in val[index]) {
          var obj = {
            name: key,
            value: val[index][key]
          };

          _this.parmsData.push(obj);
        }
      });
    },
    computer1: function computer1(val) {
      this.computer1Value = val[0].coolStorageStatus;
    },
    computer2: function computer2(val) {
      this.computer2Value = val[0].hostStatus;
    }
  }
};
exports.default = _default2;
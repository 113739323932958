"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    systemData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    computer: {
      type: Array,
      default: function _default() {
        return [{
          computerRoomValue: 0
        }];
      }
    }
  },
  data: function data() {
    return {
      parmsData: [],
      colorType: ''
    };
  },
  computed: {
    getComputerValue: function getComputerValue() {
      return this.computer[0].computerRoomValue;
    }
  },
  watch: {
    systemData: function systemData(val) {
      var _this = this;

      this.parmsData = [];
      val.map(function (e, index) {
        for (var key in val[index]) {
          var obj = {
            name: key,
            value: val[index][key]
          };

          _this.parmsData.push(obj);
        }
      });
    },
    "$store.state.project.colorType": function $storeStateProjectColorType(val) {
      this.colorType = val;
    }
  },
  created: function created() {
    this.colorType = localStorage.getItem('skin');
  }
};
exports.default = _default2;